import React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';

import { createNamedStyled } from '../../stitches.config';
import { useTheme } from '../../theme';

const styled = createNamedStyled('Carousel');

const Wrapper = styled.named('Wrapper')('div', {
  width: '100%',

  padding: '$s',
  '@desktop+': { padding: '$m' },

  cursor: 'grab',
  ':active': { cursor: 'grabbing' },
});

const Track = styled.named('Track')('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  gridAutoColumns: 300,
  gridGap: '$s',
  '@desktop+': { gridGap: '$m' },
});

const Carousel = ({ children, id, ...props }) => {
  const { language } = useTheme();
  const wheelGestures = WheelGesturesPlugin();

  const [emblaRef] = useEmblaCarousel({
    containScroll: 'trimSnaps',
    skipSnaps: true,
    draggable: true,
    align: 'start',
    direction: language.direction,
  }, [wheelGestures]);

  return (
    <Wrapper ref={emblaRef} id={id} {...props}>
      <Track>
        {children}
      </Track>
    </Wrapper>
  );
};

export default Carousel;
