import React from 'react';

import { createNamedStyled, keyframes } from '../../stitches.config';

const styled = createNamedStyled('Loader');

const load = keyframes({
  '0%': {
    transform: 'scaleX(0)',
  },
  '100%': {
    transform: 'scaleX(0.9)',
  },
});

const Element = styled.named('Element')('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  width: '100%',
  height: '2px',
  background: 'linear-gradient(to right, $loaderStart, $loaderEnd)',
  zIndex: 1000,
  transformOrigin: 'left',
  willChange: 'transform',
  transform: 'scaleX(0)',

  animation: `${load} 4000ms forwards`,

  variants: {
    state: {
      idle: {
        transform: 'scaleX(0)',
      },
      loading: {
        transform: 'scaleX(0.9)',
        transition: 'transform 4000ms $easeOutExpo',
      },
      loaded: {
        opacity: 0,
        transform: 'scaleX(1)',
        transition: 'opacity 300ms, transform 300ms $easeOutCubic',
      },
      standalone: {
        true: {
          animation: `${load} 4000ms forwards`,
        },
      },
    },
  },

  '@keyframes load': {
    '0%': {
      transform: 'scaleX(0)',
    },
    '100%': {
      transform: 'scaleX(0.9)',
    },
  },
});

export default function Loader({ state = 'standalone' }) {
  return (
    <Element state={state} />
  );
}
