import { createNamedStyled } from '../../stitches.config';

const styled = createNamedStyled('Text');

const TextBlock = styled.named('TextBlock')('div', {
  fontFamily: '$text',
  fontWeight: '$text',
  fontSize: '$text',
  color: '$text',
  letterSpacing: '$text',
  lineHeight: '$text',
  strong: { fontWeight: '$textBold', color: '$textBold' },

  h1: {
    fontSize: '$h1',
    fontFamily: '$title',
    fontWeight: '$title',
    color: '$title',
    letterSpacing: '$title',
    lineHeight: '$title',
    marginBottom: '$s',

    strong: { fontWeight: '$titleBold', color: '$titleBold' },
  },

  h2: {
    fontSize: '$h2',
    fontFamily: '$title',
    fontWeight: '$title',
    color: '$title',
    letterSpacing: '$title',
    lineHeight: '$title',
    marginBottom: '$s',

    strong: { fontWeight: '$titleBold', color: '$titleBold' },
  },

  h3: {
    fontSize: '$h3',
    fontFamily: '$title',
    fontWeight: '$title',
    color: '$title',
    letterSpacing: '$title',
    lineHeight: '$title',
    '&:not(:last-child)': { marginBottom: '$s' },

    strong: { fontWeight: '$titleBold', color: '$titleBold' },
  },

  h4: {
    fontSize: '$h4',
    fontFamily: '$title',
    fontWeight: '$title',
    color: '$title',
    letterSpacing: '$title',
    lineHeight: '$title',
    '&:not(:last-child)': { marginBottom: '$s' },

    strong: { fontWeight: '$titleBold', color: '$titleBold' },
  },

  h5: {
    fontSize: '$h5',
    fontFamily: '$title',
    fontWeight: '$title',
    color: '$title',
    letterSpacing: '$title',
    lineHeight: '$title',
    '&:not(:last-child)': { marginBottom: '$s' },

    strong: { fontWeight: '$titleBold', color: '$titleBold' },
  },

  h6: {
    fontSize: '$h6',
    fontFamily: '$title',
    fontWeight: '$title',
    color: '$title',
    letterSpacing: '$title',
    lineHeight: '$title',
    '&:not(:last-child)': { marginBottom: '$s' },

    strong: { fontWeight: '$titleBold', color: '$titleBold' },
  },

  p: {
    fontFamily: '$text',
    fontWeight: '$text',
    fontSize: '$text',
    color: '$text',
    letterSpacing: '$text',
    lineHeight: '$text',
    strong: { fontWeight: '$textBold', color: '$textBold' },

    '&:not(:last-child)': { marginBottom: '$xs' },
  },

  em: { fontStyle: 'italic' },
  s: { textDecoration: 'line-through' },

  ul: {
    listStyleType: 'disc',
    marginLeft: '$m',
    color: '$text',
    fontFamily: '$text',
    '&:not(:last-child)': { marginBottom: '$xs' },
  },

  ol: {
    listStyleType: 'decimal',
    marginLeft: '$m',
    color: '$text',
    fontFamily: '$text',
    '&:not(:last-child)': { marginBottom: '$xs' },
  },
});

export default TextBlock;
