import {
  useMemo,
} from 'react';

import { useStore, useStoreCurrency } from '../context/Store';

import Types from '../modules/types';

export function getPriceWithVat(
  value,
  storeData,
  currencyObject,
  vat = 0,
) {
  const finalVat = (
    Number.isFinite(vat)
    ? vat
    : vat === true && storeData?.trn?.active
    ? Types.VAT
    : 0
  );
  const { rate } = storeData.storeCurrenciesMap[currencyObject.code];
  return Types.getRoundedAmount((value + (value * finalVat)) * rate);
}

export function getPriceWithCurrency(
  value,
  storeData,
  currencyObject,
  vat = 0,
) {
  const finalVat = (
    Number.isFinite(vat)
    ? vat
    : vat === true && storeData?.trn?.active
    ? Types.VAT
    : 0
  );
  const { code, rate } = storeData.storeCurrenciesMap[currencyObject.code];
  return `${
    code
  } ${
    Types.decimalize(Types.getRoundedAmount(
      (value + (value * finalVat)) * rate
    ))
  }`;
}

export function getProductPriceWithCurrency(
  value,
  storeData,
  currencyObject,
  vat,
) {
  return getPriceWithCurrency(
    value,
    storeData,
    currencyObject,
    (
      vat === false
      ? false
      : storeData?.showPricesWithVat === false ? false : true
    ),
  );
}

export function usePriceWithCurrency({ value, vat = 0, product = false }) {
  const { data: storeData } = useStore();
  const [currencyObject] = useStoreCurrency();

  const priceFormatted = useMemo(
    () => (
      product
      ? getProductPriceWithCurrency(value, storeData, currencyObject, vat)
      : getPriceWithCurrency(value, storeData, currencyObject, vat)
    ),
    [storeData, currencyObject, vat, product, value],
  );

  return priceFormatted;
}

const PriceWithCurrency = ({ value, vat = 0, product = false }) => {
  const { data: storeData } = useStore();
  const [currencyObject] = useStoreCurrency();

  const priceFormatted = useMemo(
    () => (
      product
      ? getProductPriceWithCurrency(value, storeData, currencyObject, vat)
      : getPriceWithCurrency(value, storeData, currencyObject, vat)
    ),
    [storeData, currencyObject, vat, product, value],
  );

  return priceFormatted;
};

export default PriceWithCurrency;
