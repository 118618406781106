import intersection from 'lodash/intersection';

const Types = {};

Types.getArModels = (
  arModels = [],
  variation = {},
  count = Infinity,
) => {
  const variationVariantChoiceIds = Object.values(variation.variants || {});
  const arModelItemsLocal = [];
  for (let i = 0; i < arModels.length; i++) {
    const arModelItem = arModels[i];
    const { variants } = arModelItem;
    if (
      !variationVariantChoiceIds?.length
      || !variants?.length
      || (
        // TODO Match all variant choices from item (not any)
        intersection(variationVariantChoiceIds, variants).length
        >= variants.length
      )
    ) {
      if (arModelItem.ios || arModelItem.android) {
        arModelItemsLocal.push(arModelItem);
        if (arModelItemsLocal.length >= count) {
          break;
        }
      }
    }
  }
  return arModelItemsLocal;
};

export default Types;
