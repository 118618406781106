import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { createNamedStyled } from '../../stitches.config';
import { useTheme } from '../../theme';

import { Paragraph } from './Text';

const styled = createNamedStyled('Notification');

const NotificationWrapper = styled.named('Wrapper')('div', {
  position: 'absolute',
  top: -2,
  insetInlineEnd: -10,
  zIndex: 2,
});

const MotionWrapper = styled.named('MotionWrapper')(motion.div, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2,

  backfaceVisibility: 'hidden',
  transform: 'perspective(1000px)',

  padding: 3,
  minWidth: 25,

  borderRadius: '100px',
  letterSpacing: 0,

  transition: 'opacity $s',
  background: '$notificationBackground',
});

export default function Notification({
  value,
  invert,
  subtle,
  forceVisible,
  ...props
}) {
  const { transitions } = useTheme();
  const multiplier = transitions.spring.damping > 20 ? 4 : 1.5;

  return (
    <NotificationWrapper {...props}>
      <MotionWrapper
        key={value}
        transition={transitions.spring}
        animate={{
          y: subtle ? -3 : -6,
          transition: {
            velocity: subtle ? -125 * multiplier : -250 * multiplier,
            ...transitions.spring,
          },
        }}
        css={{ opacity: (forceVisible || value > 0) ? 1 : 0 }}
      >
        <AnimatePresence>
          <Paragraph
            as={motion.p}
            key={value}
            initial={{ y: '100%', opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: '-100%', opacity: 0 }}
            css={{
              fontSize: 12,
              fontWeight: 'bold',
              color: '$notificationForeground',
            }}
          >
            {value}
          </Paragraph>
        </AnimatePresence>
      </MotionWrapper>
    </NotificationWrapper>
  );
}
