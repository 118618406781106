import { useMemo } from 'react';

import { useStore } from '../context/Store';
import { useLocation } from '../context/Location';

import Types from '../modules/types';

export function usePopulatedProductCategories(
  productCategory,
  productCategories,
) {
  const { data: store } = useStore();
  const categoryIds = useMemo(
    () => [
      productCategory?._id || productCategory,
      ...(productCategories || []).map(categoriesItem => (
        categoriesItem?.category?._id || categoriesItem?.category
      )),
    ],
    [productCategory, productCategories],
  );
  return categoryIds.reduce(
    (agr, categoryId) => {
      if (categoryId) {
        const populatedCategory = (store?.categories || []).find(
          storeCategory => storeCategory._id === categoryId
        );
        if (populatedCategory) {
          agr.push(populatedCategory);
        }
      }
      return agr;
    },
    [],
  );
}

export function useSearchParamsCategoryIdFromProduct(
  categorySlug,
  productCategory,
  productCategories,
) {
  const populatedProductCategories = usePopulatedProductCategories(
    productCategory,
    productCategories,
  );
  return useMemo(
    () => {
      const categoryIdLocal = populatedProductCategories.find(
        populatedCategory => populatedCategory.slug === categorySlug,
      )?._id;
      return categoryIdLocal;
    },
    [categorySlug, populatedProductCategories],
  );
}

export default function useGalleryItems(
  gallery,
  includeVideo = false,
  variation,
  variants,
  options,
  optionValues,
  categorySlug,
  productCategory,
  productCategories,
) {
  const { searchParams } = useLocation();
  const categoryIdFromProduct = useSearchParamsCategoryIdFromProduct(
    categorySlug || searchParams.category,
    productCategory,
    productCategories,
  );
  return useMemo(
    () => Types.getGalleryImages(
      gallery,
      includeVideo,
      variation,
      variants,
      options,
      optionValues,
      categoryIdFromProduct,
    ),
    [
      categoryIdFromProduct,
      gallery,
      includeVideo,
      variation,
      variants,
      options,
      optionValues,
    ],
  );
}
