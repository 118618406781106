import React, {
  createContext, useContext, useRef, useState, useEffect,
} from 'react';
import { Route } from 'react-router-dom';

const SharedStoreDataContext = createContext();

const MOZHE_URL = process.env.NODE_ENV === 'development'
  ? window.location.origin
  : process.env.REACT_APP_MOZHE_HOME_URL;

export function SharedStoreDataProvider({ children }) {
  const iframeRef = useRef(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.action === 'sharedStoreDataResponse') {
        setData(event.data.data);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const setSharedStoreData = (newData) => new Promise((resolve, reject) => {
    const handleMessage = (event) => {
      if (event.data.action === 'setSharedStoreDataResponse') {
        resolve();
        window.removeEventListener('message', handleMessage);
      }
    };
    window.addEventListener('message', handleMessage);

    if (iframeRef && iframeRef.current) {
      iframeRef.current.contentWindow.postMessage({
        action: 'setSharedStoreData',
        data: newData,
      }, '*');
    } else {
      reject(new Error('iframe not ready'));
    }
  });

  // const getSharedStoreData = () => new Promise((resolve, reject) => {
  //   const maxRetries = 10;
  //   let retryCount = 0;

  //   const handleMessage = (event) => {
  //     if (event.data.action === 'sharedStoreDataResponse') {
  //       resolve(event.data.data);
  //       window.removeEventListener('message', handleMessage);

  //       if (retryInterval) {
  //         clearInterval(retryInterval);
  //       }
  //     }
  //   };

  //   window.addEventListener('message', handleMessage);

  //   const tryPostMessage = () => {
  //     if (iframeRef && iframeRef.current) {
  //       iframeRef.current.contentWindow.postMessage({
  //         action: 'getSharedStoreData',
  //       }, '*');
  //     } else {
  //       retryCount++;

  //       if (retryCount >= maxRetries) {
  //         clearInterval(retryInterval);
  //         reject(new Error('iframe not ready after multiple attempts'));
  //       }
  //     }
  //   };

  //   tryPostMessage();
  //   const retryInterval = setInterval(tryPostMessage, 500);
  // });

  const getSharedStoreData = () => new Promise((resolve, reject) => {
    const handleMessage = (event) => {
      if (event.data.action === 'sharedStoreDataResponse') {
        resolve(event.data.data);
        window.removeEventListener('message', handleMessage);
      }
    };

    window.addEventListener('message', handleMessage);

    const tryPostMessage = () => {
      if (iframeRef && iframeRef.current) {
        iframeRef.current.contentWindow.postMessage({
          action: 'getSharedStoreData',
        }, '*');
      } else {
        reject(new Error('iframe not ready'));
      }
    };

    if (iframeRef && iframeRef.current) {
      if (iframeRef.current.contentWindow) {
        tryPostMessage();
      }
      iframeRef.current.addEventListener('load', tryPostMessage);
    } else {
      reject(new Error('No iframeRef available'));
    }
  });

  return (
    <SharedStoreDataContext.Provider
      value={{ data, iframeRef, setSharedStoreData, getSharedStoreData }}
    >
      {children}
    </SharedStoreDataContext.Provider>
  );
}

export function useSharedStoreData() {
  return useContext(SharedStoreDataContext);
}

export function SharedStoreDataLoader() {
  const { iframeRef } = useSharedStoreData();

  return (
    <iframe
      ref={iframeRef}
      src={`${MOZHE_URL}/shared-store-data-manager`}
      style={{ display: 'none' }}
      title="Shared Store Data Manager"
    />
  );
}

export function SharedStoreDataManager() {
  // const isCorrectOrigin = MOZHE_URL === window.location.origin;
  const isCorrectOrigin = true;

  useEffect(() => {
    if (!isCorrectOrigin) {
      window.location.href = '/';
      return;
    }

    const eventListener = (event) => {
      if (event.data.action === 'setSharedStoreData') {
        localStorage.setItem(
          'MOZHE_SHARED_STORE_DATA',
          JSON.stringify(event.data.data)
        );
        event.source.postMessage({
          action: 'setSharedStoreDataResponse',
        }, '*');
      } else if (event.data.action === 'getSharedStoreData') {
        const storedData = localStorage.getItem('MOZHE_SHARED_STORE_DATA');
        event.source.postMessage({
          action: 'sharedStoreDataResponse',
          data: JSON.parse(storedData || '{}'),
        }, '*');
      }
    };

    window.addEventListener('message', eventListener);
    // eslint-disable-next-line consistent-return
    return () => {
      if (isCorrectOrigin) {
        window.removeEventListener('message', eventListener);
      }
    };
  }, [isCorrectOrigin]);

  if (!isCorrectOrigin) return null;
  return <div />;
}

export const sharedStoreDataManagerRoute = (
  <Route
    path="/shared-store-data-manager"
    component={SharedStoreDataManager}
  />
);
