import isFinite from 'lodash/isFinite';
import isString from 'lodash/isString';
import isBoolean from 'lodash/isBoolean';
import isPlainObject from 'lodash/isPlainObject';
import getKey from 'lodash/get';
import debounce from 'lodash/debounce';

import moment from 'moment-timezone';

import React, { createContext, useContext, useMemo, createRef } from 'react';
import {
  stringify as stringifyQuery,
} from 'querystring';
import {
  // useHistory,
  Redirect,
} from 'react-router-dom';

import ContextProviderComponent from './ContextProviderComponent';

import Types from '../modules/types';

import { useLocation } from './Location';
import { useApi, useApiRequest } from './Api';
import { useStore, useStoreCurrency } from './Store';
import { useViewer } from './Viewer';
import { useAnalytics } from './Analytics';
import { useDictionary } from './Language';

import { getPriceWithCurrency } from '../helpers/PriceWithCurrency';

const {
  CONSTANTS: {
    ShippingMethod,
  },
} = Types;

const Context = createContext();

// eslint-disable-next-line no-unused-vars
export const getCalculatedPriceIncludingVat = (value, props) => {
  if (Number.isFinite(value)) {
    const vat = Types.getRoundedAmount(value * Types.VAT);
    const includingVat = Types.getRoundedAmount(value + vat);
    return includingVat;
  }
  return undefined;
};

export const KEY_CART_OPEN = 'CART_OPEN_V2';
export const KEY_CART_ITEMS = 'CART_ITEMS_V2';
export const KEY_SUBMIT_FIELDS = 'SUBMIT_FIELDS';

// function findImageForVariation(variation, gallery) {
//   const variantIDs = Object.values(variation.variants);

//   for (let i = 0; i < gallery.length; i++) {
//     const galleryItem = gallery[i];

//     for (let j = 0; j < galleryItem.variants.length; j++) {
//       if (variantIDs.includes(galleryItem.variants[j])) {
//         return galleryItem.image;
//       }
//     }
//   }

//   return null;
// }

export const getCartItemFromProductAndVariation = (
  product,
  variation,
  quantity = 1,
  options = {},
  galleryId,
) => {
  const optionsMap = (
      isPlainObject(options)
    ? options
    : Array.isArray(options)
    ? options.reduce(
        (agr, option) => {
          agr[option._id] = option.value;
          return agr;
        },
        {},
      )
    : {}
  );
  const imageGallery = product.gallery.find(
    galleryItem => galleryItem._id === galleryId,
  );
  return {
    sku: variation.sku,
    price: variation.price,
    product,
    variation,
    meta: {
      name: product.name,
      kind: product.kind || Types.CONSTANTS.ProductKind.TANGIBLE,
      tagline: product.tagline,
      productId: product._id,
      productSlug: product.slug,
      storeSku: variation.storeSku,
      variationSlug: variation.slug,
      variation: variation.name,
      galleryId,
      // image: findImageForVariation(variation, product.gallery)?.src
      //   || null,
      image: (
        imageGallery?.image?.src
        || null
      ),
      options: optionsMap,
      // TODO Missing categoryName
    },
    quantity,
  };
};

// eslint-disable-next-line arrow-body-style,  no-unused-vars
export const getSubmitFieldsFromSavedData = (store) => {
  // const savedCustomerDetails = store.getData(KEY_SUBMIT_FIELDS);
  // if (savedCustomerDetails) {
  //   return {
  //     ...savedCustomerDetails,
  //     ...(store.data?.customer || {}),
  //   };
  // }
  return null;
};

export const getSubmitFieldsFromViewer = (viewer) => {
  if (viewer?.data?.store?.active !== true) {
    return {};
  }
  viewer = viewer.data;
  try {
    return {
      store: viewer.store._id,
      country: viewer.systemCountry,
      firstName: viewer.name.split(/\s+/)[0],
      lastName: viewer.name.split(/\s+/)[1],
      email: viewer.email,
      phoneNumberString: viewer.phoneNumberString,
      // "shipping": "ABSTRACT",
      city: viewer.store.address.city,
      postalCode: viewer.store.address.postalCode,
      coordinatesString: viewer.store.address.coordinatesString,
      line1: viewer.store.address.line1,
      line2: viewer.store.address.line2,
      payment: 'CARD',
      // billing: {
      //   firstName: null,
      //   lastName: null,
      //   email: null,
      //   phoneNumber: null,
      //   country: null,
      //   city: null,
      //   postalCode: null,
      //   line1: null,
      // },
    };
  } catch (error) {
    return {};
  }
};

// eslint-disable-next-line arrow-body-style
export const getSubmitFieldsForPosCheckout = (store) => {
  return {
    country: store.systemCountry,
    email: 'pos-customer@mozhe.rs',
    phoneNumberString: '+38164123456',
    city: store.address.city,
    postalCode: store.address.postalCode,
    coordinatesString: store.address.coordinatesString,
    line1: store.address.line1,
    line2: store.address.line2,
    payment: 'CARD',
    shipping: 'POS',
  };
};

export const getCartItemsFromSavedData = async (cartItems, store, api) => {
  if (cartItems && cartItems.length) {
    try {
      const products = await api.get('products', {
        where: {
          store: store.data._id,
          'variations.sku': { IN: cartItems.map(({ sku }) => sku) },
          active: { NE: false },
        },
        limit: 100,
      });
      if (products.length) {
        const items = [];
        cartItems.forEach(({
          sku,
          quantity,
          meta,
          options,
          comment,
        }) => {
          for (let p = 0; p < products.length; p++) {
            const product = products[p];
            for (let v = 0; v < product.variations.length; v++) {
              const variation = product.variations[v];
              if (variation.sku === sku) {
                items.push({
                  ...getCartItemFromProductAndVariation(
                    product,
                    variation,
                    quantity || 1,
                    options || meta.options || [],
                    meta.galleryId,
                  ),
                  comment,
                });
              }
            }
          }
        });
        if (items.length) {
          return items;
        }
      }
    } catch (error) {
      return null;
    }
  }
  return null;
};

export default Context;

export const { Consumer } = Context;

export function Provider(props) {
  const api = useApi();
  const store = useStore();
  const dictionary = useDictionary();
  const analytics = useAnalytics();
  const { pathname, searchParams } = useLocation();
  // const history = useHistory();
  const submitFields = useMemo(
    () => {
      try {
        return JSON.parse(searchParams.fields);
      } catch (error) {
        return null;
      }
    },
    [searchParams.fields],
  );
  const viewer = useViewer();
  const posSkip = !props.posOrderId || !props.posOrderId.length;
  const [{ data: posOrder, loading: posLoading }] = useApiRequest({
    url: `orders/${props.posOrderId}?${stringifyQuery({
      query: JSON.stringify({
        where: { status: 'DRAFT' },
      }),
    })}`,
    skip: posSkip,
  });
  if (posLoading || viewer.loading || store.loading || !store.data) {
    return null;
  }
  if (
    (!posSkip && !posLoading)
    && (!posOrder || posOrder.store !== store.data._id)
  ) {
    const newSearchParams = { ...searchParams };
    delete newSearchParams.pos;
    const searchString = stringifyQuery(newSearchParams);
    return (
      <Redirect
        to={`${pathname}${searchString ? `?${searchString}` : ''}`}
      />
    );
  }
  return (
    <CartProvider
      key={store._id}
      api={api}
      store={store}
      dictionary={dictionary}
      viewer={viewer}
      analytics={analytics}
      submitFields={submitFields}
      discountCode={searchParams.discountcode}
      posOrder={posOrder}
      {...props}
    />
  );
}

export class CartProvider extends ContextProviderComponent {
  static NAME = 'CART'

  static defaultProps = {
    ...ContextProviderComponent.defaultProps,
    ProviderComponent: Context.Provider,
  }

  constructor(props) {
    super(props);
    global.CART = this;
    this.checkoutCardInputRef = createRef();
    this.allsecureCardInputRef = createRef();
    this.stripeCardRef = createRef();
    this.stripeCardRef.current = {};
    this.exposeMethods(
      'onFormInitialize',
      'onFormChange',
      'toggleShowCart',
      'setItemComment',
      'addToCart',
      'removeFromCart',
      'replaceCart',
      'ensureInCart',
      'ensureNotInCart',
      'clearCart',
      'submit',
      'setError',
      'setSubmitTried',
      'getStripePaymentIntent',
    );
    Object.assign(this.state, {
      submitTried: false,
      data: null,
      loading: true,
      submitting: false,
      evaluating: true,
      error: null,
      isOpen: false,
      items: [],
      countries: [],
      shipping: {},
      scheduling: false,
      payment: {},
      stock: null,
      checkoutCardInputRef: this.checkoutCardInputRef,
      allsecureCardInputRef: this.allsecureCardInputRef,
      stripeCardRef: this.stripeCardRef,
      stripePaymentIntent: {},
      posOrderId: props.posOrderId,
      posOrder: props.posOrder,
    });
    Object.assign(
      this.state,
      this.getCalculatedState(
        this.props,
        {
          defaultSubmitFields: (
              props.store?.data?.customer
            ? props.store?.data?.customer
            : props.viewer
            ? getSubmitFieldsFromViewer(props.viewer)
            : {}
          ),
        }
      ),
    );

    this.evaluateDebounced = debounce(
      this.evaluate,
      2000,
      // { leading: true },
    );

    this.evaluateDebounced.now = (...args) => {
      this.evaluateDebounced.cancel();
      // this.evaluateDebounced(...args);
      this.evaluate(...args);
    };

    this.saveSubmitFieldsDebounced = debounce(
      this.saveSubmitFields,
      200,
    );

    this.saveItemsDebounced = debounce(
      this.saveItems,
      200,
    );
  }

  setSubmitTried = (tried) => {
    this.setState({ submitTried: !!tried });
  }

  async componentDidMount() {
    const { store, api, forceInitialClosed, posOrder } = this.props;
    const savedCartItems = await getCartItemsFromSavedData(
      posOrder ? posOrder.items : store.getData(KEY_CART_ITEMS),
      store,
      api,
    );
    const savedSubmitFields = await getSubmitFieldsFromSavedData(store);
    // const savedCartItems = null;
    // const savedSubmitFields = null;
    const cartOpen = (
        forceInitialClosed
      ? false
      : !!store.getData(KEY_CART_OPEN)
    );
    const changes = { loading: false };
    changes.defaultIsOpen = cartOpen;
    if (savedCartItems) {
      changes.items = savedCartItems.map(item => this.getCalculatedItem(item));
    }
    if (posOrder) {
      changes.defaultSubmitFields = Object.assign(
        changes.defaultSubmitFields || {},
        getSubmitFieldsForPosCheckout(store.data),
      );
      if (posOrder.discountCode) {
        changes.defaultSubmitFields.discountCode = posOrder.discountCodeValue;
      } else {
        delete changes.defaultSubmitFields.discountCode;
      }
      this.lastCountry = changes.defaultSubmitFields.country;
      this.saveSubmitFields(changes.defaultSubmitFields);
    } else {
      if (savedSubmitFields) {
        changes.defaultSubmitFields = Object.assign(
          changes.defaultSubmitFields || {},
          savedSubmitFields
        );
        this.lastCountry = (
          savedSubmitFields.country || changes.defaultSubmitFields.country
        );
        this.saveSubmitFields(changes.defaultSubmitFields);
      }
      if (this.props.submitFields) {
        changes.defaultSubmitFields = Object.assign(
          changes.defaultSubmitFields || {},
          this.props.submitFields,
        );
        if (this.props.submitFields.country) {
          this.lastCountry = this.props.submitFields.country;
        }
        this.saveSubmitFields(changes.defaultSubmitFields);
      }
      if (this.props.store?.data?.customer) {
        changes.defaultSubmitFields = Object.assign(
          changes.defaultSubmitFields || {},
          this.props.store.data.customer,
        );
        if (this.props.store.data.customer.country) {
          this.lastCountry = this.props.store.data.customer.country;
        }
        this.saveSubmitFields(changes.defaultSubmitFields);
      }
    }
    if (this.props.viewer) {
      changes.defaultSubmitFields = Object.assign(
        changes.defaultSubmitFields || {},
        getSubmitFieldsFromViewer(this.props.viewer),
      );
      this.lastCountry = changes.defaultSubmitFields.country;
      this.saveSubmitFields(changes.defaultSubmitFields);
    }
    this.setCalculatedState(this.props, changes);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    clearTimeout(this.submitCardTimeout);
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.state.items !== prevState.items) {
    //   this.saveItemsDebounced(this.state.items);
    // }
    if (
      this.state.posOrderId !== this.props.posOrderId
      || this.state.posOrder !== this.props.posOrder
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        posOrderId: this.props.posOrderId,
        posOrder: this.props.posOrder,
      });
    }
    if (!prevState.isOpen && this.state.isOpen === true) {
      const { currency: systemCurrency } = (
        this.props.store?.data
        ? Types.getSystemCountry(this.props.store.data.systemCountry)
        : {}
      );
      this.props.analytics.trigger.CART_OPEN({
        items: this.state.items,
        currency: systemCurrency,
      });
    }
    if (
      this.form
      && (
        this.state.items !== prevState.items
        || this.props.store !== prevProps.store
        || this.props.posOrder !== prevProps.posOrder
      )
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      const values = this.form.getValues();
      if (!this.lastCountry) {
        this.lastCountry = values.country;
      }
      this.evaluateDebounced.now(
        this.state.items,
        values,
        this.props,
      );
    }
  }

  getCalculatedItem({ price, quantity = 1, ...item }) {
    const priceWithVat = (
      this.props.store?.data?.trn?.active
      ? getCalculatedPriceIncludingVat(price)
      : price
    );
    const priceTotalWithVat = Types.getRoundedAmount(
      priceWithVat * quantity,
    );
    const priceTotal = price * quantity;
    return {
      ...item,
      quantity,
      price,
      priceWithVat,
      priceTotal,
      priceTotalWithVat,
      priceTotalFormatted: Types.decimalize(priceTotalWithVat),
    };
  }

  getCalculatedState(props, changes = {}, state) {
    state = state || this.state;
    const newState = (changes.items || state.items || []).reduce(
      (agr, item) => {
        agr.priceItems += item.priceTotal;
        agr.quantityTotal += item.quantity;
        agr.itemsMap[item.sku] = item;
        return agr;
      },
      {
        ...state,
        ...changes,
        itemsMap: {},
        priceItems: 0,
        quantityTotal: 0,
      },
    );

    newState.defaultSubmitFields.shippingScheduled = (
      !!newState.defaultSubmitFields.shippingScheduled
    );
    newState.defaultSubmitFields.shippingScheduledFor = (() => {
      let value = newState.defaultSubmitFields.shippingScheduledFor;
      if (!Number.isFinite(new Date(value).getTime())) {
        value = '';
      // } else {
      //   const momentNow = moment().startOf('day');
      //   const momentScheduledFor = moment(value).startOf('day');
      }
      return value;
    })();

    if (!newState.items.length) {
      newState.isOpen = false;
      this.props.api.post('abandoned-carts/clear', {
        email: newState.defaultSubmitFields.email,
        storeId: this.props.store.data._id,
      });
    } else if (newState.defaultSubmitFields?.email?.length) {
      this.props.api.post('abandoned-carts/update', this.getOrderSubmitData(
        newState.items,
        newState.defaultSubmitFields,
        this.props,
      ));
    }

    if (newState.defaultIsOpen) {
      delete newState.defaultIsOpen;
      if (newState.items.length) {
        newState.isOpen = true;
      }
    }

    const cost = newState.cost || {};

    const values = (
        this.form
      ? this.form.getValues()
      : newState.defaultSubmitFields
    ) || {};

    newState.priceItemsDiscount = cost.itemsDiscount || 0;

    newState.priceItemsVat = cost.vat || 0;

    newState.priceItemsWithVat = cost.itemsWithVat;

    const freeShipping = getKey(
      newState,
      `shipping.${values.shipping}.free`,
      false,
    );

    newState.priceShippingFree = freeShipping;

    newState.priceShipping = freeShipping
    ? 0
    : (cost.shipping || 0) - (cost.shippingVat || 0);
    newState.priceShippingVat = freeShipping
    ? 0
    : cost.shippingVat || 0;
    newState.priceShippingWithVat = cost.shipping || 0;

    newState.pricePayment = (
      (cost.payment || 0) - (cost.paymentVat || 0)
    );
    newState.pricePaymentVat = cost.paymentVat || 0;
    newState.pricePaymentWithVat = cost.payment || 0;

    newState.priceServiceFee = (
      (cost.serviceFee > 0 && cost.serviceFeeChargedTo === 'CUSTOMER')
      ? (cost.serviceFee || 0)
      : 0
    );
    newState.priceServiceFeeVat = (
      (cost.serviceFeeVat > 0 && cost.serviceFeeChargedTo === 'CUSTOMER')
      ? (cost.serviceFeeVat || 0)
      : 0
    );
    newState.priceServiceFeeWithVat = (
      newState.priceServiceFee
      + newState.priceServiceFeeVat
    );

    newState.priceVat = (
        newState.priceItemsVat
      + newState.priceShippingVat
      + newState.pricePaymentVat
      + newState.priceServiceFeeVat
    );

    newState.priceTotal = (
        newState.priceItems
      + newState.priceShipping
      + newState.pricePayment
      + newState.priceServiceFee
      + newState.priceVat
    );

    newState.priceCharged = (
      cost.charged
      || (
          newState.priceTotal
        - newState.priceItemsDiscount
      )
    );

    [
      'priceVat',
      'priceItems',
      'priceItemsVat',
      'priceItemsDiscount',
      'priceShipping',
      'pricePayment',
      'priceServiceFee',
      'priceTotal',
      'priceCharged',
    ].forEach((key) => {
      newState[`${key}Formatted`] = Types.decimalize(newState[key]);
    });

    return newState;
  }

  async setCalculatedState(props, changes = {}) {
    return new Promise(resolve => this.setState(
      state => this.getCalculatedState(props, changes, state),
      () => resolve(this.state),
    ));
  }

  getOrderCustomerDetails(values = {}) {
    const country = Types.COUNTRIES_MAP[values.country];
    return {
      name: values.firstName && values.lastName
        ? `${values.firstName} ${values.lastName}`
        : undefined,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumberString: values.phoneNumberString,
      address: {
        line1: `${values.streetName} ${values.streetNumber}`,
        line2: values.line2,
        country: values.country,
        city: values.city,
        dexpressTownId: values.dexpressTownId,
        postalCode: (
            country && country.postalCode
          ? country.postalCode
          : values.postalCode
        ),
        coordinatesString: values.coordinatesString || '20.47438,44.80189',
        district: values.district,
        area: values.area,
        neighbourhood: values.neighbourhood,
        streetName: values.streetName,
        streetNumber: values.streetNumber,
      },
    };
  }

  getOrderSubmitData(items, values, props) {
    let {
      discountCode = null,
      shippingScheduled = false,
      shippingScheduledFor,
    } = values;
    if (props.discountCode) {
      discountCode = props.discountCode;
    }
    if (discountCode && discountCode.length > 0) {
      discountCode = discountCode.toUpperCase().trim();
    }
    const paymentCardProviderData = {};
    const { paymentCardProvider = 'PAYTABS' } = Types.getSystemCountry(
      this.props.store.data.systemCountry,
    );
    if (paymentCardProvider === 'STRIPE') {
      paymentCardProviderData.stripePaymentIntent = (
        this.state.stripePaymentIntent
      );
    }
    if (!shippingScheduled) {
      shippingScheduled = false;
      shippingScheduledFor = undefined;
    } else {
      shippingScheduled = true;
      if (!shippingScheduledFor) {
        shippingScheduledFor = undefined;
      } else {
        const momentNow = moment();
        const momentScheduledFor = moment(shippingScheduledFor);
        momentScheduledFor.hour(momentNow.hour());
        momentScheduledFor.minute(momentNow.minute());
        momentScheduledFor.second(momentNow.second());
        shippingScheduledFor = momentScheduledFor.toJSON();
      }
    }
    return {
      store: props.store.data._id,
      language: props.store.language.language,
      comment: values.orderComment,
      customerStore: props.viewer?.data?.store?._id,
      customerStoreName: props.viewer?.data?.store?.name,
      items: items.map(({
        sku,
        quantity,
        meta,
        comment,
      }) => ({
        sku,
        quantity,
        comment,
        galleryId: meta.galleryId,
        options: meta.options || [],
      })),
      payment: values.payment,
      paymentCardProvider,
      paymentCardProviderData,
      shipping: values.shipping,
      shippingScheduled,
      shippingScheduledFor,
      shippingDetails: this.getOrderCustomerDetails(values),
      billingDetailsDiffer: !!values.billingDetailsDiffer,
      billingDetails: (
        values.billingDetailsDiffer
          ? this.getOrderCustomerDetails(values.billing)
          : undefined
      ),
      referral: props.store.data.referral
      ? props.store.data.referral._id
      : undefined,
      discountCode,
      draftedFromPurpose: props.posOrderId ? 'POS' : undefined,
      draftedFrom: props.posOrderId || undefined,
    };
  }

  // eslint-disable-next-line no-unused-vars
  getOrderSubmitQuery(items, values, props) {
    return {};
  }

  evaluate = async (items, values, props) => {
    if (props.store && items.length) {
      const { systemCountry: country } = props.store.data;
      let newState = null;
      const data = this.getOrderSubmitData(items, values, props);
      const aborter = this.evaluationAborter = new AbortController();
      try {
        this.setState({ evaluating: true });
        const { paymentCardProvider = 'PAYTABS' } = Types.getSystemCountry(
          this.props.store.data.systemCountry,
        );
        const evaluation = await props.api.post(
          'orders/evaluate',
          data,
          undefined,
        );
        this.debug('data:', data);
        this.debug('evaluation:', evaluation);
        let ALL_SHIPPING_METHODS = [];
        let AVAILABLE_SHIPPING_METHODS = [];
        let ALL_PAYMENT_METHODS = [];
        let AVAILABLE_PAYMENT_METHODS = [];
        let shipping = null;
        // eslint-disable-next-line no-unused-vars
        let payment = null;
        newState = newState || {};
        newState.isAbstract = evaluation.isAbstract === true;
        newState.international = typeof evaluation.international === 'undefined'
        ? undefined
        : !!evaluation.international;
        newState.scheduling = !!evaluation.scheduling;
        newState.evaluating = false;
        // newState.data = data;
        newState.error = (
          (!evaluation.valid && evaluation.message)
          ? evaluation.message
          : null
        );
        newState.stock = evaluation.stock || null;
        if (!evaluation.countries || !evaluation.countries.length) {
          newState = newState || {};
          newState.error = (
               newState.error
            || evaluation.error
            || 'Store offers no shipping methods'
          );
          newState.shipping = {};
          newState.payment = {};
        } else if (
          !evaluation.countries.includes(data.shippingDetails.address.country)
        ) {
          const defaultCountryIsAvailable = evaluation.countries
          .includes(country);
          const nextCountry = (
              defaultCountryIsAvailable
            ? country
            : evaluation.countries[0]
          );
          if (nextCountry !== data.shippingDetails.address.country) {
            newState.error = null;
            // this.form.setValues({
            //   ...values,
            //   country: nextCountry,
            // });
            this.form.setValue(
              'country',
              nextCountry,
              // { shouldValidate: false },
            );
          }
        } else {
          newState = newState || {};
          newState.cost = evaluation.cost || {};
          newState.countries = evaluation.countries;
          newState.shipping = evaluation.shipping;
          ALL_SHIPPING_METHODS = Object.keys(evaluation.shipping || {});
          AVAILABLE_SHIPPING_METHODS = ALL_SHIPPING_METHODS
          .filter(method => evaluation.shipping[method].available !== false);
          if (!ALL_SHIPPING_METHODS.length) {
            newState = newState || {};
            newState.error = evaluation.error || evaluation.message;
            newState.payment = {};
          } else if (
            AVAILABLE_SHIPPING_METHODS.length
            && !AVAILABLE_SHIPPING_METHODS.includes(data.shipping)
          ) {
            const nextShipping = (
              (
                AVAILABLE_SHIPPING_METHODS
                .includes(ShippingMethod.MOZHE_SHOP_STANDARD)
              )
              ? ShippingMethod.MOZHE_SHOP_STANDARD
              : AVAILABLE_SHIPPING_METHODS[0]
            );
            if (nextShipping !== data.shipping) {
              newState = newState || {};
              newState.error = null;
              this.form.setValue(
                'shipping',
                nextShipping,
                { shouldValidate: false },
              );
            }
          } else if (
            ALL_SHIPPING_METHODS.length
            && !ALL_SHIPPING_METHODS.includes(data.shipping)
          ) {
            newState = newState || {};
            newState.error = evaluation.error || evaluation.message;
            const nextShipping = (
              (
                ALL_SHIPPING_METHODS
                .includes(ShippingMethod.MOZHE_SHOP_STANDARD)
              )
              ? ShippingMethod.MOZHE_SHOP_STANDARD
              : ALL_SHIPPING_METHODS[0]
            );
            if (nextShipping !== data.shipping) {
              this.form.setValue(
                'shipping',
                nextShipping,
                { shouldValidate: false },
              );
            }
          } else {
            shipping = evaluation.shipping[data.shipping];
            ALL_PAYMENT_METHODS = Object.keys(shipping.payment || {});
            AVAILABLE_PAYMENT_METHODS = ALL_PAYMENT_METHODS
            .filter((method) => {
              if (
                !shipping.payment[method]?.available
                || !isFinite(shipping.payment[method]?.price)
              ) {
                return false;
              }
              const [
                paymentMethodMain,
                paymentMethodSub,
              ] = method.split('.');
              let item = Types.PAYMENT_METHOD_MAP[paymentMethodMain];
              if (paymentMethodSub && item && item.METHODS) {
                item = item.METHODS_MAP[paymentMethodSub];
              }
              if (item.checkBrowserSupport && !item.checkBrowserSupport()) {
                delete shipping.payment[method];
                return false;
              }
              if (
                item.providers
                && !item.providers.includes(paymentCardProvider)
              ) {
                delete shipping.payment[method];
                return false;
              }
              return true;
            });
            if (!ALL_PAYMENT_METHODS.length) {
              newState = newState || {};
              newState.error = evaluation.error || evaluation.message;
              newState.shipping = evaluation.shipping;
              newState.payment = {};
            } else if (
              AVAILABLE_PAYMENT_METHODS.length
              && !AVAILABLE_PAYMENT_METHODS.includes(data.payment)
            ) {
              const nextPayment = AVAILABLE_PAYMENT_METHODS[0];
              if (nextPayment !== data.payment) {
                newState = newState || {};
                newState.error = null;
                this.form.setValue('payment', nextPayment);
              }
            } else if (
              ALL_PAYMENT_METHODS.length
              && !ALL_PAYMENT_METHODS.includes(data.payment)
            ) {
              const nextPayment = ALL_PAYMENT_METHODS[0];
              if (nextPayment !== data.payment) {
                newState = newState || {};
                newState.error = evaluation.error || evaluation.message;
                this.form.setValue('payment', nextPayment);
              }
            } else if (!newState.scheduling && data.shippingScheduledFor) {
              this.form.setValue('shippingScheduled', false);
              this.form.setValue('shippingScheduledFor', '');
            } else {
              // eslint-disable-next-line no-unused-vars
              payment = shipping.payment[data.payment];
              newState = newState || {};
              newState.shipping = evaluation.shipping;
              newState.payment = shipping.payment;
              newState.error = evaluation.error || evaluation.message;
              if (
                    data.discountCode
                && data.discountCode !== evaluation.discountCode
              ) {
                newState = newState || {};
                newState.error = props.dictionary.cartInvalidDiscountCode;
                newState.discountCodeError = true;
              } else {
                newState = newState || {};
                newState.discountCodeError = false;
              }
            }
          }
        }
      } catch (error) {
        this.debug('evaluation error:', error);
        newState = newState || {};
        newState.evaluating = false;
        newState.error = 'Mozhe® appears to be down';
        newState.countries = [];
        newState.shipping = {};
        newState.payment = {};
        if (error?.response?.status === 400) {
          newState.error = error.response.message;
        }
      }
      if (newState) {
        this.setCalculatedState(props, newState);
      }
      if (this.evaluationAborter === aborter) {
        this.setState({ evaluating: false });
      }
    }
  }

  saveSubmitFields = (data) => {
    if (!this.props.posOrderId) {
      this.props.store.setData(KEY_SUBMIT_FIELDS, data);
    }
  }

  saveItems = (data) => {
    if (!this.props.posOrderId) {
      this.props.store.setData(KEY_CART_ITEMS, data);
    }
  }

  onFormInitialize = (form) => {
    if (this.form !== form) {
      this.setState({ form });
    }
    this.form = form;
    if (!this.didInitialEvaluate) {
      this.didInitialEvaluate = true;
      this.evaluateDebounced.now(
        this.state.items || [],
        this.state.defaultSubmitFields || {},
        this.props,
      );
    }
  }

  onFormChange = (values, changedKeys) => {
    if (
      typeof values.country !== 'undefined'
      && this.lastCountry !== values.country
    ) {
      this.lastCountry = values.country;
      const changes = {
        dexpressTownId: '',
        city: '',
        postalCode: '',
        line1: '',
        line2: '',
        coordinatesString: '',
        district: '',
        area: '',
        neighbourhood: '',
        streetName: '',
        streetNumber: '',
      };
      values = {
        ...values,
        ...changes,
      };
      Object.keys(changes).forEach((key) => {
        this.form.setValue(key, changes[key]);
      });
    }
    this.saveSubmitFieldsDebounced(values);
    this.setState({
      // evaluating: true,
      error: null,
      defaultSubmitFields: values,
    });
    if (
      changedKeys.includes('city')
      || changedKeys.includes('line1')
      || changedKeys.includes('line2')
      || changedKeys.includes('streetName')
      || changedKeys.includes('streetNumber')
      || changedKeys.includes('area')
      || changedKeys.includes('firstName')
      || changedKeys.includes('lastName')
      || changedKeys.includes('phoneNumberString')
      || changedKeys.includes('email')
    ) {
      this.debug('form changed (debouncing):', values, changedKeys);
      this.evaluateDebounced(
        this.state.items,
        values,
        this.props,
      );
    } else if (
      changedKeys.includes('country')
      || changedKeys.includes('shipping')
      || changedKeys.includes('shippingScheduled')
      || changedKeys.includes('shippingScheduledFor')
      || changedKeys.includes('payment')
      || changedKeys.includes('coordinatesString')
      || changedKeys.includes('discountCode')
    ) {
      this.debug('form changed:', values, changedKeys);
      this.evaluateDebounced.now(
        this.state.items,
        values,
        this.props,
      );
    }
  }

  submit = async (values) => {
    let order = null;
    let data = null;
    clearTimeout(this.submitCardTimeout);
    try {
      this.setState({ submitting: true });
      data = this.getOrderSubmitData(
        this.state.items,
        values,
        this.props,
        true,
      );
      const query = this.getOrderSubmitQuery(
        this.state.items,
        values,
        this.props,
      );
      const { paymentCardProvider = 'PAYTABS' } = Types.getSystemCountry(
        this.props.store.data.systemCountry,
      );
      const [payment, paymentSubmethod] = (values.payment || '').split('.');
      if (payment === 'CARD') {
        data.paymentCardProviderData = {};
        if (paymentCardProvider === 'STRIPE') {
          data.paymentCardProvider = 'STRIPE';
          data.paymentCardProviderData.stripePaymentIntent = (
            this.state.stripePaymentIntent.paymentId
          );
        } else if (paymentCardProvider === 'CHECKOUT') {
          data.paymentCardProvider = 'CHECKOUT';
          if (paymentSubmethod === 'APPLE_PAY') {
            query.precheckout = 'true';
          } else {
            const frames = await this.checkoutCardInputRef.current.getFrames();
            if (!frames.cardholder) {
              frames.cardholder = {};
            }
            const { shippingDetails } = data;
            const billingDetails = (
              data.billingDetailsDiffer
              ? data.billingDetails
              : data.shippingDetails
            );
            frames.cardholder.name = (
              billingDetails.name
              || shippingDetails.name
            );
            frames.enableSubmitForm();
            this.submitCardTimeout = setTimeout(
              () => this.setState({ submitting: false }),
              2000,
            );
            const checkoutResult = await frames.submitCard();
            clearTimeout(this.submitCardTimeout);
            // eslint-disable-next-line no-console
            // console.log(checkoutResult);
            data.paymentCardProviderData.checkoutCardData = checkoutResult;
            data.paymentCardProviderData.checkoutCardToken = (
              checkoutResult.token
            );
          }
        } else if (paymentCardProvider === 'ALLSECURE') {
          data.paymentCardProvider = 'ALLSECURE';
          const allsecureResult = await new Promise((resolve, reject) => {
            const cardSubmitData = {
              first_name: data?.shippingDetails?.firstName,
              last_name: data?.shippingDetails?.lastName,
              month: this.allsecureCardInputRef.current.data.expiresMonth,
              year: this.allsecureCardInputRef.current.data.expiresYear,
            };
            // this.submitCardTimeout = setTimeout(
            //   () => this.setState({ submitting: false }),
            //   2000,
            // );
            this.allsecureCardInputRef.current.payment.tokenize(
              cardSubmitData,
              (token, card) => {
                // console.log('token:', token);
                // console.log('card:', card);
                resolve({ ...card, token });
              },
              (errors) => {
                // console.log('errors:', errors);
                const error = new Error(
                  errors?.[0]?.message || 'Check card details and try again'
                );
                error.errors = errors;
                reject(error);
              }
            );
          });
          // clearTimeout(this.submitCardTimeout);
          data.paymentCardProviderData.allsecureCardData = allsecureResult;
          data.paymentCardProviderData.allsecureCardToken = (
            allsecureResult.token
          );
        } else {
          data.paymentCardProvider = 'PAYTABS';
          clearTimeout(this.submitCardTimeout);
        }
      }
      const { currency: systemCurrency } = Types.getSystemCountry(
        this.props.store.data.systemCountry,
      );
      order = await this.props.api.post(
        'orders/submit',
        data,
        query,
      );
      this.debug('submit order:', order);
      if (order) {
        if (order.status && /^INVALID/.test(order.status)) {
          // throw new Error(order.message);
          // eslint-disable-next-line no-console
          console.log('submit invalid order error:', order.message);
          throw new Error(order.message || 'Submit failed');
        }

        if (order.payment.provider === 'STRIPE') {
          const { stripe, elements } = this.stripeCardRef.current;

          // );
          if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
          }

          const {
            error: stripeFetchUpdatesError,
          } = await elements.fetchUpdates();
          if (stripeFetchUpdatesError) {
            this.setState({ stripePaymentIntent: null });
            await new Promise(res => setTimeout(res, 1000));
            this.getStripePaymentIntent(true);
            throw new Error(error.message || 'Submit failed');
          }

          const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
              payment_method_data: {
                billing_details: {
                  address: {
                    country: data.shippingDetails.address.country,
                    postal_code: (
                      data.shippingDetails.address.postalCode
                      || '00000'
                    ),
                  },
                },
              },
              // Make sure to change this to your payment completion page
              return_url: `${
                this.props.api.baseUrl
              }/orders/payment/stripe?orderId=${
                order._id
              }`,
            },
          });
          if (error) {
            this.setState({ stripePaymentIntent: null });
            await new Promise(res => setTimeout(res, 1000));
            this.getStripePaymentIntent(true);
            throw new Error(error.message || 'Submit failed');
          }
        }

        this.props.analytics.trigger.ORDER_SUBMIT({
          order,
          currency: systemCurrency,
        });
        const redirectUrl = (
             order?.payment?.redirectUrl
          || order?.payment?.paytabsPaymentPageUrl
          || order?.payment?.checkoutRedirectUrl
          || order?.payment?.allsecureRedirectUrl
        );
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          this.props.store.removeData(KEY_CART_ITEMS);
          this.props.store.removeData(KEY_CART_OPEN);
          await new Promise(resolve => setTimeout(resolve, 300));
          // eslint-disable-next-line no-undef
          window.location.href = `/order/${order._id}?submitted=true`;
        }
      }
      this.setState({
        error: null,
        submitting: false,
      }, () => {
        this.props.api.post('abandoned-carts/clear', {
          email: data?.shippingDetails?.email || '',
          storeId: this.props.store.data._id || 'not-id',
        });
      });
    } catch (error) {
      clearTimeout(this.submitCardTimeout);
      this.debug('submit error:', error, error.response);
      let { message } = isString(error) ? { message: error } : error;
      if (error.response && error.response.status < 500) {
        // eslint-disable-next-line prefer-destructuring
        message = error.response.message;
        // 'Could not submit at this time, please try again later';
      }
      this.setState({
        error: message,
        submitting: false,
      }, () => {
        this.props.api.post('abandoned-carts/clear', {
          email: data?.shippingDetails?.email || '',
          storeId: this.props.store.data._id || 'not-id',
        });
      });
    }
  }

  setError = error => this.setState({ error })

  setItemComment = (sku, comment) => {
    this.setCalculatedState(this.props, {
      items: this.state.items.map((item) => {
        if (item.sku === sku) {
          item = { ...item };
          if (comment) {
            item.comment = comment;
          } else {
            delete item.comment;
          }
        }
        return item;
      }),
    });
  }

  addToCart = (sku, price, meta, quantity = 1, replace = false) => {
    // console.log(meta);
    let productAlreadyInCart = false;
    const { currency: systemCurrency } = (
      this.props.store?.data
      ? Types.getSystemCountry(this.props.store.data.systemCountry)
      : {}
    );
    // eslint-disable-next-line react/no-access-state-in-setstate
    const newItems = this.state.items.map((item) => {
      if (item.sku === sku) {
        productAlreadyInCart = true;
        const newQuantity = replace ? quantity : item.quantity + quantity;
        if (newQuantity > item.quantity) {
          this.props.analytics.trigger.CART_ADD({
            sku: item.sku,
            price: item.price,
            meta: item.meta,
            quantity,
            replace,
            currency: systemCurrency,
          });
        } else if (newQuantity < item.quantity) {
          this.props.analytics.trigger.CART_REMOVE({
            sku: item.sku,
            price: item.price,
            meta: item.meta,
            quantity,
            replace,
            currency: systemCurrency,
          });
        }
        return this.getCalculatedItem({
          ...item,
          meta: meta || item.meta,
          quantity: newQuantity,
        });
      }
      return item;
    });
    if (!productAlreadyInCart) {
      const item = this.getCalculatedItem({ sku, price, meta, quantity });
      this.props.analytics.trigger.CART_ADD({
        sku: item.sku,
        price: item.price,
        meta: item.meta,
        quantity: item.quantity,
        currency: systemCurrency,
      });
      // console.log('item:', item);
      newItems.push(item);
    }
    return this.setCalculatedState(this.props, { items: newItems });
  }

  removeFromCart = (sku, quantity = 1) => {
    const { currency: systemCurrency } = (
      this.props.store?.data
      ? Types.getSystemCountry(this.props.store.data.systemCountry)
      : {}
    );
    const newItems = this.state.items.reduce(
      (agr, item) => {
        if (item.sku === sku) {
          const newQuantity = item.quantity - quantity;
          if (newQuantity > 0) {
            agr.push(this.getCalculatedItem({
              ...item,
              quantity: newQuantity,
            }));
          }
          this.props.analytics.trigger.CART_REMOVE({
            sku: item.sku,
            price: item.price,
            meta: item.meta,
            quantity: Math.min(item.quantity, quantity),
            currency: systemCurrency,
          });
        } else {
          agr.push(item);
        }
        return agr;
      },
      [],
    );
    return this.setCalculatedState(this.props, { items: newItems });
  }

  replaceCart = (rawItems = []) => {
    const items = rawItems.map(({
      sku,
      price,
      meta,
      quantity = 1,
    }) => (
      this.getCalculatedItem({ sku, price, meta, quantity })
    ));
    return this.setCalculatedState(this.props, { items });
  }

  ensureInCart = (rawItems = []) => {
    const newItemsSkus = [];
    const newItems = rawItems.map(({
      sku,
      price,
      meta,
      quantity = 1,
    }) => {
      newItemsSkus.push(sku);
      return this.getCalculatedItem({ sku, price, meta, quantity });
    });
    const oldItems = this.state.items.filter(({ sku }) => (
      !newItemsSkus.includes(sku)
    ));

    return this.setCalculatedState(this.props, {
      items: [
        ...oldItems,
        ...newItems,
      ],
    });
  }

  ensureNotInCart = (skus = []) => {
    const newItems = this.state.items.reduce(
      (agr, item) => {
        if (!skus.includes(item.sku)) {
          agr.push(item);
        }
        return agr;
      },
      [],
    );
    return this.setCalculatedState(this.props, { items: newItems });
  }

  clearCart = () => (
    this.setCalculatedState(this.props, { items: [] })
  )

  getStripePaymentIntent = async (force = false) => {
    const { paymentCardProvider } = Types.getSystemCountry(
      this.props.store.data.systemCountry
    );
    if (!paymentCardProvider || paymentCardProvider !== 'STRIPE') {
      return;
    }
    if (
      !Object.keys(this.state.stripePaymentIntent || {}).length > 0
      || force
    ) {
      const stripePaymentIntent = await this.props.api.post(
        '/orders/payment/stripe',
        {
          requestEvent: 'createIntent',
        },
      );
      if (stripePaymentIntent) {
        if (force) {
          setTimeout(() => this.setState({ error: null }), 10000)
        }
        this.setState({ stripePaymentIntent });
      }
    }
  }

  toggleShowCart = (force = null) => {
    const isOpen = !isBoolean(force)
      ? !this.state.isOpen
      : force;
    if (this.state.isOpen !== isOpen) {
      this.setState({ isOpen });
      this.props.store.setData(KEY_CART_OPEN, !!isOpen);
    }
  }

  render() {
    return (
      this.state.loading
      ? null
      : super.render()
    );
  }
}

export function useCart() {
  return useContext(Context);
}

const defaultShippingCountryOptions = Types.COUNTRIES.map(
  ({ name, iso3a2 }) => ({
    value: iso3a2,
    label: name,
  })
);

export function useShippingCountryOptions() {
  const { countries = [] } = useCart();
  if (countries && countries.length) {
    const shippingCountryOptions = (
      countries.reduce(
        (agr, country) => {
          if (Types.COUNTRIES_MAP[country]) {
            agr.push({
              value: country,
              label: Types.COUNTRIES_MAP[country].name,
            });
          }
          return agr;
        },
        [],
      )
      .sort((a, b) => (a.label > b.label ? 1 : -1))
    );
    return shippingCountryOptions;
  }
  return defaultShippingCountryOptions;
}

export function useShippingOptions() {
  const { data: storeData } = useStore();
  const [currencyObject] = useStoreCurrency();
  const {
    cartShippingAddressIncomplete,
    cartUnavailable,
    cartPaymentFree,
    SHIPPING_METHOD_LABELS_MAP,
    SHIPPING_METHOD_DURATIONS_MAP,
  } = useDictionary();
  const { shipping = {} } = useCart();

  const shippingOptions = useMemo(
    () => {
      let options = Types.SHIPPING_METHOD.reduce(
        (agr, methodId, i) => {
          const method = shipping[methodId];
          if (method) {
            const disabled = method.available === false;
            const description = [];
            if (disabled) {
              description.push(
                method.shippingAddressIncomplete
                ? cartShippingAddressIncomplete
                : method.message || cartUnavailable
              );
            } else {
              if (!method.free && method.price > 0) {
                description.push(getPriceWithCurrency(
                  method.price,
                  storeData,
                  currencyObject,
                ));
              } else {
                description.push(cartPaymentFree);
              }
              const durationLabel = SHIPPING_METHOD_DURATIONS_MAP[methodId];
              if (durationLabel) {
                description.push(durationLabel);
              }
            }
            agr.push({
              disabled,
              label: SHIPPING_METHOD_LABELS_MAP[methodId],
              description,
              value: methodId,
              index: i,
            });
          }
          return agr;
        },
        [],
      );
      options = options.slice().sort((a, b) => (
          a.disabled > b.disabled ? -1
        : a.disabled < b.disabled ? 1
        : (
              a.index > b.index ? 1
            : a.index < b.index ? -1
            : 0
          )
      ));
      return options;
    },
    [
      shipping,
      storeData,
      currencyObject,
      cartPaymentFree,
      cartUnavailable,
      cartShippingAddressIncomplete,
      SHIPPING_METHOD_LABELS_MAP,
      SHIPPING_METHOD_DURATIONS_MAP,
    ],
  );

  return shippingOptions;
}

export function useShippingScheduledOptions() {
  const {
    cartScheduledShippingNow,
    cartScheduledShippingLater,
  } = useDictionary();
  return [{
    label: cartScheduledShippingNow,
    value: false,
  }, {
    label: cartScheduledShippingLater,
    value: true,
  }];
}

export function usePaymentOptions() {
  const { data: storeData } = useStore();
  const [currencyObject] = useStoreCurrency();
  const {
    cartUnavailable,
    cartPaymentFree,
    PAYMENT_METHOD_LABELS_MAP,
    PAYMENT_SUBMETHOD_LABELS_MAP,
  } = useDictionary();
  const { data: { systemCountry } } = useStore();
  const { payment = {} } = useCart();

  const paymentOptions = useMemo(
    () => {
      const { paymentCardProvider } = Types.getSystemCountry(systemCountry);
      let i = 0;
      let options = Types.PAYMENT_METHOD_LIST.reduce(
        (agr, method) => {
          i++;
          const methodId = method.id;
          const paymentMethod = payment[method.id];
          if (
            paymentMethod
            && (
              !method.checkBrowserSupport
              || method.checkBrowserSupport()
            )
            && (
              !method.providers
              || method.providers.includes(paymentCardProvider)
            )
          ) {
            const disabled = paymentMethod.available === false;
            const description = [];
            if (disabled) {
              description.push(paymentMethod.message || cartUnavailable);
            } else if (!paymentMethod.free && paymentMethod.price > 0) {
              description.push(getPriceWithCurrency(
                paymentMethod.price,
                storeData,
                currencyObject,
              ));
            } else {
              description.push(cartPaymentFree);
            }
            agr.push({
              // disabled,
              label: PAYMENT_METHOD_LABELS_MAP[methodId],
              description,
              value: methodId,
              index: i,
            });
          }
          if (method.METHODS_LIST && method.METHODS_LIST.length) {
            method.METHODS_LIST.forEach((submethod) => {
              i++;
              const submethodId = `${method.id}.${submethod.id}`;
              const paymentSubmethod = payment[submethodId];
              if (
                paymentSubmethod
                && paymentSubmethod.available
                && (
                  !submethod.checkBrowserSupport
                  || submethod.checkBrowserSupport()
                )
                && (
                  !submethod.providers
                  || submethod.providers.includes(paymentCardProvider)
                )
              ) {
                const disabled = paymentSubmethod.available === false;
                const description = [];
                if (disabled) {
                  description.push(
                    paymentSubmethod.message || cartUnavailable
                  );
                } else if (
                  !paymentSubmethod.free
                  && paymentSubmethod.price > 0
                ) {
                  description.push(getPriceWithCurrency(
                    paymentSubmethod.price,
                    storeData,
                    currencyObject,
                  ));
                } else {
                  description.push(cartPaymentFree);
                }
                agr.push({
                  // disabled,
                  label: PAYMENT_SUBMETHOD_LABELS_MAP[method.id][submethod.id],
                  description,
                  value: submethodId,
                  index: i,
                });
              }
            });
          }
          return agr;
        },
        [],
      );
      options = options.slice().sort((a, b) => (
          a.disabled > b.disabled ? -1
        : a.disabled < b.disabled ? 1
        : (
              a.index > b.index ? 1
            : a.index < b.index ? -1
            : 0
          )
      ));
      return options;
    },
    [
      payment,
      storeData,
      cartPaymentFree,
      systemCountry,
      currencyObject,
      cartUnavailable,
      PAYMENT_METHOD_LABELS_MAP,
      PAYMENT_SUBMETHOD_LABELS_MAP,
    ],
  );

  return paymentOptions;
}

// eslint-disable-next-line no-unused-vars
export function useShouldHideShippingOptions(shippingOptions = []) {
  const { isAbstract, posOrderId } = useCart();
  return !!(isAbstract || posOrderId);
}

export function useShouldHidePaymentOptions(paymentOptions = []) {
  const { isAbstract } = useCart();
  return (
    isAbstract
    && paymentOptions?.length === 1
    && paymentOptions?.[0]?.value === 'CARD'
  );
}
