import { modularScale as polishedModularScale, em, rem } from 'polished';

const modularScaleText = (scale, base = em(22), ratio = 1.5) => (
  polishedModularScale(scale, base, ratio)
);

const DEFAULTS = {
  family: 'Montserrat',
  weight: {
    normal: 400,
    bold: 600,
  },
  scaling: 1,
  textTransform: 'none',
  letterSpacing: 0,
}

const FONTS = {
  title: DEFAULTS,
  body: DEFAULTS,
  label: {
    ...DEFAULTS,
    textTransform: 'uppercase',
    letterSpacing: '3px',
  },
};

function transformFontObject(input) {
  const output = {};

  Object.keys(input).forEach((key) => {
    const value = input[key];

    if (value !== 'default') {
      if (key === 'family') {
        output[key] = value.replaceAll('+', ' ');
      } else if ((key === 'variantNormal' || key === 'variantBold')
      && value !== 'default') {
        output.weight = output.weight || {};
        const weightKey = key === 'variantNormal' ? 'normal' : 'bold';
        output.weight[weightKey] = value;
      } else if (typeof value === 'string') {
        output[key] = value.toLowerCase();
      } else {
        output[key] = value;
      }
    }
  });

  return output;
}

const fontObjects = ['title', 'label', 'body'];

const getFontConfig = ({ style }) => fontObjects.reduce((acc, key) => ({
  ...acc,
  [key]: {
    ...FONTS[key],
    ...transformFontObject(style?.fonts[key]),
  },
}), {});

const getThemeFonts = ({ style }) => {
  const fontConfig = getFontConfig({ style });
  return ({
    fontConfig,

    fonts: {
      title:
        `${fontConfig.title.family}, Montserrat, Avenir, Beiruti, sans-serif`,
      text:
        `${fontConfig.body.family}, Montserrat, Avenir, Beiruti, sans-serif`,
      label:
        `${fontConfig.label.family}, Montserrat, Avenir, Beiruti, sans-serif`,
    },
    fontWeights: {
      title: fontConfig.title.weight.normal,
      titleBold: fontConfig.title.weight.bold,
      text: fontConfig.body.weight.normal,
      textBold: fontConfig.body.weight.bold,
      label: fontConfig.label.weight.normal,
      labelBold: fontConfig.label.weight.bold,
    },
    fontSizes: {
      title: modularScaleText(3 * fontConfig.title.scaling),
      text: rem(16 * fontConfig.body.scaling),
      label: rem(15 * fontConfig.label.scaling),
      copy: rem(14 * fontConfig.body.scaling),

      xs: modularScaleText(0 * fontConfig.title.scaling),
      s: modularScaleText(1 * fontConfig.title.scaling),
      m: modularScaleText(2 * fontConfig.title.scaling),
      l: modularScaleText(3 * fontConfig.title.scaling),
      xl: modularScaleText(4 * fontConfig.title.scaling),

      h1: modularScaleText(4 * fontConfig.title.scaling),
      h2: modularScaleText(3 * fontConfig.title.scaling),
      h3: modularScaleText(2 * fontConfig.title.scaling),
      h4: modularScaleText(1 * fontConfig.title.scaling),
      h5: modularScaleText(0 * fontConfig.title.scaling),
    },
    letterSpacings: {
      title: fontConfig.title.letterSpacing,
      text: fontConfig.body.letterSpacing,
      label: fontConfig.label.letterSpacing,
    },
    textTransforms: {
      title: fontConfig.title.textTransform,
      text: fontConfig.body.textTransform,
      label: fontConfig.label.textTransform,
    },
  })
};

export default getThemeFonts;
