import dictionary from '../assets/dictionary.json';

const Types = {};

Types.DICTIONARY_OBJECT = dictionary;

Types.LANGUAGE_DICTIONARY_KEYS = (
  Object.values(dictionary)
  .reduce(
    (agr, group) => {
      agr.push(...group)
      return agr;
    },
    [],
  )
  .map(({ key }) => key)
  .sort(
    (a, b) => (a > b ? 1 : a < b ? -1 : 0)
  )
);

export default Types;
