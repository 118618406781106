import React, { useMemo, useRef, useState, useEffect } from 'react';

import { createNamedStyled } from '../../stitches.config';

import Base from './Base';

const styled = createNamedStyled('Video');

const Node = styled(Base, {
  width: '100%',
  height: '100%',
});

const Video = ({ node, css, renderNode, ...props }) => {
  const videoRef = useRef(null);
  const [isPowerSavingMode, setIsPowerSavingMode] = useState(false);

  const finalCss = useMemo(
    () => ({
      ...node?.video_objectFit !== 'NORMAL' ? {
        objectFit: node?.video_objectFit?.toLowerCase(),
      } : {},
      ...css,
    }),
    [css, node?.video_objectFit],
  );

  useEffect(() => {
    const videoElement = videoRef.current;
    const promise = videoElement?.play();
    if (promise) {
      promise.catch(() => {
        setIsPowerSavingMode(true);
      });
    }

    return () => {
      if (videoElement) {
        videoElement.pause();
      }
    }
  }, []);

  return (
    !!node?.video_file?.src && (
      <Node
        {...props}
        ref={videoRef}
        node={node}
        tag={isPowerSavingMode ? 'img' : 'video'}
        src={node?.video_file?.src}
        poster={node?.video_poster?.src}
        autoPlay={node?.video_autoplay}
        loop={node?.video_loop}
        muted={node?.video_mute}
        controls={node?.video_controls}
        playsInline
        css={finalCss}
      />
    )
  );
};

export default Video;
