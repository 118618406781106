import React, { useMemo } from 'react';

import optimizeInlineCss from '../../helpers/optimizeInlineCss';

export default function Block({
  node,
  renderNode,
  ...props
}) {
  const finalCss = useMemo(
    () => ({
      ...node.css,
      ...(node?.hide || []).reduce((acc, device) => ({
        ...acc,
        [`@${device.toLowerCase()}`]: { display: 'none !important' },
      }), {}),
      ...node?.stitchesCss,
    }),
    [node.css, node?.hide, node?.stitchesCss],
  );

  return (
    <>
      {node.block_customCss && (
        <style>{optimizeInlineCss(node.block_customCss)}</style>
      )}
      {
        (
          (node.content || node.block_items || {})?.nodes || []
        ).map(item => React.cloneElement(renderNode({
          ...item,
          name: node.name || item.name,
          id: node.id || item.id,
          className: `${item.className || ''} ${node.className || ''}`,
          css: finalCss,
        }, props)))
      }
    </>
  );
}
