import React, {
  useEffect, useMemo, useCallback, useState, forwardRef,
} from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useTheme } from '../../theme';

const Parallax = forwardRef(({ as, children, effect, ...props }, ref) => {
  const { transitions } = useTheme();
  const imgAnimation = useAnimation();
  const innerRef = React.useRef(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleMouseMove = useCallback(
    (e) => {
      const { clientX, clientY } = e;
      setMousePosition({
        x: clientX - window.innerWidth / 2,
        y: clientY - window.innerHeight / 2,
      });
    },
    []
  );

  const handleScroll = useCallback(
    () => {
      const initialY = innerRef.current.offsetTop;
      const { scrollY } = window;
      setScrollPosition(scrollY - initialY);
    },
    []
  );

  const updateAnimation = useCallback(() => {
    const offsetFactor = 100 / (effect.parallax_depth * 10);
    const moveX = mousePosition.x / offsetFactor;
    const moveY = (mousePosition.y / offsetFactor)
      + (scrollPosition / ((1 - effect.parallax_depth) * 10));

    imgAnimation.start({
      x: moveX,
      y: moveY,
      transition: effect.parallax_easing === 'LINEAR'
        ? { duration: 0 }
        : effect.parallax_trigger !== 'SCROLL'
          ? transitions.spring
          : { duration: 0 },
    });
  }, [
    mousePosition,
    scrollPosition,
    effect.parallax_depth,
    effect.parallax_easing,
    effect.parallax_trigger,
    imgAnimation,
    transitions.spring,
  ]);

  useEffect(() => {
    updateAnimation();
  }, [mousePosition, scrollPosition, updateAnimation]);

  useEffect(() => {
    if (effect.parallax_trigger === 'MOUSE') {
      window.addEventListener('mousemove', handleMouseMove);
    } else if (effect.parallax_trigger === 'BOTH') {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('scroll', handleScroll);
    } else {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [effect.parallax_trigger, handleMouseMove, handleScroll]);

  const finalAs = useMemo(() => (as ? motion(as) : motion.div), [as]);

  const child = React.Children.only(children);

  return React.cloneElement(child, {
    ref: React.useCallback(node => {
      innerRef.current = node;
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    }, [innerRef, ref]),
    ...child.props,
    ...props,
    css: {
      ...child.props.css,
      ...props.css,
    },
    as: finalAs,
    initial: false,
    animate: imgAnimation,
    className: props.node.className,
  });
});

const ParallaxWrapper = forwardRef(
  ({ children, as, effect, ...props }, ref) => {
    if (effect) {
      return (
        <Parallax
          as={as}
          ref={ref}
          effect={effect}
          {...props}
        >
          {children}
        </Parallax>
      );
    }

    const child = React.Children.only(children);
    return React.cloneElement(children, {
      ref,
      as,
      ...child.props,
      ...props,
      css: {
        ...props.css,
        ...child.props.css,
      },
    });
  }
);

export default ParallaxWrapper;
