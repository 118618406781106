import createDebounced from 'lodash/debounce';

import React, {
  forwardRef,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { useController } from 'react-hook-form';

import { createNamedStyled, keyframes } from '../../stitches.config';
import useClickOutside from '../../helpers/useClickOutside';

import { Paragraph } from './Text';

const styled = createNamedStyled('Input');

const Wrapper = styled.named('Wrapper')('div', {
  position: 'relative',
  fontFamily: '$label',
  paddingTop: 'calc($xs / 1.5)',
  marginBottom: 'calc($xs / 1.5)',
  cursor: 'text',
  width: '100%',

  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

const Label = styled.named('Label')(Paragraph, {
  position: 'absolute',
  whiteSpace: 'nowrap',
  pointerEvents: 'none',
  opacity: 0.5,

  top: 'calc($xs / 3)',
  padding: 'calc($xs / 3) 0',

  transformOrigin: 'bottom $custom$inlineStart',
  transition: 'transform $m $ease',

  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  variants: {
    isPinned: {
      true: {
        transform: 'translateY(-75%) scale(0.75)',
      },
    },
  },
});

const Value = styled.named('Value')('input', {
  // all: 'unset',
  border: 'none',
  background: 'none',
  outline: 'none',
  WebkitBoxShadow: 'none',
  MozBoxShadow: 'none',
  boxShadow: 'none',
  borderRadius: 0,

  fontFamily: '$text',
  fontSize: '$text',

  width: '100%',
  padding: 'calc($xs / 3) 0',
  color: '$text',

  transition: 'border-bottom $s',

  // TODO: Replace with $borderActive
  // borderBottom: '1px solid $border',
  // '@supports (color: color-mix(in srgb, white, transparent))': {
  //   borderBottom: '1px solid color-mix(in srgb, $border 25%, transparent)',
  // },
  borderBottom: '1px solid $border',

  '&:hover': { borderColor: '$borderActive' },
  '&:focus': { borderColor: '$borderActive' },

  variants: {
    hasError: {
      true: {
        borderColor: '$error !important',
      },
    },
  },
});

const Options = styled.named('Options')('div', {
  position: 'absolute',
  width: '100%',
  top: '100%',
  zIndex: 2,

  display: 'flex',
  flexDirection: 'column',
  background: '$dropdownBackground',
  borderRadius: '$m',
  boxShadow: '$m',

  overflow: 'scroll',
  maxHeight: 400,

  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
    width: 0,
    height: 0,
  },

  variants: {
    isOpen: {
      true: {
        opacity: 1,
        pointerEvents: 'auto',
        transform: 'translateY($space$xs)',
        transition: 'opacity $s, transform $m $ease',
      },
      false: {
        opacity: 0,
        pointerEvents: 'none',
        transform: 'translateY(-$space$m)',
        transition: 'opacity $s, transform $xl',
      },
    },
  },
});

const Option = styled.named('Option')(Paragraph, {
  // TODO: check if we can somehow
  // find out font family type dynamically
  fontFamily: '$text, sans-serif',

  width: '100%',
  padding: 'calc($s / 1.5)',
  cursor: 'pointer',

  color: '$dropdownForeground',
  transition: 'background $s, color $s',
  '&:hover': {
    background: '$buttonBackground',
    color: '$buttonForeground',
    '*': { color: '$buttonForeground' },
  },
});

const slideIn = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-20%)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const Error = styled.named('Error')(Paragraph, {
  color: '$error',
  opacity: 0,
  fontSize: '80%',
  marginTop: 'calc($xs / 3)',
  transform: 'translateY(-20%)',
  animation:
    `${slideIn} $transitions$m $transitions$xs $transitions$ease forwards`,
});

const prefixValue = (prefix, value) => {
  if (prefix) { return value?.startsWith?.(prefix) ? value : (prefix + value); }
  return value;
};

const filterValue = (filter, value) => {
  if (filter) {
    // return value.replace(new RegExp(`[^${pattern}]`, 'g'), '');
    return value.replace(filter, '');
  }
  return value;
};

const formatValue = (format, value) => {
  if (format) { return format(value); }
  return value;
};

const InputText = ({
  value,
  label,
  onChange,
  onBlur,
  onFocus,

  prefix,
  filter,
  format,

  field,
  error,

  hidden,
  wrapperStyle,
  ...props
}) => {
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = (event) => {
    if (onFocus) {
      onFocus(event);
    }
    setIsFocused(true);
    if (!props.type || props.type === 'text') {
      inputRef.current.setSelectionRange(
        field?.value?.length,
        field?.value?.length,
      );
    }
  };

  const handleOnChange = (event) => {
    if (onChange) {
      onChange(event.target.value);
    } else {
      field && field.onChange(event.target.value);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') { inputRef.current.blur(); }
    if (event.key === 'Tab') { inputRef.current.blur(); }
  };

  const handleKeyUp = () => {
    if (prefix || filter || format) {
      const prefixedValue = prefixValue(prefix, value);
      const filteredValue = filterValue(filter, prefixedValue);
      const formattedValue = formatValue(format, filteredValue);

      if (onChange) {
        onChange(formattedValue);
      } else {
        field.onChange(formattedValue);
      }
    }
  };

  const handleBlur = (event) => {
    if (onBlur) {
      onBlur(event);
    }
    if (!value) {
      setIsFocused(false);
    }
  };

  return (
    <Wrapper hidden={hidden} style={wrapperStyle}>
      <Value
        {...props}
        value={value}
        ref={inputRef}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onFocus={handleFocus}
        onChange={handleOnChange}
        onBlur={handleBlur}
        hasError={!!error}
      />
      <Label
        isPinned={
          isFocused
          || !!field?.value?.length
          || !!value?.length
          || props.type === 'date'
        }
      >
        {label}
      </Label>
      {
        error
        ? <Error className="input-error">{error.message}</Error>
        : null
      }
    </Wrapper>
  );
};

const InputOptions = ({
  label,
  onChange,
  onSearch,

  options,
  disableFiltering,

  field,
  form,
  error,

  hidden,
  wrapperStyle,
  ...props
}) => {
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);

  const valueOption = useMemo(
    () => options.find(option => option.value === field.value),
    [options, field.value],
  );
  const [searchValue, setSearchValue] = useState(
    valueOption?.label || field.value || '',
  );
  const [isFocused, setIsFocused] = useState(false);
  const [selected, setSelected] = useState(options.indexOf(valueOption));

  const filteredOptions = useMemo(
    () => (
        disableFiltering
      ? options
      : options?.filter(option => (
          option?.label?.toLowerCase().includes(searchValue?.toLowerCase())
        ))
    ),
    [searchValue, disableFiltering, options],
  );

  useEffect(() => {
    if (field) {
      if (!isFocused) {
        onSearch && onSearch('');
      }
      if (!isFocused && valueOption) {
        setSearchValue(''); // resets value on click
      } else if (!isFocused && !field?.value) {
        setSearchValue('');
      } else if (!isFocused && field.value) {
        setSearchValue(field.value);
      }
    }
  }, [field, isFocused, valueOption, onSearch]);

  const handleFocus = () => {
    setIsFocused(true);

    if (valueOption?.label && (!props.type || props.type === 'text')) {
      inputRef.current.setSelectionRange(
        valueOption.label?.length,
        valueOption.label?.length,
      );
    }
  };

  const handleOnSearch = (event) => {
    setSearchValue(event.target.value);

    onSearch && onSearch(event.target.value);
  };

  const handleOnChange = (option) => {
    const newValue = (
        typeof option?.value === 'undefined'
      ? option
      : option.value
    );
    const newIndex = options.indexOf(option);
    setSearchValue(option?.label);
    setSelected(newIndex);

    onChange && onChange(newValue);
    field && field.onChange(newValue);

    inputRef.current.blur();
    setIsFocused(false);
  };

  const handleOnCancel = () => {
    if (valueOption) {
      setSearchValue(valueOption.label);
    }

    inputRef.current.blur();
    setIsFocused(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') handleOnCancel();
    if (event.key === 'Tab') handleOnCancel();

    if (event.key === 'ArrowDown') {
      event.preventDefault();
      event.stopPropagation();

      setSelected(prev => Math.min(prev + 1, filteredOptions.length - 1));
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault();
      event.stopPropagation();

      setSelected(prev => Math.max(prev - 1, 0));
    }

    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      handleOnChange(filteredOptions[selected]);
    }
  };

  useClickOutside(wrapperRef, handleOnCancel);

  return (
    <Wrapper ref={wrapperRef} hidden={hidden} style={wrapperStyle}>
      <Value
        {...props}
        value={isFocused ? searchValue : (valueOption?.label || field.value)}
        ref={inputRef}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onChange={handleOnSearch}
        hasError={!!error}
      />
      <Label isPinned={(isFocused || !!field.value)}>
        {label}
      </Label>
      <Options isOpen={isFocused}>
        {
          filteredOptions.reduce(
            (agr, option, index) => {
              if (!option.hidden) {
                agr.push(
                  <Option
                    key={option._id || option.value}
                    onClick={() => handleOnChange(option)}
                    css={{
                      background: index === selected ? '$accentLight' : 'unset',
                      '&:hover': { color: '$buttonForeground' },
                    }}
                  >
                    {option.label}
                  </Option>
                );
              }
              return agr;
            },
            [],
          )
        }
      </Options>
      {
        error
        ? <Error className="input-error">{error.message}</Error>
        : null
      }
    </Wrapper>
  );
};

const Input = forwardRef(({ options, ...props }, ref) => (
  options ? (
    <InputOptions ref={ref} options={options} {...props} />
  ) : (
    <InputText ref={ref} {...props} />
  )
));

const DebouncedInput = forwardRef(
  ({ debounce = 300, ...props }, ref) => {
    const [value, setValue] = useState(props.field.value);
    const onChangeRef = useRef(props.field.onChange);
    onChangeRef.current = props.field.onChange;
    const onChangeDebounced = useMemo(
      () => createDebounced(
        currentValue => onChangeRef.current(currentValue),
        debounce,
      ),
      [debounce],
    );
    useEffect(
      () => {
        if (value !== props.field.value) {
          setValue(props.field.value);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.field.value],
    );
    return (
      <Input
        {...props}
        ref={ref}
        value={value}
        onChange={(onChangeValue) => {
          // console.log('value:', onChangeValue);
          setValue(onChangeValue);
          onChangeDebounced(onChangeValue);
        }}
      />
    );
  },
);

export const ControlledInput = forwardRef((props, ref) => {
  props = {
    ...props,
    'data-cart-form-name': props.name,
  }
  const { form, field } = useController(props);

  const handleOnChange = (value) => {
    field.onChange && field.onChange(value);
    props.onChange && props.onChange(value);
  };

  return (
    props.debounce > 0
    ? (
        <DebouncedInput
          {...props}
          {...field}
          form={form}
          ref={ref}
          field={field}
          onChange={handleOnChange}
        />
      )
    : (
      <Input
        {...props}
        {...field}
        form={form}
        ref={ref}
        field={field}
        onChange={handleOnChange}
      />
    )
  );
});

export default Input;
