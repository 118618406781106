const ROUNDNESS = {
  NONE: {
    xs: '0px',
    s: '0px',
    m: '0px',
    l: '0px',
    xl: '0px',
    full: '0px',
  },
  ROUNDED: {
    xs: '4px',
    s: '8px',
    m: '12px',
    l: '16px',
    xl: '32px',
    full: '9999px',
  },
  ROUND: {
    xs: '9999px',
    s: '9999px',
    m: '16px',
    l: '32px',
    xl: '64px',
    full: '9999px',
  },
};

const getThemeBorderRadius = ({ style }) => ROUNDNESS[
  style?.roundness || 'ROUNDED'
];

export default getThemeBorderRadius;
