import intersection from 'lodash/intersection';

const Types = {};

Types.getGalleryImages = (
  gallery = [],
  includeVideo = false,
  variation = {},
  variants = [],
  options = [],
  optionValues = undefined,
  categoryId = undefined,
  count = Infinity,
  any = false,
) => {
  const variantChoicesToVariantIdMap = variants.reduce(
    (agr, variant) => {
      variant?.choices?.forEach((variantChoice) => {
        agr[`${variantChoice?._id}`] = `${variant?._id}`;
      });
      return agr;
    },
    {},
  );
  const optionChoicesToOptionIdMap = options.reduce(
    (agr, option) => {
      option?.choices?.forEach((optionChoice) => {
        agr[`${optionChoice?._id}`] = `${option?._id}`;
      });
      return agr;
    },
    {},
  );
  const hasOptionValues = Object.keys(optionValues || {}).length > 0;
  const variationVariantChoiceIds = Object.values(variation.variants || {})
    .map(id => `${id}`);
  const variationOptionChoiceIds = Object.values(optionValues || {})
    .map(id => `${id}`);
  const galleryItemsLocal = [];
  for (let i = 0; i < gallery.length; i++) {
    const galleryItem = gallery[i];
    const {
      variants: galleryVariantChoiceIdsRaw,
      options: galleryOptionChoiceIdsRaw,
      categories,
    } = galleryItem;
    const galleryVariantChoiceIds = galleryVariantChoiceIdsRaw.map(
      choiceId => `${choiceId}`,
    );
    const galleryOptionChoiceIds = galleryOptionChoiceIdsRaw.map(
      choiceId => `${choiceId}`,
    );
    let variantsDidPass = null;
    let optionsDidPass = !hasOptionValues ? true : null;
    if (
         !variationVariantChoiceIds?.length
      || !galleryVariantChoiceIds?.length
    ) {
      variantsDidPass = true;
    } else {
      const variantIdsNeeded = [];
      const variantIdsFound = [];
      for (let j = 0; j < galleryVariantChoiceIds.length; j++) {
        const galleryVariantChoiceId = galleryVariantChoiceIds[j];
        const galleryVariantId = variantChoicesToVariantIdMap[
          galleryVariantChoiceId
        ];
        if (!variantIdsNeeded.includes(galleryVariantId)) {
          variantIdsNeeded.push(galleryVariantId);
        }
        if (
          !variantIdsFound.includes(galleryVariantId)
          && variationVariantChoiceIds.includes(galleryVariantChoiceId)
        ) {
          variantIdsFound.push(galleryVariantId);
        }
      }
      if (variantIdsNeeded.length === variantIdsFound.length) {
        variantsDidPass = true;
      } else if (any === true) {
        variantsDidPass = true;
        // if (variantIdsFound.length > 0) {
        //   variantsDidPass = true;
        // }
      } else if (Array.isArray(any)) {
        // console.log('a:', galleryVariantChoiceIds);
        // console.log('b:', any);
        // console.log('intersection', intersection(galleryVariantChoiceIds, any));
        if (intersection(galleryVariantChoiceIds, any)?.length) {
          variantsDidPass = true;
        }
      }
    }
    if (variantsDidPass && !optionsDidPass) {
      if (
           !hasOptionValues
        || !galleryOptionChoiceIds?.length
      ) {
        optionsDidPass = true;
      } else {
        const optionIdsNeeded = [];
        const optionIdsFound = [];
        for (let j = 0; j < galleryOptionChoiceIds.length; j++) {
          const galleryOptionChoiceId = galleryOptionChoiceIds[j];
          const galleryOptionId = optionChoicesToOptionIdMap[
            galleryOptionChoiceId
          ];
          if (!optionIdsNeeded.includes(galleryOptionId)) {
            optionIdsNeeded.push(galleryOptionId);
          }
          if (
            !optionIdsFound.includes(galleryOptionId)
            && variationOptionChoiceIds.includes(galleryOptionChoiceId)
          ) {
            optionIdsFound.push(galleryOptionId);
          }
        }
        if (optionIdsNeeded.length === optionIdsFound.length) {
          optionsDidPass = true;
        } else if (any === true) {
          optionsDidPass = true;
          // if (variantIdsFound.length > 0) {
          //   variantsDidPass = true;
          // }
        } else if (Array.isArray(any)) {
          // console.log('a:', galleryVariantChoiceIds);
          // console.log('b:', any);
          // console.log('intersection', intersection(galleryVariantChoiceIds, any));
          if (intersection(galleryOptionChoiceIds, any)?.length) {
            optionsDidPass = true;
          }
        }
      }
    }
    if (variantsDidPass && optionsDidPass) {
      if (
        !categoryId
        || !categories?.length
        || categories.includes(categoryId)
      ) {
        if (galleryItem.image || (galleryItem.video && includeVideo)) {
          galleryItemsLocal.push(galleryItem);
          if (galleryItemsLocal.length >= count) {
            break;
          }
        }
      }
    }
  }
  return galleryItemsLocal;
};

export default Types;
