import color from 'color';

const COLORS = {
  LIGHT: {
    auto: 'hsl(0, 0%, 0%)',
    autoInverted: 'hsl(0, 0%, 100%)',

    title: 'hsl(0, 0%, 10%)',
    titleBold: 'hsl(0, 0%, 5%)',
    label: 'hsl(0, 0%, 10%)',
    labelBold: 'hsl(0, 0%, 5%)',
    text: 'hsl(0, 0%, 20%)',
    textBold: 'hsl(0, 0%, 10%)',
    icon: 'hsl(0, 0%, 10%)',
    shade: 'hsla(0, 0%, 10%, 0.8)',
    cover: 'hsla(0, 0%, 10%, 0.05)',
    shadow: 'hsla(0, 0%, 10%, 0.15)',
    accent: '$brandColor',

    border: 'hsla(0, 0%, 10%, 0.15)',
    borderActive: 'hsla(0, 0%, 10%, 0.5)',

    buttonBackground: '$brandColor',
    buttonForeground: 'hsl(0, 0%, 100%)',
    announcementBackground: '$brandColor',
    announcementForeground: 'hsl(0, 0%, 100%)',
    dropdownBackground: 'hsl(0, 0%, 100%)',
    dropdownForeground: 'hsl(0, 0%, 10%)',
    notificationBackground: 'hsl(0, 0%, 10%)',
    notificationForeground: 'hsl(0, 0%, 100%)',

    backgroundWebsite: '#fff',
    backgroundNavigation: '#fff',
    backgroundCart: '#fff',
    backgroundCard: '#fff',
    backgroundPageOrder: '#fff',
    backgroundConsent: '#fff',
    backgroundHeader: 'transparent',
    backgroundHeaderSticky: '#fff',
    backgroundFooter: 'transparent',
    backgroundSlider: 'transparent',

    loaderStart: '$brandColor',
    loaderEnd: '$brandColor',
  },
  DARK: {
    auto: 'hsl(0, 0%, 100%)',
    autoInverted: 'hsl(0, 0%, 0%)',

    title: 'hsl(0, 0%, 100%)',
    titleBold: 'hsl(0, 0%, 100%)',
    label: 'hsl(0, 0%, 100%)',
    labelBold: 'hsl(0, 0%, 100%)',
    text: 'hsla(0, 0%, 100%, 0.9)',
    textBold: 'hsl(0, 0%, 100%)',
    icon: 'hsl(0, 0%, 100%)',
    dropdown: 'hsl(0, 0%, 10%)',
    shade: 'hsla(0, 0%, 10%, 0.8)',
    cover: 'hsla(0, 0%, 10%, 0.05)',
    shadow: 'hsla(0, 0%, 0%, 0.15)',
    accent: '$brandColor',

    border: 'hsla(0, 0%, 100%, 0.15)',
    borderActive: 'hsla(0, 0%, 100%, 0.5)',

    buttonBackground: '$brandColor',
    buttonForeground: 'hsl(0, 0%, 100%)',
    announcementBackground: '$brandColor',
    announcementForeground: 'hsl(0, 0%, 100%)',
    dropdownBackground: 'hsl(0, 0%, 10%)',
    dropdownForeground: 'hsl(0, 0%, 100%)',
    notificationBackground: 'hsl(0, 0%, 100%)',
    notificationForeground: 'hsl(0, 0%, 10%)',

    backgroundWebsite: '#111',
    backgroundNavigation: '#111',
    backgroundCart: '#111',
    backgroundCard: '#111',
    backgroundPageOrder: '#111',
    backgroundConsent: '#111',
    backgroundHeader: 'transparent',
    backgroundHeaderSticky: '#111',
    backgroundFooter: 'transparent',
    backgroundSlider: 'transparent',

    loaderStart: '$brandColor',
    loaderEnd: '$brandColor',
  },
};

const generateShades = (label, value) => ([
    0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50,
    55, 60, 65, 70, 75, 80, 85, 90, 95, 100,
  ].reduce((acc, curr) => ({
    ...acc,
    [`${label}${curr}`]: color(value).fade(1 - curr / 100),
  }), {}));

const getContrastColor = (value, colors) => {
  let colorValue = value;
  if (value?.startsWith('$')) {
    colorValue = colors[value?.replace('$', '')];
  }

  return color(colorValue).luminosity() > 0.5 ? '$dark' : '$light'
};

function processColorOverrides(data, colors) {
  return data?.reduce((acc, curr) => {
    const { context } = curr;
    const { property } = curr;
    const { color: value } = curr;

    if (!acc[context]) { acc[context] = {}; }

    const camelCasedProperty = (property?.startsWith('background'))
      ? `${property}${context[0]?.toUpperCase() + context?.slice(1)}`
      : property;

    acc[context][camelCasedProperty] = value;

    if (property.includes('Background')) {
      const foregroundProperty = property.replace('Background', 'Foreground');

      let colorValue = value;

      if (value.startsWith('$')) {
        colorValue = colors[value.replace('$', '')];
      }
      acc[context][foregroundProperty] = getContrastColor(colorValue);
    }

    return acc;
  }, {});
}

const getThemeColors = ({ style, brand, mode }) => {
  const brandColor = brand?.brandColor || '#fe1760';
  const accentColorPrimary = brand?.accentColorPrimary || brandColor;
  const accentColorSecondary = brand?.accentColorSecondary || brandColor;

  const buttonColor = COLORS[mode]?.buttonBackground;
  const notificationColor = COLORS[mode]?.notificationBackground;
  const announcementColor = COLORS[mode]?.announcementBackground;
  const dropdownColor = COLORS[mode]?.dropdownBackground;

  const modeColorOverrides = {
    LIGHT: style?.colors?.light,
    DARK: style?.colors?.dark,
  }[mode];

  const BRAND_COLORS = {
    brandColor,
    ...generateShades('brandColor', brandColor),
    accentColorPrimary,
    ...generateShades('accentColorPrimary', accentColorPrimary),
    accentColorSecondary,
    ...generateShades('accentColorSecondary', accentColorSecondary),
  };

  return {
    colors: {
      ...COLORS[mode],
      ...BRAND_COLORS,
      dark: 'hsl(0, 0%, 10%)',
      light: 'hsl(0, 0%, 100%)',
      ...generateShades('text', COLORS[mode]?.text),
      ...generateShades('title', COLORS[mode]?.title),
      ...generateShades('label', COLORS[mode]?.label),
      buttonForeground: getContrastColor(buttonColor, BRAND_COLORS),
      notificationForeground: getContrastColor(notificationColor, BRAND_COLORS),
      announcementForeground: getContrastColor(announcementColor, BRAND_COLORS),
      dropdownForeground: getContrastColor(dropdownColor, BRAND_COLORS),
      ...processColorOverrides(style?.colors?.defaults, BRAND_COLORS)?.website,
      ...processColorOverrides(modeColorOverrides, BRAND_COLORS)?.website,
    },
    colorOverrides: {
      ...processColorOverrides(style?.colors?.defaults, BRAND_COLORS),
      ...processColorOverrides(modeColorOverrides, BRAND_COLORS),
    },
  };
};

export default getThemeColors;
