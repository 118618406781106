function parseFloatStrict(str) {
  if (!Number.isNaN(str) && !str.includes('%') && !str.includes(',')) {
    return parseFloat(str);
  }
  return str;
}

const ASPECT_RATIO = {
  SQUARE: '1',
  PORTRAIT: '0.7',
  LANDSCAPE: '1.3',
};

const OBJECT_FIT = {
  COVER: 'cover',
  CONTAIN: 'contain',
};

const PARALLAX = {
  NONE: 0,
  MEDIUM: 1,
  HIGH: 2,
};

const BUTTON_SIZE = {
  SMALL: 'calc($space$s / 1.5) calc($space$m / 1.5)',
  MEDIUM: '$space$s $space$m',
  LARGE: 'calc($space$s * 1.5) calc($space$m * 1.5)',
};

function flattenObject(obj, prefix = '') {
  const flattened = {};

  Object.keys(obj).forEach(key => {
    const propName = prefix ? `${prefix}-${key}` : key;

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      const nested = flattenObject(obj[key], propName);
      Object.assign(flattened, nested);
    } else {
      flattened[propName] = obj[key];
    }
  });

  return flattened;
}

const getThemeShop = ({ shop }) => {
  const obj = {
    // @HACK:
    xs: '$space$xs',
    s: '$space$s',
    m: '$space$m',
    l: '$space$l',
    xl: '$space$xl',

    cartAlignment: shop?.cartAlignment === 'SIDE' ? 'flex-end' : 'center',
    card: {
      minWidth: shop?.card?.minWidth || '240px',
      minWidthMobile: shop?.card?.minWidthMobile || '160px',
      minWidthTablet: shop?.card?.minWidthTablet || '200px',
      minWidthDesktop: shop?.card?.minWidthDesktop || '240px',
      featuredBehaviour: shop?.card?.featuredBehaviour || 'POSITION',
      gap: shop?.card?.gap || '$xs',
      objectFit: OBJECT_FIT[shop?.card?.imageSizing] || OBJECT_FIT.COVER,
      aspectRatio: ASPECT_RATIO[shop?.card?.imageAspectRatio]
        || ASPECT_RATIO.SQUARE,
      parallax: PARALLAX[shop?.card?.imageParallax] || PARALLAX.NONE,
      interaction: shop?.card?.imageInteraction || 'STATIC',
      swapping: {
        with: shop?.card?.imageInteractionSwapping?.with || 'VARIATIONS',
        ...['from', 'active', 'to'].reduce((acc, curr) => ({
          ...acc,
          [curr]: {
            ...acc[curr],
            ...shop?.card?.imageInteractionSwapping?.[curr]?.reduce((a, c) => ({
              ...a,
              [c.key]: parseFloatStrict(c.value),
            }), {}),
          },
        }), {}),
      },
      sliding: {
        with: shop?.card?.imageInteractionSliding?.with || 'VARIATIONS',
        loop: shop?.card?.imageInteractionSliding?.loop,
      },
    },
    gallery: {
      thumbnails: shop?.gallery?.thumbnails,
      dotNavigation: shop?.gallery?.dotNavigation,
      arrowsNavigation: shop?.gallery?.arrowsNavigation,
      overflow: shop?.gallery?.overflow.toLowerCase() || 'faded',
      zoom: shop?.gallery?.zoom,
      fullscreen: shop?.gallery?.fullscreen,
    },
  };

  return ({
    ...obj,
    ...flattenObject(obj),
  })
}

const getStoreSettings = ({ store, style, language }) => {
  const { shop, navigation } = style;

  return ({
    custom: {
      inlineStart: language?.direction === 'LTR' ? 'left' : 'right',
      inlineEnd: language?.direction === 'LTR' ? 'right' : 'left',
      inlineTranslateStart: language?.direction === 'LTR'
        ? 'translateX(-100%)' : 'translateX(100%)',
      inlineTranslateEnd: language?.direction === 'LTR'
        ? 'translateX(100%)' : 'translateX(-100%)',
      inlineTranslateCenter: language?.direction === 'LTR'
        ? 'translateX(-50%)' : 'translateX(50%)',
    },
    general: {
      iconsStyle: style?.iconsStyle || 'LINE',
      buttonStyle: style?.buttonStyle || 'FADE',
      buttonSize: BUTTON_SIZE[style?.buttonSize || 'MEDIUM'],
    },
    icons: {
      style: style?.iconsStyle || 'LINE',
      cart: style?.icons?.cart || 'shopping-bag-2',
      rating: style?.icons?.rating || 'star',
    },
    navigation: {
      position: navigation?.position || 'STATIC',
      activeItemStyle: navigation?.activeItemStyle || 'DOT',
      mobileShow: navigation?.mobileNavigationShow || 'AUTO',
      mobileStyle: navigation?.mobileNavigationStyle || 'SIDE',
      // STORE NAVIGATION OPTIONS
      currency: store?.navigationCurrenciesShow,
      language: !store?.navigationLanguageSwitcherHide,
      social: !store?.navigationSocialHide,
    },
    shop: getThemeShop({ shop }),
    language: {
      direction: language?.direction.toLowerCase() || 'ltr',
      language: language?.key || language?._id || 'en',
    },
    legal: {
      strictTermsAndConditions: store?.legal?.strictTermsAndConditions,
    },
  })
};

export default getStoreSettings;
