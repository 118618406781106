import React, { useMemo } from 'react';
import { createNamedStyled } from '../../stitches.config';

// import { useContentWithReplacedVars } from '../../context/GlobalVars';

import Base from './Base';

const styled = createNamedStyled('TextBlock');

const Node = styled(Base, {
  // ...['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].reduce((acc, item) => ({
  //   ...acc,
  //   [item]: {
  //     fontFamily: '$title',
  //     fontWeight: '$title',
  //     color: '$title',
  //     letterSpacing: '$title',
  //     lineHeight: '$title',

  //     strong: { fontWeight: '$titleBold', color: '$titleBold' },
  //   },
  // }), {}),

  h1: {
    fontSize: '$h1',
    fontFamily: '$title',
    fontWeight: '$title',
    color: '$title',
    letterSpacing: '$title',
    lineHeight: '$title',
    margin: '$xs 0',

    strong: { fontWeight: '$titleBold', color: '$titleBold' },
  },

  h2: {
    fontSize: '$h2',
    fontFamily: '$title',
    fontWeight: '$title',
    color: '$title',
    letterSpacing: '$title',
    lineHeight: '$title',
    margin: 'calc($xs / 1.5) 0',

    strong: { fontWeight: '$titleBold', color: '$titleBold' },
  },

  h3: {
    fontSize: '$h3',
    fontFamily: '$title',
    fontWeight: '$title',
    color: '$title',
    letterSpacing: '$title',
    lineHeight: '$title',
    margin: 'calc($xs / 2) 0',

    strong: { fontWeight: '$titleBold', color: '$titleBold' },
  },

  h4: {
    fontSize: '$h4',
    fontFamily: '$title',
    fontWeight: '$title',
    color: '$title',
    letterSpacing: '$title',
    lineHeight: '$title',
    margin: 'calc($xs / 2.5) 0',

    strong: { fontWeight: '$titleBold', color: '$titleBold' },
  },

  h5: {
    fontSize: '$h5',
    fontFamily: '$title',
    fontWeight: '$title',
    color: '$title',
    letterSpacing: '$title',
    lineHeight: '$title',
    margin: 'calc($xs / 3) 0',

    strong: { fontWeight: '$titleBold', color: '$titleBold' },
  },

  h6: {
    fontSize: '$h6',
    fontFamily: '$title',
    fontWeight: '$title',
    color: '$title',
    letterSpacing: '$title',
    lineHeight: '$title',
    margin: 'calc($xs / 3.5) 0',

    strong: { fontWeight: '$titleBold', color: '$titleBold' },
  },

  p: {
    fontFamily: '$text',
    fontWeight: '$text',
    fontSize: '$text',
    color: '$text',
    letterSpacing: '$text',
    lineHeight: '$text',
    strong: { fontWeight: '$textBold', color: '$textBold' },

    '&:not(:last-child)': { marginBottom: '$xs' },
  },

  em: { fontStyle: 'italic' },
  s: { textDecoration: 'line-through' },

  ul: {
    listStyleType: 'disc',
    marginLeft: '$m',
    marginBottom: '$xs',
    color: '$text',
    fontFamily: '$text',
  },

  ol: {
    listStyleType: 'decimal',
    marginLeft: '$m',
    marginBottom: '$xs',
    color: '$text',
    fontFamily: '$text',
  },

  hr: {
    margin: '$m 0',
    opacity: 0.25,
  },
});

const TextBlock = ({ node, css, renderNode, ...props }) => {
  const finalCss = useMemo(
    () => ({
      ...css,
    }),
    [css],
  );

  // const value = useContentWithReplacedVars(node.text_block_value || '', {
  //   html: true,
  // });

  const value = node.text_block_value;

  if (!value) return null;

  return (
    <Node
      {...props}
      node={node}
      css={finalCss}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  );
};

export default TextBlock;
