import React, { useEffect, useState, useRef, memo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Spline from '@splinetool/react-spline';

function SplineViewer({ value, optimize }) {
  const [isInView, setIsInView] = useState(false);
  const splineRef = useRef(null);

  const timeoutRef = useRef(null);
  const incrementRef = useRef(0);

  useEffect(() => {
    if (!optimize) {
      setIsInView(true);
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        clearTimeout(timeoutRef.current);

        if (entries[0].isIntersecting) {
          timeoutRef.current = setTimeout(() => {
            setIsInView(true);
          }, 100);

          incrementRef.current++;
        } else {
          setIsInView(false);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.6,
      }
    );

    let splineRefSaved;

    if (splineRef.current) {
      splineRefSaved = splineRef.current;
      observer.observe(splineRefSaved);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (splineRefSaved) {
        observer.unobserve(splineRefSaved);
      }
    };
  }, [optimize]);

  return (
    <div
      ref={splineRef}
      style={{ position: 'relative', width: '100%', height: '100%' }}
    >
      <AnimatePresence>
        {isInView && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'linear', duration: 0.2 }}
            style={{ width: '100%', height: '100%' }}
          >
            <Spline scene={value} key={value} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

function Embed({ node }) {
  return (
    <>
      {node.embed_type === 'SPLINE'
        && (
        <SplineViewer
          value={node.embed_value}
          optimize={node.className === 'spline-optimize'}
        />
      )}
    </>
  );
}

export default memo(Embed);
