import React, { useMemo } from 'react';
import { createNamedStyled } from '../../stitches.config';

import {
  useGlobalVars,
  getObjectWithReplacedVars,
} from '../../context/GlobalVars';

import { getEffects, getHoverEffects } from '../functions/getEffects';

import Base from './Base';

// import { useContentWithReplacedVars } from '../../context/GlobalVars';

const styled = createNamedStyled('Text');

const getPixelSafeValue = value => (
  // eslint-disable-next-line no-restricted-globals
  !isNaN(value) ? `${value}px` : value
);

const Node = styled(Base, {
  // transition: 'all 300ms ease-out',

  em: { fontStyle: 'italic' },
  s: { textDecoration: 'line-through' },

  variants: {
    type: {
      title: {
        fontFamily: '$title',
        fontWeight: '$title',
        color: '$title',
        letterSpacing: '$title',
        lineHeight: '$title',
        textTransform: '$textTransforms$title',

        strong: { fontWeight: '$titleBold' },
      },
      label: {
        fontFamily: '$label',
        fontWeight: '$label',
        color: '$label',
        letterSpacing: '$label',
        lineHeight: '$label',
        textTransform: '$textTransforms$label',

        // TODO: Remove this when we have a better solution for
        whiteSpace: 'nowrap',

        strong: { fontWeight: '$labelBold' },
      },
    },
  },
});

const SIZES = {
  EXTRA_SMALL: '$xs',
  SMALL: '$s',
  MEDIUM: '$m',
  LARGE: '$l',
  EXTRA_LARGE: '$xl',
};

const ALIGN = {
  LEFT: 'start',
  CENTER: 'center',
  RIGHT: 'end',
};

const TAGS = {
  EXTRA_LARGE: 'h1',
  LARGE: 'h2',
  MEDIUM: 'h3',
  SMALL: 'h4',
  EXTRA_SMALL: 'h5',
};

const Text = ({ node, css, renderNode, ...props }) => {
  const globalVars = useGlobalVars();

  {
    const [
      textEffects,
      textEffectsHover,
    ] = useMemo(
      () => [
        (node?.text_effects || []).map(
          effect => getObjectWithReplacedVars(effect, globalVars, {

          })
        ),
        (node?.text_effectsHover || []).map(
          effect => getObjectWithReplacedVars(effect, globalVars, {

          }),
        ),
      ],
      [node?.text_effects, node?.text_effectsHover, globalVars],
    );

    node = useMemo(
      () => ({
        ...node,
        text_effects: textEffects,
        text_effectsHover: textEffectsHover,
      }),
      [node, textEffects, textEffectsHover],
    );
  }

  const finalCss = useMemo(
    () => ({
      fontSize: node.text_type === 'LABEL'
        ? '$label'
        : node.text_size === 'CUSTOM'
          ? getPixelSafeValue(node.text_sizeCustom)
          : SIZES[node.text_size || 'MEDIUM'],
      textAlign: ALIGN[node.text_align],
      ...getEffects(node.text_effects),
      ...getHoverEffects(node.text_effectsHover, node.hoverTarget),
      ...css,
    }),
    [css, node],
  );

  const value = node.text_value;

  if (!value) return null;

  return (
    <Node
      {...props}
      node={node}
      tag={node.text_type === 'LABEL' ? 'span' : TAGS[node.text_size] || 'p'}
      type={node.text_type?.toLowerCase() || 'title'}
      css={finalCss}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  );
};

export default Text;
