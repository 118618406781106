import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { Redirect } from 'react-router-dom';
import {
  stringify as stringifyQuery,
} from 'querystring';

import storage from '../storage';

import ContextProviderComponent from './ContextProviderComponent';
import { useLocation } from './Location';
import { Provider as GlobalVarsProvider } from './GlobalVars';
import { StoreApiProvider, useApi, useApiRequest } from './Api';
import { useViewer } from './Viewer';

// import NotFound from '../components/Sections/NotFound';
import RedirectToHome from '../helpers/RedirectToHome';

import Types from '../modules/types';

const {
  STORE_OID_TOKEN_KEY: OID_TOKEN_KEY,
  STORE_OID_TOKEN_SEARCH_PARAM: OID_TOKEN_SEARCH_PARAM,
} = Types;

const Context = createContext();

export const BlocksContext = createContext();

export function BlocksProvider({ children }) {
  const { data: store } = useStore() || {};
  const segmentsMap = Types.CONTENT_BUILDER_BLOCK_KIND_ITEMS.reduce(
    (agr, item) => {
      const [{
        segment,
        loading,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      }] = useStoreSegment(
        {
          _id: store?.style?.blocks?.[item.id],
          store: { IN: [store._id, null] },
        },
        !store?.style?.blocks?.[item.id],
      );
      agr[item.id] = { segment, loading };
      return agr;
    },
    {},
  );

  return (
    <BlocksContext.Provider value={segmentsMap}>
      {children}
    </BlocksContext.Provider>
  );
}

export const { Consumer: BlocksConsumer } = BlocksContext;

export function useStoreBlocks() {
  return useContext(BlocksContext);
}

export function useStoreBlockRender(blockKey, varsName, vars, extraProps = {}) {
  const {
    [blockKey]: {
      loading: blockLoading = true,
      segment: block,
    } = {},
  } = useStoreBlocks();

  if (blockLoading) {
    return true;
  }

  if (block) {
    return (
      <GlobalVarsProvider
        name={varsName}
        vars={vars}
      >
        {window.mozheContentBuilderRenderNode(block, extraProps)}
      </GlobalVarsProvider>
    );
  }

  return null;
}

export default Context;

export function Provider(props) {
  const api = useApi();
  const viewer = useViewer();
  const location = useLocation();
  return (
    <StoreProvider
      api={api}
      location={location}
      viewer={viewer}
      {...props}
    />
  );
}

export const { Consumer } = Context;

function StoreGlobalVarsProvider({ children }) {
  const store = useStore();
  const vars = useMemo(
    () => {
      if (!store?.data) {
        return null;
      }
      return {
        name: store.data.name,
        legal: {
          name: store.data.legal?.name,
          email: store.data.legal?.email,
          phoneNumberFormatted: store.data.legal?.phoneNumberString,
          address: store.data.legal?.address,
          registrationNumber: store.data.legal?.registrationNumber,
          taxIdentificationNumber: store.data.legal?.taxIdentificationNumber,
          bankAccountNumber: store.data.legal?.banking?.accountNumber,
          bankAccountHolder: store.data.legal?.banking?.accountHolderName,
        },
      };
    },
    [store?.data],
  );
  return (
    <GlobalVarsProvider
      name="store"
      vars={vars}
    >
      {children}
    </GlobalVarsProvider>
  )
}

export class StoreProvider extends ContextProviderComponent {
  static NAME = 'STORE'

  static defaultProps = {
    ...ContextProviderComponent.defaultProps,
    ProviderComponent: Context.Provider,
  }

  // static setData(key, data) {
  //   try {
  //     return storage.setItem(key, JSON.stringify(data));
  //   } catch (error) {
  //     // eslint-disable-next-line no-console
  //     console.log(error.message);
  //     return null;
  //   }
  // }

  // static getData(key) {
  //   try {
  //     return JSON.parse(storage.getItem(key));
  //   } catch (error) {
  //     return null;
  //   }
  // }

  constructor(props) {
    super(props);
    global.STORE = this;
    this.state.loading = true;
    this.state.data = null;
    this.exposeMethods(
      'setData',
      'getData',
      'setLanguage',
      'setCurrency',
      'getCurrencyConverted',
      'removeData',
      'getUrl',
      'getProductsUrl',
    );
    Object.assign(this.state, {
      mode: this.props.mode,
      isCustomDomain: this.props.isCustomDomain,
    });
  }

  componentDidMount() {
    this.getStore(this.props);
  }

  componentDidUpdate(props) {
    const refresh = (
         this.props.id !== props.id
      || this.props.slug !== props.slug
      || this.props.language !== props.language
      || this.props.isCustomDomain !== props.isCustomDomain
    );
    if (
         refresh
      || this.props.referral !== props.referral
      || this.props.viewer !== props.viewer
      || this.props.mode !== props.mode
    ) {
      this.getStore(this.props, refresh);
    }
  }

  async getStore(
    {
      id,
      slug,
      language,
      mode,
      referral: referralStoreSlug,
      isCustomDomain,
      location,
      useCustomDomain = true,
      viewer,
    },
    refresh = false
  ) {
    if (refresh) {
      window.location.reload();
      return;
    }
    this.setState({ loading: true, redirect: null });
    let redirect = null;
    let referral = null;
    let aborter = new AbortController();
    try {
      const data = await this.props.api.get(
        `stores/${id || slug}`,
        {
          includeCurrencies: 'true',
          i18nTranslate: language || 'true',
        },
        this.aborter,
        this.aborter = aborter,
      );
      if (!Types.getIsCustomDomain(window.location.hostname) && data) {
        data.domainsInUse = [];
        data.domains = [];
      }
      if (
        data
        && (
          mode === 'order'
          || (viewer?.data || data.internal !== true)
        )
      ) {
        const savedLanguage = viewer.getData(`${data._id}.LANGUAGE`);
        let validLanguage = (
            !language
          ? data.language
          : !data.languages.includes(language)
          ? data.language
          : language
        );
        if (
          !language
          && savedLanguage
          && data.languages.includes(savedLanguage)
        ) {
          validLanguage = savedLanguage;
        }
        if (language === validLanguage) {
          viewer.setData(`${data._id}.LANGUAGE`, language);
        }
        if (!useCustomDomain && isCustomDomain) {
          window.location.replace(`${
            process.env.REACT_APP_MOZHE_SHOP_URL
          }${
            mode === 'store'
            ? `/${slug}${location.pathname}`
            : location.pathname
          }${
            location.search
          }`);
          return;
        }
        if (
          useCustomDomain
          && !isCustomDomain
          && data.domainsInUse
          && data.domainsInUse[0]
        ) {
          let path = (
            mode === 'store'
            ? `/${location.pathname.split('/').slice(
                language ? 3 : 2
              ).join('/')}`
            : location.pathname
          );
          if (mode === 'store' && validLanguage !== language) {
            path = `/${validLanguage}${path}`;
          }
          window.location.replace(`https://${data.domainsInUse[0]}${
            path
          }${
            location.search
          }`);
          return;
        }
        if (mode === 'store' && validLanguage !== language) {
          if (isCustomDomain) {
            const newLocation = `/${
              validLanguage
            }${
              language
              ? location.pathname.split('/').slice(2).join('/')
              : location.pathname
            }${
              location.search
            }`;
            window.location.replace(newLocation);
          } else {
            const newLocation = `/${
              slug
            }/${
              validLanguage
            }/${
              location.pathname.split('/').slice(language ? 3 : 2).join('/')
            }${
              location.search
            }`;
            window.location.replace(newLocation);
          }
          return;
        }
        // OPENID CONNECT START
        if (
          typeof location.searchParams[OID_TOKEN_SEARCH_PARAM] !== 'undefined'
        ) {
          this.props.viewer.setData(
            OID_TOKEN_KEY,
            location.searchParams[OID_TOKEN_SEARCH_PARAM],
          );
          const newSearchParams = { ...location.searchParams };
          delete newSearchParams[OID_TOKEN_SEARCH_PARAM];
          const newQueryString = stringifyQuery(newSearchParams);
          window.location.href = `${
            window.location.origin
          }${location.pathname}${
              newQueryString.length
            ? `?${newQueryString}`
            : ''
          }`;
        }
        let customer = null;
        if (
          mode === 'store'
          && data?.integrations?.openId?.supported
          && data?.integrations?.openId?.backend
        ) {
          aborter = new AbortController();
          const response = await this.props.api.get(
            'openid/store/authenticate',
            {
              url: window.location.href,
              store: data._id,
              token: this.props.viewer.getData(OID_TOKEN_KEY),
            },
            this.aborter,
            this.aborder = aborter,
          );
          if (response.redirect) {
            window.location = response.redirect;
            return;
          }
          if (response.customer) {
            customer = response.customer;
          }
        }
        // OPENID CONNECT END
        aborter = new AbortController();
        const languageData = await this.props.api.get(
          `languages/stores/${data._id}/${language || data.language}`,
          {},
          this.aborter,
          this.aborter = aborter,
        );
        aborter = new AbortController();
        const productsCount = await this.props.api.get(
          'products',
          {
            where: {
              store: data._id,
              active: { NE: false },
            },
            limit: 0,
          },
        );
        const categories = await this.props.api.get(
          'categories',
          {
            where: { store: data._id, products: { GT: 0 } },
            sort: { sortWeight: -1, createdAt: 1 },
            limit: 1000,
            i18nTranslate: language || 'true',
          },
          this.aborter,
          this.aborter = aborter,
        );
        const collections = await this.props.api.get(
          'collections',
          {
            where: { store: data._id, products: { GT: 0 } },
            sort: { sortWeight: -1, createdAt: 1 },
            limit: 1000,
            i18nTranslate: language || 'true',
          },
        )
        if (referralStoreSlug) {
          aborter = new AbortController();
          if (referralStoreSlug) {
            try {
              aborter = new AbortController();
              referral = await this.props.api.get(
                `stores/${id || slug}/referral/${referralStoreSlug}`,
                {},
                this.aborter,
                this.aborter = aborter,
              );
              if (!referral) {
                throw new Error(`Referral "${referralStoreSlug}" not found`);
              }
              // this.props.viewer.setData(
              //   `${data._id}.referral`,
              //   { data: referral },
              // );
            } catch (error) {
              referral = null;
              // this.props.viewer.setData(`${data._id}.referral`, null);
              this.debug(`error getting referral ${referralStoreSlug}`, error);
              redirect = `${location.pathname}`;
            }
          } else {
            // const referralSaved = this.props.viewer.getData(
            //   `${data._id}.referral`,
            // );
            const referralSaved = null;
            if (referralSaved) { // TODO And not expired
              try {
                aborter = new AbortController();
                const referralStore = await this.props.api.get(
                  `stores/${referralSaved.data.referralStore}`,
                  {},
                  this.aborter,
                  this.aborter = aborter,
                );
                if (!referralStore) {
                  throw new Error('Referral store not found');
                }
                redirect = `${location.pathname}?referral=${
                  referralStore.slug
                }`;
              } catch (error) {
                this.debug('error getting saved referral store:', error);
                this.props.viewer.setData(`${data._id}.referral`, null);
                referral = null;
              }
            } else {
              referral = null;
              this.props.viewer.setData(`${data._id}.referral`, null);
            }
          }
        }
        const storeCurrenciesMap = (data.storeCurrencies || []).reduce(
          (agr, currency) => {
            agr[currency.code] = currency;
            return agr;
          },
          {},
        );
        let storeCurrency = this.props.viewer.getData(`${
          data._id
        }.storeCurrency`);
        if (!storeCurrency || !storeCurrenciesMap[storeCurrency]) {
          storeCurrency = data.storeCurrencies[0].code;
        }
        this.setState({
          loading: false,
          mode,
          currency: storeCurrency,
          language: languageData,
          data: {
            ...data,
            storeCurrenciesMap,
            productsCount: productsCount?.count || 0,
            categories,
            collections,
            customer,
            referral: (
              referral
              ? {
                  ...referral,
                  referralStoreSlug,
                }
              : null
            ),
          },
          isCustomDomain,
          redirect,
        });
      } else if (data && data.internal) {
        this.setState({
          loading: false,
          mode,
          redirect: `${
            process.env.REACT_APP_MOZHE_SHOP_ADMIN_URL
          }/admin/store/orders?mozhe-marketplace=${
            data._id
          }`,
        });
      } else {
        throw new Error(`Store "${id || slug}" not found`);
      }
    } catch (error) {
      this.debug('error fetching store:', error);
      this.setState({
        loading: false,
        data: null,
        redirect,
        mode,
      });
    }
  }

  setData = (key, data) => {
    try {
      return this.props.viewer.setData(
        `${this.state.data._id}.${key}`,
        data,
      );
    } catch (error) {
      return null;
    }
  }

  getData = (key) => {
    try {
      return this.props.viewer.getData(
        `${this.state.data._id}.${key}`,
      );
    } catch (error) {
      return null;
    }
  }

  removeData = (key) => {
    try {
      return storage.removeItem(
        `${this.state.data._id}.${key}`,
      );
    } catch (error) {
      return null;
    }
  }

  getUrl = (to, mode = 'store') => {
    const languagePath = (
      this.props.language
      ? `/${this.props.language}`
      : ''
    );
    return (
        this.props.isCustomDomain
      ? `${languagePath}${to || '/'}`
      : `${
          mode === 'order'
          ? `${to || '/'}`
          : `/${this.state.data.slug}${languagePath}${to || ''}`
        }`
    );
  }

  getProductsUrl = (productSlug, variationSlug, search) => this.getUrl(`/${
    Types.SHOP_PRODUCTS_ARCHIVE_PATH
  }${
    productSlug ? `/${productSlug}` : ''
  }${
    variationSlug ? `/${variationSlug}` : ''
  }${
    search || ''
  }`)

  setCurrency = (currency) => {
    if (
      this.state.data.storeCurrenciesMap
      && this.state.data.storeCurrenciesMap[currency]
    ) {
      this.setState({ currency });
      this.setData('storeCurrency', currency);
    }
  }

  setLanguage = (language) => {
    const {
      language: { _id: currentLanguage },
      data: { slug },
    } = this.state;
    const { isCustomDomain } = this.props;
    if (currentLanguage !== language) {
      if (isCustomDomain) {
        window.location.replace(`/${
          language
        }/${
          window.location.pathname.split('/').slice(2).join('/')
        }${
          window.location.search
        }`);
      } else {
        window.location.replace(`/${
          slug
        }/${
          language
        }/${
          window.location.pathname.split('/').slice(3).join('/')
        }${
          window.location.search
        }`);
      }
    }
  }

  getCurrencyConverted = (value = 1) => (
    this.state.data.storeCurrenciesMap[this.storeCurrency] * value
  )

  render() {
    const { loading, data, redirect } = this.state;
    if (!loading && redirect) {
      if (redirect.indexOf('http') === 0) {
        window.location.href = redirect;
        return null;
      }
      return <Redirect to={redirect} />;
    }
    if (!loading && !data) {
      // return <NotFound message="Store not found" />;
      return <RedirectToHome isCustomDomain={this.props.isCustomDomain} />;
    }
    if (!loading) {
      return (
        <StoreApiProvider
          baseUrl={this.props.api.baseUrl}
          iframe={this.props.api.iframe}
          defaultHeaders={{
            'x-mozhe-i18n-translate': this.props.language,
          }}
        >
          {
            super.render(
              null,
              children => (
                <StoreGlobalVarsProvider>
                  <BlocksProvider>
                    {children}
                  </BlocksProvider>
                </StoreGlobalVarsProvider>
              ),
            )
          }
        </StoreApiProvider>
      );
    }
    return null;
  }
}

export function useStore() {
  return useContext(Context);
}

export function useIsStoreInternal() {
  const store = useStore();
  return store.data?.internal === true;
}

export function useStoreCurrency() {
  const { data: store, currency, setCurrency } = useStore();
  const currencyObject = useMemo(
    () => (
      store && store.storeCurrenciesMap[currency]
      ? store.storeCurrenciesMap[currency]
      : null
    ),
    [store, currency],
  );
  return [currencyObject, setCurrency];
}

export function useStorePage(where, skip = false, reloadIndex = 0) {
  const { data: store, loading: storeLoading } = useStore();
  const [errorReloadIndex, setErrorReloadIndex] = useState(0);
  const finalReloadIndex = reloadIndex + errorReloadIndex;
  const extractDataPages = useCallback(
    data => (data?.[0] || null),
    [],
  );
  const [{
    data: pageData,
    loading: pageDataLoading,
  }] = useApiRequest({
    url: `pages/render?query=${JSON.stringify({
      compileContent: true,
      store: store?._id,
      limit: 1,
      where: {
        AND: [
          {
            OR: [
              { global: false, store: store?._id },
              { global: true },
            ],
          }, {
            ...(where || {}),
            active: { NE: false },
          },
        ],
      },
      reloadIndex: finalReloadIndex,
    })}`,
    skip: storeLoading || !store || skip,
    extractData: extractDataPages,
  });
  const [{
    data: pageContent,
    error: pageContentError,
    loading: pageContentLoading,
  }] = useApiRequest({
    url: `storage/cbcache/${
      pageData?.contentCache || 'PAGE_NOT_FOUND'
    }`,
    skip: storeLoading || !store || !pageData,
  });
  const pageLoading = pageDataLoading || pageContentLoading;
  const page = useMemo(
    () => (
      !pageLoading && pageData && pageContent
      ? {
          ...pageData,
          content: pageContent,
        }
      : null
    ),
    [pageLoading, pageData, pageContent],
  );
  useEffect(
    () => {
      // let timeout;
      if (pageContentError) {
        setErrorReloadIndex(errorReloadIndex + 1);
      }
      // return () => clearTimeout(timeout);
    },
    [pageContentError, errorReloadIndex],
  );
  const result = useMemo(
    () => [{
      page,
      loading: !!(
        pageLoading
        || (!!pageData && !pageContent)
      ),
    }],
    [page, pageLoading, pageData, pageContent],
  );
  return result;
}

export function useStoreSegment(where, skip = false, reloadIndex = 0) {
  const { data: store, loading: storeLoading } = useStore();
  const [errorReloadIndex, setErrorReloadIndex] = useState(0);
  const finalReloadIndex = reloadIndex + errorReloadIndex;
  const extractDataSegments = useCallback(
    data => (data?.[0] || null),
    [],
  );
  const [{
    data: segmentData,
    loading: segmentDataLoading,
  }] = useApiRequest({
    url: `contentbuilder/segments/render?query=${JSON.stringify({
      compileContent: true,
      store: store?._id,
      limit: 1,
      where: {
        store: store?._id,
        ...(where || {}),
      },
      reloadIndex: finalReloadIndex,
    })}`,
    skip: storeLoading || !store || skip,
    extractData: extractDataSegments,
  });
  const [{
    data: segmentContent,
    error: segmentContentError,
  }] = useApiRequest({
    url: `storage/cbcache/${
      segmentData?.contentCache || 'PAGE_NOT_FOUND'
    }`,
    skip: storeLoading || !store || !segmentData,
  });
  const segment = useMemo(
    () => (
      segmentData && segmentContent
      ? {
          ...segmentData,
          content: segmentContent,
        }
      : null
    ),
    [segmentData, segmentContent],
  );
  useEffect(
    () => {
      // let timeout;
      if (segmentContentError) {
        setErrorReloadIndex(errorReloadIndex + 1);
      }
      // return () => clearTimeout(timeout);
    },
    [segmentContentError, errorReloadIndex],
  );
  const result = useMemo(
    () => [{
      segment,
      loading: !!(
        segmentDataLoading
        || (!!segmentData && !segmentContent)
      ),
    }],
    [segment, segmentDataLoading, segmentData, segmentContent],
  );
  return result;
}
