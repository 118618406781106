import { createNamedStyled, keyframes } from '../../stitches.config';

const styled = createNamedStyled('Spinner');

const rotate = keyframes({
  '100%': {
    transform: 'rotate(360deg)',
  },
});

const clip = keyframes({
  '0%': {
    clipPath: 'polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)',
  },
  '25%': {
    clipPath: 'polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)',
  },
  '50%': {
    clipPath: 'polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)',
  },
  '75%': {
    clipPath: 'polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)',
  },
  '100%': {
    clipPath: 'polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)',
  },
});

const Spinner = styled.named('Element')('div', {
  flex: '0 0 auto',
  width: '48px',
  height: '48px',
  borderRadius: '50%',
  position: 'relative',
  animation: `${rotate} 1s linear infinite`,

  '&::before': {
    content: '""',
    boxSizing: 'border-box',
    position: 'absolute',
    inset: 0,
    borderRadius: '50%',
    border: '5px solid $brandColor',
    animation: `${clip} 2s linear infinite alternate`,
  },
});

export default Spinner;
