import React, { createContext, useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet';

import { useStore } from './Store';

import Types from '../modules/types';

import { Provider as GlobalVarsProvider } from './GlobalVars';

const LanguageContext = createContext();
export default LanguageContext;

export const LanguageProvider = ({ children, data }) => (
  <LanguageContext.Provider value={data}>
    {children}
  </LanguageContext.Provider>
);

export const StoreBoundLanguageProvider = ({ children }) => {
  const store = useStore();
  const language = useMemo(
    () => {
      const { dictionary } = store.language;
      const result = {
        ...store.language,
        dictionary: {
          ...dictionary,
          currency: Types.SYSTEM_COUNTRY_ITEMS.reduce(
            (agr, { currency }) => {
              agr[currency] = dictionary[`currency_${currency}`] || currency;
              return agr;
            },
            {},
          ),
          ...[
            ['_LABELS_MAP', ''],
            ['_DURATIONS_MAP', 'Duration'],
          ].reduce(
            (agr, [keySuffix, languageKeySuffix]) => {
              agr[`SHIPPING_METHOD${keySuffix}`] = [
                ['STORE_RUSH', 'cartShippingStoreRush'],
                ['STORE_STANDARD', 'cartShippingStoreStandard'],
                ['STORE_EXPRESS', 'cartShippingStoreExpress'],
                ['STORE_INTERNATIONAL', 'cartShippingStoreInternational'], // eslint-disable-line max-len
                ['STORE_INTERNATIONAL_EXPRESS', 'cartShippingStoreInternationalExpress'], // eslint-disable-line max-len
                ['MOZHE_SHOP_STANDARD', 'cartShippingStandard'],
                ['MOZHE_SHOP_STANDARD_EXPRESS', 'cartShippingStandardExpress'],
                ['MOZHE_SHOP_INTERNATIONAL', 'cartShippingInternational'],
                ['MOZHE_SHOP_INTERNATIONAL_EXPRESS', 'cartShippingInternationalExpress'], // eslint-disable-line max-len
                ['MOZHE_RUSH', 'cartShippingRush'],
                ['SELF_PICKUP', 'cartShippingSelfPickup'],
                ['POS', 'cartShippingPOS'],
                // BOXIT_STANDARD: 'Boxit Standard',
                // BOXIT_INTERNATIONAL_ECONOMY: 'Boxit International (Economy)',
                // BOXIT_INTERNATIONAL_EXPRESS: 'Boxit International (Express)',
              ].reduce(
                (agr1, [key, languageKey]) => {
                  agr1[key] = dictionary[`${languageKey}${languageKeySuffix}`];
                  return agr1;
                },
                {},
              );
              return agr;
            },
            {},
          ),
          PAYMENT_METHOD_LABELS_MAP: {
            CARD: dictionary.cartPaymentCardOnline,
            TAMARA: dictionary.cartPaymentTamara,
            COD_CASH: dictionary.cartPaymentCashOnDelivery,
            COD_CARD: dictionary.cartPaymentCardOnDelivery,
            FREE: dictionary.cartPaymentFree,
          },
          PAYMENT_SUBMETHOD_LABELS_MAP: {
            CARD: {
              APPLE_PAY: dictionary.cartPaymentApplePay,
            },
            TAMARA: {
              PAY_BY_INSTALMENTS: dictionary.cartPaymentTamaraPayByInstalments,
              PAY_BY_LATER: dictionary.cartPaymentTamaraPayByLater,
            },
          },
        },
      };
      return result;
    },
    [store.language],
  );
  return (
    <LanguageProvider data={language}>
      <Helmet>
        <html lang={language?._id} />
      </Helmet>
      <GlobalVarsProvider
        name="language"
        vars={language}
      >
        {children}
      </GlobalVarsProvider>
    </LanguageProvider>
  );
};

export const useLanguage = () => useContext(LanguageContext);

export const useDictionary = () => {
  const { dictionary } = useLanguage();
  return dictionary;
};
