import React from 'react';
import ReactDOM from 'react-dom';
import { parse as parseQuery } from 'querystring';

import App from './App';

import Types from './modules/types';

// eslint-disable-next-line no-unused-vars
import renderNode from './builder/functions/renderNode';

const {
  REACT_APP_SERVER_HOST,
  REACT_APP_SERVER_PROTOCOL,
  REACT_APP_HTTP_PORT,
  REACT_APP_API_PATH,
  REACT_APP_MOZHE_HOME_URL,
} = process.env;

const SERVER_URL = `${
  REACT_APP_SERVER_PROTOCOL
}://${
  REACT_APP_SERVER_HOST
}${
  REACT_APP_HTTP_PORT === '80' ? '' : `:${REACT_APP_HTTP_PORT}`
}`;

const API_URL = `${SERVER_URL}${REACT_APP_API_PATH}`;

const IS_CUSTOM_DOMAIN = Types.getIsCustomDomain(window.location.hostname);

const searchParams = parseQuery(window.location.search.slice(1));

ReactDOM.render(
  <App
    serverUrl={SERVER_URL}
    apiUrl={API_URL}
    homeUrl={REACT_APP_MOZHE_HOME_URL}
    isCustomDomain={IS_CUSTOM_DOMAIN}
    iframe={`${searchParams.iframe}` === 'true'}
    token={`${searchParams.token}`}
  />,
  window.document.getElementById('root'),
);
