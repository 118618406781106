const TRANSITIONS = {
  SMOOTH: {
    spring: { type: 'spring', stiffness: 500, damping: 80 },
    timingFunction: 'cubic-bezier(0.190, 1.000, 0.220, 1.000)',
    duration: {
      xs: '200ms', s: '400ms', m: '800ms', l: '1200ms', xl: '1600ms',
    },
  },
  SNAPPY: {
    spring: { type: 'spring', stiffness: 500, damping: 50 },
    timingFunction: 'cubic-bezier(0.075, 0.820, 0.165, 1.000)',
    duration: {
      xs: '100ms', s: '200ms', m: '400ms', l: '600ms', xl: '800ms',
    },
  },
  SPRINGY: {
    spring: { type: 'spring', stiffness: 150, damping: 15, mass: 1.5 },
    timingFunction: 'cubic-bezier(0.200, 2.000, 0.200, 0.800)',
    duration: {
      xs: '200ms', s: '400ms', m: '800ms', l: '1000ms', xl: '1200ms',
    },
  },
};

const getThemeTransitions = ({ style }) => ({
  spring: TRANSITIONS[style?.transitionStyle || 'SMOOTH']?.spring,
  ease: TRANSITIONS[style?.transitionStyle || 'SMOOTH']?.timingFunction,
  xs: TRANSITIONS[style?.transitionStyle || 'SMOOTH']?.duration.xs,
  s: TRANSITIONS[style?.transitionStyle || 'SMOOTH']?.duration.s,
  m: TRANSITIONS[style?.transitionStyle || 'SMOOTH']?.duration.m,
  l: TRANSITIONS[style?.transitionStyle || 'SMOOTH']?.duration.l,
  xl: TRANSITIONS[style?.transitionStyle || 'SMOOTH']?.duration.xl,
});

export default getThemeTransitions;
