import { stringify as stringifyQuery } from 'querystring';

import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { createNamedStyled, keyframes } from '../../stitches.config';
import { useTheme } from '../../theme';

import Link from '../Patterns/Link';
import { Title, Paragraph, Label } from './Text';
import PriceWithCurrency, {
  usePriceWithCurrency,
} from '../../helpers/PriceWithCurrency';

import { useStoreBlockRender, useStore } from '../../context/Store';
import { useDictionary } from '../../context/Language';
import { useLocation } from '../../context/Location';

import useGalleryItems from '../../helpers/useGalleryItems';

// import renderNode from '../../builder/functions/renderNode';

const styled = createNamedStyled('Card');

const cardFadeIn = keyframes({
  '100%': { opacity: 1 },
});

const Wrapper = styled.named('Wrapper')('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 auto',
  width: '100%',

  transition: 'translate $m $ease',
  '@media (hover: hover) and (pointer: fine)': {
    '&:hover': { translate: '0 -3%' },
  },

  variants: {
    disabled: {
      true: {
        // pointerEvents: 'none',
        opacity: 0.5,
      },
    },
  },

  opacity: 0,
  animation: `${cardFadeIn} $transitions$m forwards`,
});

const ImageWrapper = styled.named('ImageWrapper')('div', {
  position: 'relative',
  width: '100%',
  flex: '1 0 auto',
  borderRadius: '$l',
  overflow: 'hidden',

  aspectRatio: '$shop$card$aspectRatio',

  minHeight: 240,
  '@supports (aspect-ratio: 1/1)': {
    minHeight: 'initial',
  },

  '&:after': {
    content: '',
    position: 'absolute',
    inset: 0,
    background: '$cover',
    opacity: 0.5,
    pointerEvents: 'none',
  },
});

const Image = styled.named('Image')('img', {
  position: 'absolute',
  width: '100%',
  height: '100%',
  inset: '-1px',
  objectFit: '$shop$card$objectFit',
});

const Content = styled.named('Content')('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '$s $xs',
});

const PriceWrapper = styled.named('PriceWrapper')('div', {
  display: 'flex',
  alignItems: 'baseline',
  gap: 6,

  '@mobile': {
    flexDirection: 'column',
    gap: 0,
  },
});

const PriceBeforeSale = styled.named('PriceBeforeSale')('span', {
  fontSize: '75%',
  textDecoration: 'line-through',
  opacity: 0.5,

  '@mobile': {
    marginTop: -4,
  },
});

const Tags = styled.named('Tags')('div', {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  zIndex: 1,
  top: 10,
  zoom: 0.7,
  gap: 4,
  insetInlineStart: -8,

  '@desktop+': {
    zoom: 0.9,
    top: 10,
  },
});

const Tag = styled.named('Tag')(Label, {
  display: 'flex',
  width: 'fit-content',
  borderRadius: '$s',
  background: '$buttonBackground',
  color: '$buttonForeground',
  padding: 10,

  variants: {
    disabled: {
      true: {
        filter: 'grayscale(100%)',
      },
    },
  },
});

const SlidingWrapper = styled.named('Wrapper')('div', {
  position: 'absolute',
  inset: 0,
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  scrollSnapStop: 'always',
  '&::-webkit-scrollbar': { display: 'none' },
});

const SlidingItem = styled.named('SlidingItem')('div', {
  position: 'relative',
  width: '100%',
  flex: '1 0 auto',
  scrollSnapAlign: 'start',
});

const Header = styled.named('Header')('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: '$xs',
});

const RatingWrapper = styled.named('RatingWrapper')('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '5px',
  alignItems: 'center',
  marginTop: '-10px',
  '*': { whiteSpace: 'nowrap' },
});

const Rating = styled.named('Rating')('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  zoom: 0.7,
});

const RatingIcon = styled.named('RatingIcon')('i', {
  color: '$icon',
  fontSize: '$xs',
});

const ICONS_STYLE_MAP = {
  LINE: 'line',
  SOLID: 'fill',
};

const ICONS_MAP = {
  star: ['star'],
  heart: ['heart'],
  emotion: [
    'emotion-sad',
    'emotion-unhappy',
    'emotion-normal',
    'emotion-happy',
    'emotion-laugh',
  ],
};

function ImageLazy({ src, alt, placeholder }) {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    if (imgRef.current && 'IntersectionObserver' in window) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setLoaded(true);
              observer.unobserve(imgRef.current);
            }
          });
        },
        { threshold: 0.01 }
      );

      observer.observe(imgRef.current);

      return () => {
        observer.disconnect();
      };
    }

    return undefined;
  }, []);

  return (
    <Image
      ref={imgRef}
      src={loaded ? src : placeholder}
      alt={alt}
      style={{ opacity: loaded ? 1 : 0, transition: 'opacity 0.5s' }}
    />
  );
}

const Sliding = ({ images }) => {
  const { shop } = useTheme();
  const wrapperRef = useRef(null);

  const { loop } = shop.card.sliding;
  const loopedImages = [images[images.length - 1], ...images, images[0]];

  useEffect(() => {
    if (!loop) return;
    const wrapper = wrapperRef.current;

    wrapper.scrollTo({ left: wrapper.offsetWidth, behavior: 'instant' });

    const handleScroll = () => {
      if (!wrapper) return;

      if (wrapper.scrollLeft
          >= wrapper.scrollWidth - wrapper.offsetWidth - 1) {
        wrapper.scrollTo({ left: wrapper.offsetWidth, behavior: 'instant' });
      }

      if (wrapper.scrollLeft <= 1) {
        wrapper.scrollTo({
          left: wrapper.scrollWidth - 2 * wrapper.offsetWidth,
          behavior: 'instant',
        });
      }
    };

    wrapper.addEventListener('scroll', handleScroll);

    // eslint-disable-next-line consistent-return
    return () => {
      wrapper.removeEventListener('scroll', handleScroll);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SlidingWrapper ref={wrapperRef}>
      {(loop ? loopedImages : images).map((image, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SlidingItem key={index}>
          <ImageLazy
            src={image?.src}
            alt={`Gallery Item ${index + 1}`}
          />
        </SlidingItem>
      ))}
    </SlidingWrapper>
  );
};

const SwappingLoop = ({ images, shouldAnimate, options }) => {
  const { transitions } = useTheme();

  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    let interval;
    let timeout;
    if (shouldAnimate) {
      if (images.length > 1) {
        timeout = setTimeout(() => {
          setCurrentImage(
            (prevIndex) => (prevIndex + 1) % images.length);

          interval = setInterval(() => {
            setCurrentImage(
              (prevIndex) => (prevIndex + 1) % images.length);
          }, 1500);
        }, 500);
      }
    }

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [shouldAnimate, images.length]);

  return (
    <>
      <AnimatePresence initial={false}>
        <Image
          as={motion.img}
          key={images[currentImage]?.src}
          src={images[currentImage]?.src}
          alt={`Gallery Item ${currentImage + 1}`}
          initial={options.from}
          animate={options.active}
          exit={options.to}
          transition={transitions.spring}
        />
      </AnimatePresence>
      {/* Preload next image */}
      <Image
        src={images[currentImage + 1]}
        alt={`Gallery Item ${currentImage + 2}`}
        css={{ position: 'absolute', visibility: 'hidden' }}
      />
    </>
  );
};

const SwappingSingle = ({ image, options }) => {
  const { transitions } = useTheme();

  return (
    <AnimatePresence initial={false}>
      <Image
        as={motion.img}
        key={image?.src}
        src={image?.src}
        alt="Gallery Item 1"
        initial={options.from}
        animate={options.active}
        exit={options.to}
        transition={transitions.spring}
      />
    </AnimatePresence>
  );
};

const Card = ({
  // index,
  optimize = false,
  product: {
    // _id: productId,
    name,
    // image,
    gallery,
    price,
    priceBeforeSale,
    slug,
    inStock,
    featured,
    category,
    // subcategory,
    categories,
    variants,
    options,

    priceLowest,
    priceHighest,

    variations,
    // ...product

    rating,
    reviewCount,

    tagline,
  },
  ...props
}) => {
  const { tagFeatured, tagSale, tagSoldOut } = useDictionary();
  const { searchParams } = useLocation();

  const [isHovered, setIsHovered] = useState(false);
  const { shop: { card }, icons } = useTheme();

  const { data: store } = useStore();

  const [isOnPage, setIsOnPage] = useState(false);
  const ref = useRef();

  // TODO: Remove this when the API is fixed
  const description = tagline?.replace(/(<([^>]+)>)/gi, '');

  useEffect(() => {
    if (
      optimize
      && ref.current
      && 'IntersectionObserver' in window
    ) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setIsOnPage(true)
          } else {
            setIsOnPage(false);
          }
        },
        { threshold: 0.01 }
      );
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
    return undefined;
  }, [optimize]);

  // const categoryWithSubcategoryLabel = useMemo(
  //   () => {
  //     const populatedCategory = store?.categories?.find(
  //       test => test._id === category,
  //     );
  //     if (populatedCategory?.subcategories) {
  //       const populatedSubcategory = populatedCategory.subcategories?.find?.(
  //         test => test._id === subcategory,
  //       );
  //       if (populatedSubcategory) {
  //         return `${populatedCategory.name} / ${populatedSubcategory.name}`;
  //       }
  //     }
  //     return populatedCategory?.name || '';
  //   },
  //   [store?.categories, category, subcategory],
  // );

  const galleryItems = useGalleryItems(
    gallery,
    false,
    {},
    variants,
    options,
    undefined,
    searchParams.category,
    category,
    categories,
  );

  const images = useMemo(
    () => {
      const imagesLocal = galleryItems.map(
        // galleryItem => galleryItem.image?.src || galleryItem.video?.src,
        galleryItem => galleryItem.image || galleryItem.video,
      );
      switch (true) {
        case card?.interaction === 'SLIDING':
          return imagesLocal;
        case card?.interaction === 'SWAPPING':
          return imagesLocal.slice(0, 2);
        default:
          return imagesLocal;
      }
    },
    [galleryItems, card?.interaction],
  );

  // const swappingImages = card?.swapping?.with === 'ADDITIONAL_IMAGE'
  //   ? product?.imageSecondary?.src
  //   : product[card?.swapping?.with.toLowerCase()]
  //     .filter((item, index, self) => self
  //     .findIndex(i => i?.image?.meta?.md5 === item?.image?.meta?.md5) === index)
  //     .map(item => item?.image?.src || null)
  //     .filter(Boolean);

  // const slidingImages = product[card?.sliding?.with.toLowerCase()]
  //   .filter((item, index, self) => self
  //   .findIndex(i => i?.image?.meta?.md5 === item?.image?.meta?.md5) === index)
  //   .map(item => item?.image?.src || null)
  //   .filter(Boolean);

  const isFeatured = featured
    // TODO: Find a better way to determine if a product variant is featured
    || variations?.find(v => v?.slug === slug?.split('/')?.pop())?.featured;

  const productUrl = `shop/${slug}${
    searchParams.category
    ? `?${stringifyQuery({ category: searchParams.category })}`
    : ''
  }`;

  const priceWithCurrency = usePriceWithCurrency({
     value: price, vat: true, // @NOTE: check this @nikolakanacki
  });

  const vars = useMemo(
    () => ({
      name,
      price,
      priceBeforeSale,
      priceWithCurrency,
      slug,
      inStock,
      featured,
      category,
      categories,
      variants,
      options,
      priceLowest,
      priceHighest,
      variations,
      galleryItems,
      images: galleryItems.map(
        galleryItem => galleryItem.image || galleryItem.video,
      ),
      isFeatured,
      url: productUrl,
      rating,
      reviewCount,
      description,
    }),
    [
      name,
      price,
      priceBeforeSale,
      priceWithCurrency,
      slug,
      inStock,
      featured,
      category,
      categories,
      variants,
      options,
      priceLowest,
      priceHighest,
      variations,
      galleryItems,
      isFeatured,
      productUrl,
      rating,
      reviewCount,
      description,
    ],
  );

  const productCardBlock = useStoreBlockRender(
    'PRODUCT_CARD',
    'shopProductItem',
    vars,
  );

  if (productCardBlock === true) {
    return null;
  }

  if (productCardBlock) {
    return productCardBlock;
  }

  return (
    <Wrapper
      ref={ref}
      as={Link}
      to={`shop/${slug}${
        searchParams.category
        ? `?${stringifyQuery({ category: searchParams.category })}`
        : ''
      }`}
      disabled={!inStock}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      css={{
        ...(isFeatured && card.featuredBehaviour === 'SIZE') && {
          gridRow: 'span 2',
          gridColumn: 'span 2',
          '[data-mozhe="CardImageWrapper"]': {
            // TODO: Check if this is correct for all aspect ratios
            aspectRatio: 'calc($shop$card$aspectRatio * 0.9)',
          },
        },
      }}
      {...props}
    >
      {
        !optimize || isOnPage
        ? (
            <Tags>
              { priceBeforeSale > 0 ? <Tag>{tagSale}</Tag> : null }
              {
                isFeatured
                  ? <Tag style={{ opacity: 0.7 }}>{tagFeatured}</Tag>
                  : null
              }
              { !inStock ? <Tag disabled>{tagSoldOut}</Tag> : null }
            </Tags>
          )
        : null
      }
      <ImageWrapper>
        {
          !optimize || isOnPage
          ? (
              <>
                {card?.interaction === 'STATIC' && (
                  <Image src={galleryItems?.[0]?.image?.src} />
                )}
                {card?.interaction === 'SLIDING' && (
                  <Sliding
                    images={images}
                    isDraggable={images.length > 1}
                    options={card?.sliding}
                  />
                )}
                {card?.interaction === 'SWAPPING' && (
                  <>
                    {card?.swapping.with === 'ADDITIONAL_IMAGE' ? (
                      <SwappingSingle
                        image={
                          isHovered
                            ? (images[1] || images[0])
                            : images[0]
                        }
                        options={card?.swapping}
                      />
                    ) : (
                      <SwappingLoop
                        shouldAnimate={images?.length > 0 && isHovered}
                        images={images}
                        options={card?.swapping}
                      />
                    )}
                  </>
                )}
              </>
            )
          : null
        }
      </ImageWrapper>
      <Content>
        <Header>
          <Title
            size="xs"
            css={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
            title={name}
          >
            {name}
          </Title>
          {(store.reviewShowRatingOnProductCard && reviewCount > 0) && (
            <RatingWrapper>
              <Rating>
                <Paragraph>
                  {rating.toFixed(1)}
                </Paragraph>
              </Rating>
              <RatingIcon
                className={
                  // eslint-disable-next-line max-len
                  `ri-${ICONS_MAP[store?.style?.icons?.rating || 'star'][Math.floor(rating)] || ICONS_MAP[store?.style?.icons?.rating || 'star'][0]}-${ICONS_STYLE_MAP[icons?.style || 'line']}`
                }
              />
            </RatingWrapper>
          )}
        </Header>
        <Paragraph as="div">
          <PriceWrapper>
            <PriceWithCurrency value={price} product />
            {priceHighest > priceLowest && (
              <>
                {' - '}
                <PriceWithCurrency value={priceHighest} product />
              </>
            )}
            {priceBeforeSale > 0 && (
              <PriceBeforeSale>
                <PriceWithCurrency value={priceBeforeSale} product />
              </PriceBeforeSale>
            )}
          </PriceWrapper>
        </Paragraph>
      </Content>
    </Wrapper>
  );
};

export default Card;
