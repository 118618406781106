import getKey from 'lodash/get';
import escapeHtml from 'lodash/escape';

import React, {
  createContext,
  useContext,
  useMemo,
} from 'react';

const GlobalVarsContext = createContext({});

export const VAR_REGEX = /\{\{([^}\s]+)\}\}/g;
export const VAR_REGEX_EXACT = /^\{\{([^}\s]+)\}\}$/;

export default GlobalVarsContext;

export const { Consumer } = GlobalVarsContext;

export const Provider = ({ children, vars, name }) => {
  const previousData = useGlobalVars();
  const data = useMemo(
    () => (
      previousData
      ? {
          ...previousData,
          [name]: vars,
        }
      : {
          [name]: vars,
        }
    ),
    [vars, previousData, name],
  );
  return (
    <GlobalVarsContext.Provider value={data}>
      {children}
    </GlobalVarsContext.Provider>
  );
};

export function getContentWithReplacedVars(
  content = '',
  vars,
  {
    html = false,
    defaultValue = '',
  } = {},
) {
  if (!content || typeof content !== 'string' || !vars) {
    return content;
  }
  const match = content.match(VAR_REGEX_EXACT);
  if (match?.[1]) {
    return (
      html
      ? escapeHtml(getKey(vars, match[1], defaultValue))
      : getKey(vars, match[1], defaultValue)
    );
  }
  return content.replace(
    VAR_REGEX,
    (all, varPath) => (
      html
      ? escapeHtml(getKey(vars, varPath, defaultValue))
      : getKey(vars, varPath, defaultValue)
    ),
  );
}

export function useContentWithReplacedVars(
  content = '',
  {
    html = false,
    defaultValue = '',
  } = {},
) {
  const vars = useGlobalVars();
  const replacedContent = useMemo(
    () => getContentWithReplacedVars(
      content,
      vars,
      { html, defaultValue },
    ),
    [content, vars, html, defaultValue],
  );
  return replacedContent;
}

export function getObjectWithReplacedVars(
  object,
  vars,
  {
    html = {},
    properties,
    skipProperties,
    defaultValue = '',
  } = {},
) {
  if (!vars || !object) {
    return object;
  }
  return (properties || Object.keys(object)).reduce(
    (agr, key) => {
      if (
        !skipProperties?.includes?.(key)
        && typeof object[key] === 'string'
      ) {
        agr[key] = getContentWithReplacedVars(
          object[key],
          vars,
          {
            html: html[key],
            defaultValue,
          },
        );
      } else {
        agr[key] = object[key];
      }
      return agr;
    },
    {},
  );
}

export function useObjectWithReplacedVars(
  object,
  {
    html = {},
    properties,
    skipProperties,
    defaultValue = '',
  } = {},
) {
  const vars = useGlobalVars();
  const replacedObject = useMemo(
    () => getObjectWithReplacedVars(
      object,
      vars,
      { html, properties, skipProperties, defaultValue },
    ),
    [
      object,
      vars,
      html,
      properties,
      skipProperties,
      defaultValue,
    ],
  );
  return replacedObject;
}

export const useGlobalVars = () => useContext(GlobalVarsContext);
