import React, { forwardRef } from 'react';
import { useLocation, Link as ReactRouterLink } from 'react-router-dom';

import { styled } from '../../stitches.config';

import { useStore } from '../../context/Store';
import { useLanguage } from '../../context/Language';

const Wrapper = styled.named('Link')('div', { cursor: 'pointer' });

const InnerLink = ({ to, children, ...props }) => (
  <Wrapper
    as={ReactRouterLink}
    to={to}
    {...props}
  >
    {children}
  </Wrapper>
);

const OutterLink = ({ to, children, ...props }) => (
  <Wrapper as="a" href={to} target="_blank" {...props}>
    {children}
  </Wrapper>
);

// eslint-disable-next-line consistent-return
const QueryLink = ({ to, children, ...props }) => {
  const location = useLocation();

  // Assuming 'to' is a string like '?param=value'
  if (to.startsWith('?')) {
    const searchParams = new URLSearchParams(location.search);
    const newParams = new URLSearchParams(to.substring(1)); // remove the '?' prefix

    // Append new params to the existing ones
    Array.from(newParams.entries()).forEach(([key, value]) => {
      searchParams.set(key, value);
    });

    const href = `${location.pathname}?${searchParams.toString()}`;

    return (
      <ReactRouterLink to={href} {...props}>
        {children}
      </ReactRouterLink>
    );
  }
};

const TYPES = {
  inner: InnerLink,
  query: QueryLink,
  outter: OutterLink,
};

const getType = (to) => {
  if (
    to.startsWith('http') || to.startsWith('mailto') || to.startsWith('tel')
  ) return 'outter';

  if (to.startsWith('?')) return 'query';

  return 'inner';
};

const Link = forwardRef(({ to = '', children, node, ...props }, ref) => {
  const { data: { domainsInUse, slug } } = useStore();
  const { language } = useLanguage();

  to = (
    to.startsWith('http')
    || to.startsWith('mailto')
    || to.startsWith('tel')
    || to.startsWith('/')
    || to.startsWith('#')
    || to.startsWith('?')
  )
    ? to
    : `/${to}`;

  const slugSafeLink = domainsInUse?.length > 0
    ? `/${language}${to}`
    : `/${slug}/${language}${to}`;

  const toFinal = getType(to) === 'inner' ? slugSafeLink : to;

  return (
    TYPES[getType(to)]({
      to: toFinal,
      children,
      ref,
      'aria-label': node?.name || toFinal,
      'data-custom-name': node?.name,
      ...props,
    })
  );
});

export default Link;
