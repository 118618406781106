import React, { useRef, useMemo } from 'react';
import { createNamedStyled } from '../../stitches.config';

import {
  useGlobalVars,
  getObjectWithReplacedVars,
} from '../../context/GlobalVars';

import { getEffects, getHoverEffects } from '../functions/getEffects';

import LinkWrapper from '../helpers/Link';
import RevealWrapper from '../helpers/Reveal';
import ParallaxWrapper from '../helpers/Parallax';

import Base from './Base';

const styled = createNamedStyled('Frame');

const Node = styled(Base, {
  display: 'flex',
  flexDirection: 'column',
});

const convertToKebabCase = string => string
  .replace(/([a-z])([A-Z])/g, '$1-$2')
  .replace(/[\s_]+/g, '-')
  .toLowerCase();

// eslint-disable-next-line no-restricted-globals
const getPixelSafeValue = value => (!isNaN(value)
  ? `${value}px` : value);

const KEYS_MAP = {
  direction: 'flex-direction',
  widthMax: 'max-width',
  widthMin: 'min-width',
  heightMax: 'max-height',
  heightMin: 'min-height',
  alignPrimary: 'justify-content',
  alignSecondary: 'align-items',
};

const getKeyMapped = key => KEYS_MAP[key] || key;

const VALUES_MAP = {
  START: 'flex-start',
  END: 'flex-end',
  BETWEEN: 'space-between',
  AROUND: 'space-around',
  EVENLY: 'space-evenly',
  ALL: 'initial',
};

const getValueMapped = value => VALUES_MAP[value]
  || convertToKebabCase(value);

const SKIP_OBJECT_VALUE_KEYS = ['absolute'];

const getObjectValuesKey = key => (SKIP_OBJECT_VALUE_KEYS
  .includes(key) ? '' : `${key}-`);

const getInnerKeyAdvanced = (key, innerKey) => {
  switch (innerKey) {
    case 'left':
      return key === 'absolute' ? 'inset-inline-start' : 'inline-start';
    case 'right':
      return key === 'absolute' ? 'inset-inline-end' : 'inline-end';
    default:
      return innerKey;
  }
};

const getObjectValues = (key, value) => Object
  .entries(value)
  .reduce((acc, [innerKey, innerValue]) => ({
    ...acc,
    ...innerValue === '' ? {} : ({
      [`${getObjectValuesKey(key)}${getInnerKeyAdvanced(key, innerKey)}`]:
      getPixelSafeValue(innerValue),
    }),
  }), {});

const getPropValue = ({ key, value }) => {
  if (value === '') { return {}; }
  switch (key) {
    case 'display':
    case 'position':
    case 'overflow':
    case 'direction':
    case 'alignPrimary':
    case 'alignSecondary':
    case 'alignSelf':
    case 'pointerEvents':
    case 'flexWrap':
      return { [getKeyMapped(key)]: getValueMapped(value) };
    case 'width':
    case 'height':
    case 'gap':
    case 'widthMax':
    case 'widthMin':
    case 'heightMax':
    case 'heightMin':
    case 'flexBasis':
      return { [getKeyMapped(key)]: getPixelSafeValue(value) };
    case 'zIndex':
    case 'flexGrow':
    case 'flexShrink':
    case 'order':
      return { [getKeyMapped(key)]: parseInt(value, 10) };
    case 'aspectRatio':
      return { [getKeyMapped(key)]: value };
    case 'margin':
    case 'padding':
    case 'absolute':
      return getObjectValues(key, value);
    default:
      return {};
  }
};

const getLayoutProperties = (layout) => Object
  .entries(layout)
  .reduce((acc, [key, value]) => ({
    ...acc,
    ...getPropValue({ key, value }),
  }), {});

const Frame = ({ node, renderNode, css, as, ...props }) => {
  const ref = useRef(null);

  const globalVars = useGlobalVars();

  {
    const [
      frameEffects,
      frameEffectsHover,
    ] = useMemo(
      () => [
        (node?.frame_effects || []).map(
          effect => getObjectWithReplacedVars(effect, globalVars, {

          })
        ),
        (node?.frame_effectsHover || []).map(
          effect => getObjectWithReplacedVars(effect, globalVars, {

          }),
        ),
      ],
      [node?.frame_effects, node?.frame_effectsHover, globalVars],
    );

    node = useMemo(
      () => ({
        ...node,
        frame_effects: frameEffects,
        frame_effectsHover: frameEffectsHover,
      }),
      [node, frameEffects, frameEffectsHover],
    );
  }

  const {
    linkEffect,
    parallaxEffect,
    revealEffect,
  } = useMemo(
    () => {
      const result = {};
      let found = 0;
      if (Array.isArray(node?.frame_effects)) {
        const { frame_effects: frameEffects } = node;
        for (let i = 0; i < frameEffects.length; i++) {
          const effect = frameEffects[i];
          if (effect.type === 'LINK' && effect.link_value?.length) {
            if (!result.linkEffect) {
              result.linkEffect = effect;
              found++;
            }
          } else if (effect.type === 'PARALLAX') {
            if (!result.parallaxEffect) {
              result.parallaxEffect = effect;
              found++;
            }
          } else if (effect.type === 'REVEAL') {
            if (!result.revealEffect) {
              result.revealEffect = effect;
              found++;
            }
          }
          if (found === 3) {
            break;
          }
        }
      }
      return result;
    },
    [node],
  );

  const finalCss = useMemo(
    () => ({
      ...getLayoutProperties(node?.frame_layout || {}),
      '@desktop': getLayoutProperties(node?.frame_layoutDesktop || {}),
      '@tablet': getLayoutProperties(node?.frame_layoutTablet || {}),
      '@mobile': getLayoutProperties(node?.frame_layoutMobile || {}),
      ...getEffects(node?.frame_effects),
      ...getHoverEffects(node?.frame_effectsHover, node?.hoverTarget),
      ...css,
      ...node.css,
    }),
    [
      css,
      node,
    ],
  );

  return (
    <LinkWrapper effect={linkEffect} node={node}>
      <ParallaxWrapper effect={parallaxEffect} node={node}>
        <RevealWrapper effect={revealEffect} node={node}>
          <Node
            {...props}
            node={node}
            ref={ref}
            css={finalCss}
            data-items-length={node.frame_items?.length}
          >
            {(node.frame_items?.nodes || [])?.map(item => renderNode(item))}
          </Node>
        </RevealWrapper>
      </ParallaxWrapper>
    </LinkWrapper>
  );
};

export default Frame;
