import isEmpty from 'lodash/isEmpty';

import React, {
  useMemo,
} from 'react';

import { useGlobalVars } from '../../context/GlobalVars';

function isReact(value) {
  let result = false;
  switch (typeof value) {
    case 'string':
    case 'number':
      result = true;
      break;
    default:
      if (Array.isArray(value) && value.length) {
        result = value.reduce((agr, item) => agr && isReact(item), true);
        break;
      }
      result = React.isValidElement(value);
      break;
  }
  return !!result;
}

function getTestResult(test, value, negate = false) {
  let result = false;
  switch (test) {
    case 'DEFAULT':
      result = true;
      break;
    case 'IS_TRUE':
      result = value === true;
      break;
    case 'IS_TRUTHY':
      result = value == true; // eslint-disable-line eqeqeq
      break;
    case 'IS_FALSE':
      result = value === false;
      break;
    case 'IS_FALSY':
      result = value == false; // eslint-disable-line eqeqeq
      break;
    case 'IS_REACT':
      result = isReact(value);
      break;
    case 'IS_STRING':
      result = typeof value === 'string';
      break;
    case 'IS_NUMBER':
      result = Number.isFinite(value);
      break;
    case 'IS_EMPTY':
      result = isEmpty(value);
      break;
    default:
      result = false;
      break;
  }
  return negate ? !result : result;
}

const CBSwitch = ({ node, _css, renderNode, ..._props }) => {
  const vars = useGlobalVars();
  const caseNodeResult = useMemo(
    () => {
      const caseNodes = (node.switch_items?.nodes || []).filter(
        caseNode => caseNode.type === 'SWITCH_CASE'
      );
      let caseNodeDefault = null;
      for (let i = 0; i < caseNodes.length; i++) {
        const caseNode = renderNode.getNodeWithReplacedVars(
          caseNodes[i],
          vars,
        );
        if (
          !caseNode.switch_case_test
          || caseNode.switch_case_test === 'DEFAULT'
        ) {
          caseNodeDefault = caseNode;
        } else {
          const matches = getTestResult(
            caseNode.switch_case_test,
            caseNode.switch_case_value,
            caseNode.switch_case_negate,
          );
          if (matches) {
            return caseNode;
          }
        }
      }
      return caseNodeDefault;
    },
    [node.switch_items, vars, renderNode],
  );
  if (caseNodeResult) {
    return renderNode(caseNodeResult);
  }
  return null;
};

export default CBSwitch;
