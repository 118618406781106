import React, {
  useMemo,
  useState,
} from 'react';

import isEmail from 'validator/lib/isEmail';

import { createNamedStyled } from '../../stitches.config';

import { useApi, getGoogleReCaptchaToken } from '../../context/Api';
import { useStore } from '../../context/Store';

import ButtonBase from '../../components/Elements/Button';
import InputBase from '../../components/Elements/Input';

import Base from './Base';

const styled = createNamedStyled('Newsletter');

const Wrapper = styled.named('Wrapper')(Base, {});
const FormWrapper = styled.named('FormWrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
  '.input-error': {
    color: '$error !important',
  },
});
const Input = styled.named('Input')(InputBase, {
  color: '$dack',
});
const Button = styled.named('Button')(ButtonBase, {
  width: '100%',
});

const Newsletter = ({ node, css, renderNode, ...props }) => {
  const api = useApi();
  const store = useStore();

  const finalCss = useMemo(
    () => ({
      // cursor: 'pointer',
      ...css,
    }),
    [css],
  );
  const listId = node.newsletter_mailchimpListId;

  const [status, setStatus] = useState('idle');
  const [isTouched, setIsTouched] = useState(false);
  const [email, setEmail] = useState('');

  const {
    isSubmitting,
    isSubmitted,
    isSubmitSuccess,
  } = useMemo(
    () => ({
      isIdle: status === 'idle',
      isSubmitting: status === 'submitting',
      isSubmitted: ['success', 'error'].includes(status),
      isSubmitSuccess: status === 'success',
      isSubmitError: status === 'error',
    }),
    [status],
  );

  return (
    <Wrapper
      {...props}
      node={node}
      css={finalCss}
    >
      {
        (
          !isSubmitted
          || !node.newsletter_successHideForm
        )
        ? (
            <FormWrapper>
              <Input
                name="email"
                type="email"
                value={email}
                error={
                  (isTouched && !isEmail(email))
                  ? { message: 'Invalid email address' }
                  : null
                }
                onChange={(value) => {
                  setEmail(value);
                }}
                onBlur={() => {
                  if (email?.length) {
                    setIsTouched(true);
                  }
                }}
                placeholder="Your email"
              />
              <Button
                onClick={async () => {
                  if (!isSubmitting) {
                    try {
                      const reCaptchaToken = await getGoogleReCaptchaToken(
                        'NEWSLETTER_SUBSCRIBE',
                      );
                      if (!isEmail(email)) {
                        setIsTouched(true);
                      } else {
                        setStatus('submitting');
                        await api.post('/newsletter/subscribe', {
                          provider: 'MAILCHIMP',
                          storeId: store.data._id,
                          listId,
                          email,
                          reCaptchaToken,
                        })
                        .then((response) => {
                          setEmail('');
                          setIsTouched(false);
                          setStatus('success');
                          api.debug('newsletter response:', response);
                        })
                        .catch((error) => {
                          setIsTouched(true);
                          setStatus('error');
                          api.debug('newsletter error:', error);
                        })
                      }
                    } catch (error) {
                      api.debug('newsletter subscribe error:', error);
                    }
                  }
                }}
              >
                {node.newsletter_buttonLabel || 'Subscribe'}
              </Button>
            </FormWrapper>
          )
        : null
      }
      {
        isSubmitSuccess
        ? (
            (node.newsletter_successItems?.nodes || [])
            ?.map(item => renderNode(item))
          )
        : null
      }
    </Wrapper>
  );
};

export default Newsletter;
