import setKey from 'lodash/set';

import React, {
  useMemo,
} from 'react';

import Component from '../components/Component';
import Block from '../components/Block';
import Frame from '../components/Frame';
import Text from '../components/Text';
import TextBlock from '../components/TextBlock';
import Media from '../components/Media';
import Slider from '../components/Slider';
import Toggle from '../components/Toggle';
import Product from '../components/Product';
import Products from '../components/Products';
import Embed from '../components/Embed';
import Video from '../components/Video';
import Redirect from '../components/Redirect';
import ShoppingAssistant from '../components/ShoppingAssistant';
import Newsletter from '../components/Newsletter';
import Iterator from '../components/Iterator';
import CBSwitch from '../components/Switch';
import CBSwitchCase from '../components/SwitchCase';
import CBReact from '../components/React';

import AdapterButton from '../adapters/Button';

import {
  useGlobalVars,
  getContentWithReplacedVars,
  getObjectWithReplacedVars,
} from '../../context/GlobalVars';

const NODE_TYPE_COMPONENT_MAP_INITIAL = {
  // PRIMITIVE
  COMPONENT: Component,
  COMPONENT_SET: Component,
  BLOCK: Block,
  FRAME: Frame,
  SLIDER: Slider,
  TOGGLE: Toggle,
  TEXT: {
    NodeComponent: Text,
    html: {
      text_value: true,
    },
  },
  TEXT_BLOCK: {
    NodeComponent: TextBlock,
    html: {
      text_block_value: true,
    },
  },
  MEDIA: Media,
  VIDEO: Video,
  PRODUCT: Product,
  PRODUCTS: Products,
  EMBED: Embed,
  REDIRECT: Redirect,
  SHOPPING_ASSISTANT: ShoppingAssistant,
  NEWSLETTER: Newsletter,
  ITERATOR: Iterator,
  SWITCH: CBSwitch,
  SWITCH_CASE: CBSwitchCase,
  REACT: CBReact,
  // ADAPTERS
  BUTTON: AdapterButton,
};

const NODE_TYPE_COMPONENT_MAP = (
  Object.keys(NODE_TYPE_COMPONENT_MAP_INITIAL).reduce(
    (agr, key) => {
      const value = NODE_TYPE_COMPONENT_MAP_INITIAL[key];
      if (value?.NodeComponent) {
        agr[key] = { html: {}, ...value };
      } else {
        agr[key] = {
          NodeComponent: value,
          html: {},
        };
      }
      return agr;
    },
    {},
  )
);

export const getNodeWithReplacedVars = (nodeInitial, vars) => {
  if (nodeInitial.__varsReplaced) {
    return nodeInitial;
  }
  const { html } = NODE_TYPE_COMPONENT_MAP[nodeInitial.type] || {};
  nodeInitial = {
    ...nodeInitial,
    // ...(nodeInitial.nodeOverrides || {}),
  };
  const copied = [];
  const skipProperties = [];
  if (nodeInitial.nodeOverrides) {
    Object.keys(nodeInitial.nodeOverrides).forEach((key) => {
      const value = getContentWithReplacedVars(
        nodeInitial.nodeOverrides[key],
        vars,
      );
      skipProperties.push(key);
      if (key.includes('.')) {
        const [leading] = key.split('.');
        if (!copied.includes(leading)) {
          copied.push(leading);
          if (nodeInitial[leading]) {
            nodeInitial[leading] = JSON.parse(
              JSON.stringify(nodeInitial[leading]),
            );
          }
        }
        setKey(
          nodeInitial,
          key,
          value,
        );
      } else {
        nodeInitial[key] = value;
      }
    });
  }

  const node = getObjectWithReplacedVars(nodeInitial, vars, {
    html,
    skipProperties,
  });
  node.__varsReplaced = true;
  return node;
}

export const useNodeWithReplacedVars = (node, vars) => {
  const nodeWithReplacedVars = useMemo(
    () => getNodeWithReplacedVars(node, vars),
    [node, vars],
  );
  return nodeWithReplacedVars;
};

const WrappedNodeComponent = ({
  node: nodeInitial,
  renderNode,
  ...extraProps
}) => {
  const vars = useGlobalVars();
  const { NodeComponent } = NODE_TYPE_COMPONENT_MAP[nodeInitial.type] || {};
  const node = useMemo(
    () => getNodeWithReplacedVars(nodeInitial, vars),
    [nodeInitial, vars],
  );
  if (!NodeComponent) {
    return null;
  }
  return (
    <NodeComponent
      {...extraProps}
      node={node}
      renderNode={renderNode}
    />
  );
};

const renderNode = (node, extraProps = {}) => (
  <WrappedNodeComponent
    key={node._id}
    node={node}
    renderNode={renderNode}
    {...extraProps}
  />
);

renderNode.getNodeWithReplacedVars = getNodeWithReplacedVars;

window.mozheContentBuilderRenderNode = renderNode;

export default renderNode;
