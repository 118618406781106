import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

const TemporaryRedirect = ({ node }) => {
  const { pathname } = useLocation();
  const redirectUrl = node.redirect_url.startsWith('/')
    ? (pathname + node.redirect_url).replace('//', '/')
    : node.redirect_url

  return (
    <Redirect to={redirectUrl} />
  );
}

export default TemporaryRedirect;
