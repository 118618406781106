import { useMemo } from 'react';

function optimizeInlineCss(cssString) {
  const commentRegex = /\/\*[^*]*\*+([^/*][^*]*\*+)*\//g;
  const emptyLineRegex = /^\s*[\r\n]/gm;

  return cssString.replace(commentRegex, '').replace(emptyLineRegex, '');
}

export function useOptimizeInlineCss(cssString) {
  const css = useMemo(
    () => (cssString?.length ? optimizeInlineCss(cssString) : ''),
    [cssString],
  );
  return css;
}

export default optimizeInlineCss;
