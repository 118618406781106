import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';

import useInView from '../../helpers/useInView';
import { useTheme } from '../../theme';

function parseFloatStrict(str) {
  if (!str.includes('%') && !str.includes(',')) {
    return parseFloat(str);
  }
  return str;
}

const Reveal = forwardRef(({ children, effect, ...props }, ref) => {
  const [inViewRef, inView] = useInView({
    threshold: parseFloat(effect.reveal_threshold) || 0.05,
    triggerOnce: effect.reveal_trigger === 'ONCE' ? true : false,
    rootMargin: effect.reveal_rootMargin || '0px 0px 0px 0px',
  });

  const { transitions: { spring } } = useTheme();

  const outOfView = effect.reveal_properties?.reduce((acc, curr) => ({
    ...acc,
    [curr.key]: parseFloatStrict(curr.value),
  }), {});

  const child = React.Children.only(children);

  return React.cloneElement(child, {
    ...child.props,
    ...props,
    // key: inView,
    ref: React.useCallback(node => {
      inViewRef(node);
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    }, [inViewRef, ref]),
    as: motion.div,
    css: {
      ...props.css,
      ...child.props.css,
    },
    animate: inView ? {
      x: 0,
      y: 0,
      scale: 1,
      rotate: 0,
      opacity: 1,
      transition: {
        ...(effect.reveal_duration
          ? { duration: parseFloat(effect.reveal_duration) }
          : spring),
        delay: parseFloat(effect.reveal_delay) || 0,
      },
    } : outOfView,
    className: props.node.className,
    'data-in-view': inView,
  })
})

const RevealWrapper = forwardRef(({ children, effect, ...props }, ref) => {
  if (effect) {
    return (
      <Reveal ref={ref} effect={effect} {...props}>
        {children}
      </Reveal>
    )
  }

  const child = React.Children.only(children);
  return React.cloneElement(children, {
    ref,
    ...child.props,
    ...props,
    css: {
      ...props.css,
      ...child.props.css,
    },
  });
})

export default RevealWrapper;
