import { createNamedStyled } from '../../../stitches.config';

import ButtonBase from '../../../components/Elements/Button';

export const styled = createNamedStyled('ShoppingAssistant');

export const Actions = styled.named('Actions')('div', {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',

  margin: '$m auto',
  paddingTop: '$m',
  borderTop: '1px solid $colors$border',
});

export const Button = styled.named('Button')(ButtonBase, {
  '*': { color: 'white !important' },
});
