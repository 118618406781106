import React, {
  createContext, useContext, useEffect, useLayoutEffect, useState,
} from 'react';

import useDarkMode from '@retell/use-dark-mode';
import { useGoogleFonts } from '@flyyer/use-googlefonts';

import { useLocation } from '../context/Location';

import optimizeInlineCss from '../helpers/optimizeInlineCss';

import { createNamedStyled, theme, createTheme } from '../stitches.config';

import getThemeColors from './colors';
import getThemeFonts from './fonts';
import getThemeTransitions from './transitions';
import getThemeBorderRadius from './borderRadius';
import getThemeSettings from './settings';

const StoreThemeContext = createContext({});

export const useTheme = () => useContext(StoreThemeContext);

const styled = createNamedStyled('Theme');

const Wrapper = styled.named('Provider')('div', {
  $space$autoSize: '$sizes$s',
  $space$autoPadding: '$space$s',
  $space$absolutePadding: '$space$s',
  $space$autoGap: '$space$s',
  '@tablet': {
    $space$autoSize: '$sizes$s',
    $space$autoPadding: '$space$s',
    $space$absolutePadding: '$space$s',
    $space$autoGap: '$space$s',
  },
  '@desktop+': {
    $space$autoSize: '$sizes$m',
    $space$autoPadding: '$space$m',
    $space$absolutePadding: '$space$m',
    $space$autoGap: '$space$m',
  },
});

const StoreThemeProvider = ({ children, data: store, language }) => {
  const { style } = store || {};

  const { value: isDarkMode } = useDarkMode();
  const mode = style?.mode === 'AUTO'
    ? isDarkMode
      ? 'DARK'
      : 'LIGHT'
    : style?.mode || 'LIGHT';

  const brand = {
    brandColor: store?.brandColor,
    accentColorPrimary: store?.accentColorPrimary,
    accentColorSecondary: store?.accentColorSecondary,
    logo: mode === 'DARK'
      ? store?.logoDark?.src || store?.logo?.src
      : store?.logo?.src,
  };

  const { fontConfig, ...fonts } = getThemeFonts({ style });
  const { colors, colorOverrides } = getThemeColors({ style, brand, mode });

  const GOOGLE_FONTS = Object.values(fontConfig).reduce((acc, curr) => {
    const weights = [...new Set(Object.values(curr.weight).map(Number))];

    const existingItem = acc.find(item => item.family === curr.family);

    if (existingItem) {
      existingItem.weights = [
        ...new Set([...existingItem.weights, ...weights]),
      ];
    } else {
      acc.push({
        family: curr.family,
        weights,
      });
    }

    return acc;
  }, []);

  useGoogleFonts(GOOGLE_FONTS.map((font) => ({
    family: font.family,
    styles: font.weights,
  })));

  // TODO: Remove this later on when we have a better solution for ismarthome
  //       (also remember to remove from /fonts)
  useGoogleFonts([{
    family: 'Beiruti',
    styles: [200, 300, 400, 500, 600, 700, 800, 900],
  }]);

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('%cSTORE:', 'color: #ff345a; font-weight: bold;', store);
    // eslint-disable-next-line no-console
    console.log('%cSTYLE:', 'color: #ff345a; font-weight: bold;', style);
  }

  const [customTheme, setCustomTheme] = useState({});

  const addToTheme = (newProperties) => {
    setCustomTheme((prevTheme) => ({
      ...prevTheme,
      ...newProperties,
    }));
  };

  const themeParams = {
    brand,
    colors,
    colorOverrides,
    ...fonts,

    radii: getThemeBorderRadius({ style }),
    transitions: getThemeTransitions({ style }),

    ...getThemeSettings({ store, brand, style, mode, language }),

    additional: { mode, language },

    ...customTheme,
  };

  const storeTheme = createTheme(themeParams);

  useEffect(() => {
    document.body.classList.add(storeTheme);

    return () => {
      document.body.classList.remove(storeTheme);
    };
  }, [storeTheme, customTheme]);

  // TODO: Remove this nonsense, por favor @markoradak
  const [absolutePadding, setAbsolutePadding] = useState();
  useLayoutEffect(() => {
    const measureElement = document.querySelector('.absolutePaddingMeasure');
    const updatePadding = () => setAbsolutePadding(measureElement?.offsetLeft);
    updatePadding();
    window.addEventListener('resize', updatePadding);
    return () => window.removeEventListener('resize', updatePadding);
  }, []);

  const { searchParams } = useLocation();

  return (
    <StoreThemeContext.Provider
      value={{ ...theme, ...themeParams, addToTheme }}
    >
      {style?.advanced?.css
        ? <style>{optimizeInlineCss(style?.advanced?.css)}</style>
        : null}
      <Wrapper
        className={storeTheme}
        dir={language?.direction.toLowerCase()}
        lang={language?.language}
        data-language={language?.language}
        data-mode={mode.toLowerCase()}
        css={{ '--space-absolutePadding': `${absolutePadding}px` }}
        // TODO: Improve logic
        data-page={window.location.pathname.includes('/shop/')
          ? 'product'
          : window.location.pathname.includes('/shop')
            ? 'shop'
            : window.location.pathname.includes('/order')
              ? 'order'
              : 'page'}
        data-category={searchParams.category}
        data-collection={searchParams.collection}
      >
        {children}
      </Wrapper>
    </StoreThemeContext.Provider>
  );
};

export default StoreThemeProvider;

export const useColorOverrides = (component, page) => {
  const { colors, colorOverrides } = useTheme();

  const overrides = createTheme({
    colors: {
      // --- Colors
      ...colorOverrides?.[component],
      // --- Page Background
      ...(!!page && {
        backgroundWebsite: colors[`backgroundPage${
          component[0].toUpperCase() + component.slice(1)
        }`],
      }),
    },
  });

  return overrides;
};
