import intersection from 'lodash/intersection';

import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import Base from '../Base';

import { useApi, useApiRequest } from '../../../context/Api';
import { useStore } from '../../../context/Store';
import { parseProduct } from '../../../context/Product';
import { useDictionary, useLanguage } from '../../../context/Language';
import { useCart } from '../../../context/Cart';

import {
  Title,
  Label,
  Paragraph,
  Link,
} from '../../../components/Elements/Text';
import Loader from '../../../components/Elements/Loader';
import Spinner from '../../../components/Elements/Spinner';

import {
  styled,
  Actions,
  Button,
} from './common';

import { keyframes } from '../../../stitches.config';

import SubmitResults from './SubmitResults';

import Types from '../../../modules/types';

const Node = styled(Base, {});

const Wrapper = styled.named('Wrapper')(motion.div, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$s',
  flex: '1 1 auto',

  minHeight: '620px',
});

const QuestionWrapper = styled.named('QuestionWrapper')(motion.div, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '$m',
  gap: '$s',
});

const Icon = styled.named('Icon')('div', {
  display: 'flex',
  width: 120,
  height: 120,
  margin: '$s',

  transition: 'background $s $ease',

  '@mobile': {
    width: '100%',
    height: '100%',
    aspectRatio: '1 / 1',
  },
});

const Choices = styled.named('Choices')('div', {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '$s',
  gap: '$s',

  '@mobile': {
    display: 'grid',
    alignItems: 'stretch',
    gridTemplateColumns: 'repeat(3, 25vw)',
    gap: '$xs',
  },
});

const Choice = styled.named('Choice')('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  gap: '$xs',
  padding: '$s',
  border: '1px solid $text20',
  borderRadius: '$m',
  cursor: 'pointer',

  '@mobile': {
    height: 'auto',
    textAlign: 'center',
  },

  transition: 'border-color $s $ease, background $s $ease',
  '*': { transition: 'color $m $ease, background $s $ease !important' },

  '@media (hover: hover)': {
    '&:hover': {
      borderColor: '$brandColor',
      '*': {
        color: '$brandColor',
        transition: 'color $s $ease, background $s $ease !important',
      },

      '@supports(mask: url(""))': {
        '.icon': {
          background: '$brandColor',
        },
      },
    },
  },

  variants: {
    selected: {
      true: {
        borderColor: '$brandColor',
        background: '$brandColor',
        '*': { color: 'white !important' },

        '@supports(mask: url(""))': {
          '.icon': {
            background: 'white !important',
          },
        },
      },
    },
  },
});

const keyframesFadeIn = keyframes({
  '0%': { opacity: 0, translate: '0px 25px' },
  '100%': { opacity: 1, translate: '0px 0px' },
});

const Floating = styled.named('FloatingButton')('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  zoom: 1.3,

  variants: {
    floating: {
      true: {
        position: 'fixed',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: '1000',
        opacity: 0,
        animation: `${keyframesFadeIn} 1000ms 0ms forwards`,
      },
      false: {
        marginBottom: '$m',
      },
    },
  },
});

function QuestionChoices({
  name, value, onChange, choices, multiple, triggerNextQuestion,
}) {
  value = useMemo(
    () => (
      multiple
      ? (
          value
          ? Array.isArray(value) ? value : [value]
          : []
        )
      : value
    ),
    [multiple, value],
  );
  return (
    <Choices>
      {choices.map(choice => (
        <Choice
          key={choice._id}
          selected={
            (
                multiple
              ? value.includes(choice._id)
              : value === choice._id
            )
          }
          onClick={() => {
            onChange(
              name,
              (
                multiple
                ? (
                    value.includes(choice._id)
                    ? value.filter(choiceId => choiceId !== choice._id)
                    : [...value, choice._id]
                  )
                : choice._id
              ),
            );

            !multiple && triggerNextQuestion();
          }}
        >
          {!!choice.icon && (
            <Icon
              className="icon"
              css={{
                '[data-mode="dark"] &': { filter: 'invert(1)' },
                background:
                  `url(${choice.icon.src}) no-repeat center / contain`,
                '@supports(mask-image: url(""))': {
                  filter: 'none !important',
                  background: '$text',
                  mask: `url(${choice.icon.src}) no-repeat center / contain`,
                },
              }}
            />
          )}
          <Label>{choice?.label}</Label>
        </Choice>
      ))}
    </Choices>
  )
}

function Question({ question, value, onChange, triggerNextQuestion }) {
  const {
    _id: id,
    type,
    label,
    choices,
    // placeholder,
  } = question;
  return (
      (type === 'CHOICES' || type === 'CHOICES_MULTIPLE')
    ? (
        <QuestionWrapper
          key={id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
        >
          <Title css={{ textAlign: 'center' }}>
            {label}
          </Title>
          {
            question.description?.length
            ? (
                <Paragraph css={{ textAlign: 'center', marginTop: '-$xs' }}>
                  {question.description}
                </Paragraph>
              )
            : null
          }
          <QuestionChoices
            name={id}
            value={value}
            onChange={onChange}
            choices={choices}
            multiple={type === 'CHOICES_MULTIPLE'}
            triggerNextQuestion={triggerNextQuestion}
          />
        </QuestionWrapper>
      )
    : (type === 'TEXT')
    ? null // TODO Implement TEXT type
    : null
  );
}

function ShoppingAssistantComponent({ assistant }) {
  const { _id: id, questions: questionsInput } = assistant;
  const questionsRaw = questionsInput;
  // TODO For when we need AND on questions and OR on question choices to match
  // const {
  //   questionsChoiceIdToQuestionIdMap,
  // } = useMemo(
  //   () => questionsRaw.reduce(
  //     (agr, question) => {
  //       if (
  //         ['CHOICES', 'CHOICES_MULTIPLE'].includes(question.type)
  //         && question.choices?.length
  //       ) {
  //         question.choices.forEach((choice) => {
  //           agr.questionsChoiceIdToQuestionIdMap[choice._id] = question._id;
  //         });
  //       }
  //       return agr;
  //     },
  //     {
  //       questionsChoiceIdToQuestionIdMap: {},
  //     },
  //   ),
  //   [questionsRaw],
  // );
  const api = useApi();
  const { data: storeData } = useStore();
  const {
    currency: dictionaryCurrency,
  } = useDictionary();
  const { currency: systemCurrency } = Types.getSystemCountry(
    storeData.systemCountry,
  );
  const currency = dictionaryCurrency[systemCurrency];
  const {
    clearCart,
    replaceCart,
    toggleShowCart,
    quantityTotal,
  } = useCart();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitResults, setSubmitResults] = useState(null);
  const [values, setValues] = useState({});
  const valueChoiceIds = useMemo(
    () => Object.values(values),
    [values],
  );
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const questions = useMemo(
    () => questionsRaw.filter((question) => {
      if (
        question.answersExclude?.length
        && ['CHOICES', 'CHOICES_MULTIPLE'].includes(question.type)
      ) {
        const matches = intersection(
          valueChoiceIds,
          question.answersExclude
        );
        return matches.length === 0;
      }
      return true;
    }),
    [questionsRaw, valueChoiceIds],
  );
  const currentQuestionRaw = useMemo(
    () => questions[currentQuestionIndex],
    [questions, currentQuestionIndex],
  );
  const currentQuestion = useMemo(
    () => {
      if (
        ['CHOICES', 'CHOICES_MULTIPLE'].includes(currentQuestionRaw.type)
      ) {
        return {
          ...currentQuestionRaw,
          choices: currentQuestionRaw.choices.filter(
            (choice) => {
              if (choice.answersExclude?.length) {
                const matches = intersection(
                  valueChoiceIds,
                  choice.answersExclude
                );
                return matches.length === 0;
              }
              return true;
            },
          ),
        };
      }
      return currentQuestionRaw;
    },
    [currentQuestionRaw, valueChoiceIds],
  );
  const currentQuestionIsMultiple = currentQuestion.type === 'CHOICES_MULTIPLE';
  const currentQuestionValue = values[currentQuestion._id];
  const hasValue = (
    currentQuestionIsMultiple
    ? !!(Array.isArray(currentQuestionValue) && currentQuestionValue.length)
    : !!currentQuestionValue?.trim?.()?.length
  );
  const handleChange = useCallback(
    (questionId, value) => {
      setValues({ ...values, [questionId]: value });
    },
    [values, setValues],
  );

  const currentLanguage = useLanguage();

  const {
    shoppingAssistantLoadingTitle = 'Hang tight!',
    // eslint-disable-next-line max-len
    shoppingAssistantLoadingDescription = 'Our AI is handpicking the perfect products for you. Keep in mind this can usually take up to 30 seconds.',
    shoppingAssistantBack = 'Back',
    shoppingAssistantNext = 'Next',
    shoppingAssistantSubmit = 'Submit',
    shoppingAssistantCheckout = 'Checkout',
  } = useDictionary();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentQuestionIndex, isSubmitting]);

  return (
    <AnimatePresence exitBeforeEnter>
      <Wrapper
        key={isSubmitting ? -1 : currentQuestion._id}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ type: 'spring', damping: 40, stiffness: 200, mass: 2 }}
      >
        {
          isSubmitting
          ? (
            <>
              {/* <Loader /> */}
              <Spinner />
              <Title css={{ fontSize: '$s', textAlign: 'center' }}>
                {shoppingAssistantLoadingTitle}
              </Title>
              <Paragraph css={{ textAlign: 'center', marginTop: '-$xs' }}>
                {shoppingAssistantLoadingDescription}
              </Paragraph>
            </>
          )
          : (
            <>
              {
                submitResults
                ? (
                  <SubmitResults
                    key="submitResults"
                    submitResults={submitResults}
                  />
                )
                : (
                  <Question
                    key={currentQuestion._id}
                    question={currentQuestion}
                    value={values[currentQuestion._id]}
                    onChange={handleChange}
                    triggerNextQuestion={() => setCurrentQuestionIndex(
                      Math.min(currentQuestionIndex + 1, questions.length - 1)
                    )}
                  />
                )
              }
              {
                !submitResults && !isSubmitting && currentQuestionIndex < 1
                ? null
                : (
                    <Link
                      key="back"
                      css={{ marginBottom: '$m' }}
                      onClick={() => {
                        if (submitResults) {
                          setCurrentQuestionIndex(questions.length - 1);
                          setSubmitResults(null);
                        } else if (currentQuestionIndex > 0) {
                          const newValues = { ...values };
                          delete newValues[currentQuestion._id];
                          setValues(newValues);
                          setCurrentQuestionIndex(currentQuestionIndex - 1);
                        }
                      }}
                    >
                      {/* TODO Translate */}
                      {shoppingAssistantBack}
                    </Link>
                  )
              }
              {(currentQuestionIsMultiple && hasValue) && (
                <Floating floating={!submitResults}>
                  <Button
                    disabled={submitResults && !quantityTotal}
                    onClick={() => {
                      if (submitResults) {
                        toggleShowCart(true);
                      } else if (currentQuestionIndex < questions.length - 1) {
                        setCurrentQuestionIndex(currentQuestionIndex + 1);
                      } else {
                        setIsSubmitting(true);
                          api.post(
                            `shopping-assistants/${id}/form`,
                            {
                              answers: questions.reduce(
                                (agr, question) => {
                                  agr[question._id] = values[question._id];
                                  return agr;
                                },
                                {},
                              ),
                              language: currentLanguage.language,
                            },
                          )
                          .then(response => {
                            {
                              const aiLog = `===\nAI PROMPT:\n===\n\n${
                                response?.ai?.prompt
                              }\n\n===\nAI RESULT:\n===\n\n${
                                JSON.stringify(response?.ai?.result, null, '\t')
                              }`;
                              // eslint-disable-next-line no-console
                              console.log(`%c${aiLog}`, '');
                            }
                            console.log(
                              'shopping assistant: response:',
                              response,
                            );
                            const products = response.products.map(product => (
                              parseProduct(product, storeData, currency, false)
                            ));
                            const storeSkuToProductMap = products.reduce(
                              (agr, product) => {
                                product.variations.forEach((variation) => {
                                  agr[variation.storeSku] = product;
                                });
                                return agr;
                              },
                              {},
                            );
                            const storeSkusWithQuantity = (
                              Object.keys(response.skus)
                              .reduce(
                                (agr, storeSku) => {
                                  const quantity = response.skus[storeSku];
                                  if (quantity > 0) {
                                    agr.push(storeSku);
                                  }
                                  return agr;
                                },
                                [],
                              )
                            );
                            if (storeSkusWithQuantity.length > 0) {
                              const resultProducts = Object.keys(
                                response.skus,
                              ).reduce(
                                (agr, storeSku) => {
                                  const quantity = response.skus[storeSku];
                                  const product = storeSkuToProductMap[
                                    storeSku
                                  ];
                                  if (product) {
                                    const variation = product.variations.find(
                                      ({ storeSku: testStoreSku }) => (
                                        testStoreSku === storeSku
                                      ),
                                    );
                                    if (variation) {
                                      const galleryItems = Types
                                        .getGalleryImages(
                                          product.gallery,
                                          false,
                                          variation,
                                          product.variants,
                                          product.options,
                                        );
                                      agr.push({
                                        product,
                                        variation,
                                        quantity,
                                        galleryItems,
                                        cartItem: {
                                          sku: variation.sku,
                                          price: variation.price,
                                          meta: {
                                            name: product.name,
                                            storeSku: variation.storeSku,
                                            variation: variation.name,
                                            tagline: product.tagline,
                                            image: galleryItems?.[0]?.image?.src
                                              || null,
                                            galleryId: galleryItems[0]?._id,
                                            // options: optionValues,
                                            categoryName: product.categoryName
                                              || 'Product',
                                            productId: product._id,
                                            productSlug: product.slug,
                                            variationSlug: variation.slug,
                                          },
                                          quantity,
                                        },
                                      });
                                    }
                                  }
                                  return agr;
                                },
                                [],
                              );
                              // TODO: Remove later
                              if (!assistant.addToCartManually) {
                                const itemsToAdd = resultProducts.map(
                                  ({ cartItem }) => cartItem,
                                );
                                if (itemsToAdd.length) {
                                  console.log('itemsToAdd:', itemsToAdd);
                                  replaceCart(itemsToAdd).then(() => {
                                    toggleShowCart(true);
                                  });
                                }
                              } else {
                                const productGroups = (
                                  assistant.productGroups.reduce(
                                    (agr, productsGroup) => {
                                      const group = {
                                        ...productsGroup,
                                        products: [],
                                      };
                                      resultProducts.forEach(
                                        (resultProduct) => {
                                          if (
                                            resultProduct
                                            ?.product
                                            ?.productGroupIds
                                            ?.includes?.(productsGroup._id)
                                          ) {
                                            group.products.push(resultProduct);
                                          }
                                        },
                                      );
                                      if (group.products.length) {
                                        agr.push(group);
                                      }
                                      return agr;
                                    },
                                    [],
                                  )
                                );
                                if (productGroups.length) {
                                  const itemsToAdd = resultProducts.map(
                                    ({ cartItem }) => cartItem,
                                  );
                                  if (itemsToAdd.length) {
                                    replaceCart(itemsToAdd);
                                  }
                                  setSubmitResults({
                                    products: resultProducts,
                                    productGroups,
                                    explanation: response.explanation || null,
                                  });
                                }
                              }
                            } else {
                              console.log(
                                // eslint-disable-next-line max-len
                                'shopping assistant: no skus or skus have no quantity',
                              );
                            }
                            setIsSubmitting(false);
                          })
                          .catch(error => {
                            setIsSubmitting(false);
                            setSubmitResults(null);
                            console.log('shopping assistant: error:', error);
                          });
                      }
                    }}
                  >
                    {submitResults
                      ? shoppingAssistantCheckout
                      : currentQuestionIndex < questions.length - 1
                      ? shoppingAssistantNext
                      : shoppingAssistantSubmit}
                  </Button>
                </Floating>
              )}
            </>
          )
        }
      </Wrapper>
    </AnimatePresence>
  );
}

function ShoppingAssistantLoader({ id }) {
  const {
    assistantUnavailable = 'This shopping assistant is currently unavailable.',
  } = useDictionary();

  const [
    {
      data: assistant,
      loading: assistantLoading,
    },
  ] = useApiRequest({
    url: `shopping-assistants/${id}/form`,
    skip: !id,
  });

  if (assistantLoading) {
    return ''; // Loading...
  }
  if (!assistant) {
    return assistantUnavailable;
  }
  return (
    <ShoppingAssistantComponent assistant={assistant} />
  );
}

const ShoppingAssistant = ({ node, css, renderNode, ...props }) => {
  const finalCss = useMemo(
    () => ({
      ...css,
    }),
    [css],
  );
  return (
    <Node
      {...props}
      tag="div"
      node={node}
      css={finalCss}
    >
      <ShoppingAssistantLoader id={node.shopping_assistant_item} />
    </Node>
  );
};

export default ShoppingAssistant;
