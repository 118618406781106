import React, {
  useMemo,
} from 'react';
// import { createNamedStyled } from '../../stitches.config';

import {
  Provider as GlobalVarsProvider,
  // useGlobalVars,
} from '../../context/GlobalVars';

// import Base from './Base';

// const styled = createNamedStyled('Link');

// const Node = styled(Base, {});

const IteratorItems = ({ nodes = [], renderNode }) => (
  nodes.map(nodeItem => renderNode(nodeItem))
);

const Iterator = ({ node, _css, renderNode, ..._props }) => {
  const items = useMemo(
    () => {
      let localItems = [];
      if (!Array.isArray(node.iterator_value)) {
        try {
          const itemsArray = JSON.parse(node.iterator_value);
          if (Array.isArray(itemsArray)) {
            localItems = itemsArray;
          }
        } catch (error) {
          // localItems = [];
        }
      } else {
        localItems = node.iterator_value;
      }
      return localItems;
    },
    [node.iterator_value],
  );
  return items.map(item => (
    <GlobalVarsProvider
      key={item._id || item.id || item.name}
      name={node.iterator_expose}
      vars={item}
    >
      <IteratorItems
        nodes={node.iterator_items?.nodes}
        renderNode={renderNode}
      />
    </GlobalVarsProvider>
  ));
};

export default Iterator;
