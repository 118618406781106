import React, {
  useMemo,
} from 'react';

import Base from './Base';

import { createNamedStyled } from '../../stitches.config';

import { useApiRequest } from '../../context/Api';
import { useStore } from '../../context/Store';
import { parseProduct } from '../../context/Product';
import { useDictionary } from '../../context/Language';
import { useCart } from '../../context/Cart';

import ButtonBase from '../../components/Elements/Button';
import { TextFormatted } from '../../components/Elements/TextFormatted';

import {
  Paragraph,
} from '../../components/Elements/Text';

import PriceWithCurrency from '../../helpers/PriceWithCurrency';

import Types from '../../modules/types';

export const styled = createNamedStyled('Product');

const Wrapper = styled.named('Wrapper')(Base, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$s',
});

const Button = styled.named('Button')(ButtonBase, {
  cursor: 'pointer !important',
  '*': { color: 'white !important' },
});

const PriceWrapper = styled.named('PriceWrapper')('div', {
  display: 'flex',
  alignItems: 'baseline',
  gap: '$xs',
});

const Price = styled.named('Price')(Paragraph, {
  lineHeight: '100%',
  variants: {
    strikeThrough: {
      true: {
        textDecoration: 'line-through',
        opacity: 0.4,
      },
    },
  },
});

const ProductPrice = ({
  variation,
}) => ((
  <PriceWrapper>
    <Price css={{ fontSize: '$s !important', color: '$title !important' }}>
      <PriceWithCurrency
        value={variation.price}
        product
      />
    </Price>
    {
        variation.priceBeforeSale > 0
      ? (
          <Price strikeThrough>
            <PriceWithCurrency
              value={variation.priceBeforeSale}
              vat
            />
          </Price>
        )
      : null
    }
  </PriceWrapper>
));

const ProductButton = ({
  product,
  variation,
  label,
  action,
  actionOpenCart,
}) => {
  const { addToCart, toggleShowCart } = useCart();
  const {
    generalAddToCart: dictionaryGeneralAddToCart,
    generalViewProduct: dictionaryGeneralViewProduct,
  } = useDictionary();
  const buttonOnClick = useMemo(
    () => {
      if (action === 'ADD_TO_CART') {
        return () => {
          const galleryItems = Types
            .getGalleryImages(
              product.gallery,
              false,
              variation,
              product.variants,
              product.options,
            );
          addToCart(
            variation.sku,
            variation.price,
            {
              name: product.name,
              storeSku: variation.storeSku,
              variation: variation.name,
              tagline: product.tagline,
              image: galleryItems?.[0]?.image?.src || null,
              galleryId: galleryItems[0]?._id,
              // options: optionValues,
              options: {},
              // categoryName: categoryPopulated.name || 'Product',
              productId: product._id,
              // productSlug,
              productSlug: product.slug,
              variationSlug: variation.slug,
            },
          ).then(() => {
            if (actionOpenCart) {
              toggleShowCart(true);
            }
          });
        };
      }
      return undefined;
    },
    [product, variation, action, actionOpenCart, addToCart, toggleShowCart],
  );
  const buttonTo = useMemo(
    () => {
      if (action === 'OPEN_PRODUCT') {
        return `/shop/${product.slug}/${variation.slug}`;
      }
      return undefined;
    },
    [product, variation, action],
  );
  return (
    <Button
      onClick={buttonOnClick}
      to={buttonTo}
      dangerouslySetInnerHTML={label?.length ? { __html: label } : null}
    >
      {
        !label?.length ? (
            action === 'ADD_TO_CART'
          ? dictionaryGeneralAddToCart
          : dictionaryGeneralViewProduct
        ) : null
      }
    </Button>
  );
};

const ProductComponent = (props) => {
  const { mode } = props;
  return (
    <>
      {
        (mode === 'PRICE' || mode === 'BOTH')
        ? (
            <ProductPrice
              {...props}
            />
          )
        : null
      }
      {
        (mode === 'BUTTON' || mode === 'BOTH')
        ? (
            <ProductButton
              {...props}
            />
          )
        : null
      }
    </>
  );
};

const ProductLoader = ({
  nodeProps,
  productId,
  variationId,
  ...props
}) => {
  const { data: storeData } = useStore();
  const {
    currency: dictionaryCurrency,
  } = useDictionary();
  const { currency: systemCurrency } = Types.getSystemCountry(
    storeData.systemCountry,
  );
  const currency = dictionaryCurrency[systemCurrency];
  const [{
    data: productRaw,
    // loading: assistantLoading,
  }] = useApiRequest({
    url: `products/${productId}`,
    skip: !productId,
  });
  const product = useMemo(
    () => (
      (productRaw && storeData && currency)
      ? parseProduct(productRaw, storeData, currency, false)
      : null
    ),
    [productRaw, storeData, currency],
  );
  const variation = useMemo(
    () => product?.variations?.find?.(
      ({ _id }) => _id === variationId,
    ),
    [product, variationId],
  );
  if (!product || !variation) {
    return null;
  }
  return (
    <Wrapper {...nodeProps}>
      <ProductComponent
        productId={productId}
        variationId={variationId}
        product={product}
        variation={variation}
        {...props}
      />
    </Wrapper>
  );
};

const Product = ({ node, css, renderNode, ...props }) => {
  const finalCss = useMemo(
    () => ({
      ...css,
    }),
    [css],
  );
  return (
    <ProductLoader
      nodeProps={{
        node,
        css: finalCss,
        ...props,
      }}
      productId={node.product_product}
      variationId={node.product_variation}
      mode={node.product_mode}
      label={node.product_buttonLabel}
      action={node.product_action}
      actionOpenCart={node.product_actionOpenCart}
    />
  );
};

export default Product;
