import React, { useEffect } from 'react';

import {
  CheckOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { useCart } from '../../../context/Cart';
import { useDictionary } from '../../../context/Language';

import {
  styled as styledBase,
} from './common';

import Card from '../../../components/Elements/Card';
import Button from '../../../components/Elements/Button';

import PriceWithCurrency from '../../../helpers/PriceWithCurrency';

import {
  Title,
  Paragraph,
} from '../../../components/Elements/Text';

import TextBlock from '../../../components/Elements/TextBlock';

const styled = styledBase.named('SubmitResults');

const ButtonAdd = ({ css, ...props }) => (
  <Button
    {...props}
    css={{
      ...css,
      '&:before': {
        backgornd: '$notificationBackground',
        ...[css?.['&:before'] || {}],
      },
      background: 'var(--colors-notificationBackground) !important',
      backgroundColor: 'var(--colors-notificationBackground) !important',
    }}
  />
);

const ButtonRemove = ({ css, ...props }) => (
  <Button
    {...props}
    css={{
      ...css,
      '&:hover': {
        opacity: '0.3 !important',
        ...(css?.['&:hover'] || {}),
      },
    }}
  />
);

const Wrapper = styled.named('Wrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '$s',
});

const GroupWrapper = styled.named('GroupWrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '$m',

  '@desktop': {
    flexDirection: 'row',
    '&:nth-child(odd)': {
      flexDirection: 'row-reverse',
    },
    '&:nth-child(n + 3)': {
      marginTop: '$m',
    },
  },
});

const GroupHeader = styled.named('GroupHeader')('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$xs',
});

const ContentWrapper = styled.named('ContentWrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$xs',
  padding: '$xs $xs $m',

  '@desktop': {
    padding: '$m',
  },
});

const ProductsWrapper = styled.named('ProductsWrapper')('div', {
  marginTop: '$s',
  gap: '$s',

  display: 'grid',
  gridGap: '$shop$card$gap',
  gridTemplateColumns: 'repeat(3, 1fr)',

  '& > *': {
    width: '100%',
    overflow: 'hidden',
  },

  '[data-mozhe="CardTags"]': {
    display: 'none',
  },
});

const MediaWrapper = styled.named('MediaWrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '$xs',

  '@desktop': {
    width: '45%',
    flex: '1 0 auto',
  },
});

const Image = styled.named('Image')('img', {
  width: '100%',
  height: 'auto',
  objectFit: 'contain',
  borderRadius: '$m',
});

const FooterWrapper = styled.named('FooterWrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$s',
  alignItems: 'center',
  margin: '$l auto $m',
});

function SubmitResults({ submitResults }) {
  const {
    ensureInCart,
    ensureNotInCart,
    itemsMap,
  } = useCart();
  const {
    productGroups,
    products,
    explanation,
  } = submitResults;

  const {
    shoppingAssistantIncludedProducts = 'Included products',
    // eslint-disable-next-line max-len
    shoppingAssistantMoreDetails = 'Click to view more details about each product.',
    shoppingAssistantAdd = 'Add',
    shoppingAssistantAdded = 'Added',
    shoppingAssistantAddAllAndCheckout = 'Add All & Checkout',
    shoppingAssistantAllProductsAdded = 'All products added',
    shoppingAssistantClickToRemove = '(click to remove)',
    shoppingAssistantAddAllGroupProducts = 'Add All Group Products',
    shoppingAssistantRemoveAllGroupProducts = 'Remove All Group Products',
    shoppingAssistantAddEverything = 'Add Everything',
    shoppingAssistantAll = 'All',
    shoppingAssistantRecommended = 'Recommended',
    // eslint-disable-next-line max-len
    shoppingAssistantNoteAddAll = 'For the best experience, we recommend adding all the products our AI has selected for you.',
  } = useDictionary();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <GroupHeader
        css={{ alignItems: 'center', margin: '$m auto', textAlign: 'center' }}
      >
        <TextBlock dangerouslySetInnerHTML={{ __html: explanation
            || 'Here are the products we recommend for you:',
          }}
        />
      </GroupHeader>
      {
        productGroups.map((group) => {
          const isGroupProductMissing = group.products.findIndex(
            ({ cartItem }) => (
              !itemsMap[cartItem.sku]
              || itemsMap[cartItem.sku]?.quantity < cartItem.quantity
            ),
          ) > -1;
          return (
            <GroupWrapper key={group._id}>
              <MediaWrapper>
                {
                  // Video
                  group.video?.src
                  ? (
                      // eslint-disable-next-line jsx-a11y/media-has-caption
                      <video
                        controls
                        width="100%"
                        height="auto"
                        src={group.video.src}
                      />
                    )
                  // Image
                  : group.image?.src
                  ? (
                      <Image
                        alt={group.image.meta?.filename}
                        src={group.image.src}
                      />
                    )
                  : null
                }
              </MediaWrapper>
              <ContentWrapper>
              <GroupHeader>
                {
                  group.name
                  ? (
                      <Title size="m">
                        {group.name}
                      </Title>
                    )
                  : null
                }
                {
                  group.description
                  ? (
                      <TextBlock
                        /* eslint-disable-next-line react/no-danger */
                        dangerouslySetInnerHTML={{ __html: group.description }}
                      />
                    )
                  : null
                }
              </GroupHeader>
              <GroupHeader>
                <Title size="s" css={{ marginTop: 20 }}>
                  {shoppingAssistantIncludedProducts}
                </Title>
                <TextBlock css={{ marginTop: -10, marginBottom: -10 }}>
                  <p>
                    {shoppingAssistantMoreDetails}
                  </p>
                </TextBlock>
              </GroupHeader>
              <ProductsWrapper>
                {
                  group.products.map(({
                    product,
                    variation,
                    quantity,
                  }) => (
                    <Card
                      key={variation.sku}
                      product={{
                        name: `${quantity} x ${product.name}`,
                        gallery: product.gallery,
                        // price: variation.price * quantity,
                        price: variation.price,
                        priceBeforeSale: variation.priceBeforeSale,
                        slug: `${product.slug}?closeable=true`,
                        inStock: variation.inStock,
                        featured: product.featured,
                        category: product.category,
                        categories: product.categories,
                        variants: product.variants,
                        priceLowest: variation.priceLowest,
                        priceHighest: variation.priceHighest,
                        variations: product.variations,
                      }}
                      // css={{ pointerEvents: 'none' }}
                      target="_blank"
                    />
                  ))
                }
              </ProductsWrapper>
              <Title size="s">
                <PriceWithCurrency value={group.products.reduce(
                    (acc, { variation, quantity }) => (
                      acc + (variation.priceIncludingVat * quantity)
                    ),
                    0,
                  )}
                />
              </Title>
              {
                isGroupProductMissing
                ? (
                  <ButtonAdd
                    onClick={() => {
                      ensureInCart(group.products.map(
                        ({ cartItem }) => cartItem,
                      ));
                    }}
                    title={shoppingAssistantAddAllGroupProducts}
                  >
                    {shoppingAssistantAdd}
                    {' '}
                    {group.name}
                    <PlusOutlined />
                  </ButtonAdd>
                )
                : (
                  <ButtonRemove
                    onClick={() => {
                      ensureNotInCart(
                        group.products.map(({ cartItem }) => cartItem.sku)
                      );
                    }}
                    title={shoppingAssistantRemoveAllGroupProducts}
                    notification={shoppingAssistantAll}
                    notificationForceVisible
                  >
                    <span>
                      {shoppingAssistantAdded}
                      {' '}
                      <span style={{ opacity: 0.5 }}>
                        {shoppingAssistantClickToRemove}
                      </span>
                    </span>
                    <CheckOutlined />
                  </ButtonRemove>
                )
              }
              </ContentWrapper>
            </GroupWrapper>
          );
        })
      }

      {(() => {
        const isAnyProductMissing = products
          .findIndex(({ cartItem }) => (
            !itemsMap[cartItem.sku]?.quantity
            || itemsMap[cartItem.sku]?.quantity < cartItem.quantity
          )) > -1;
        return (
          <FooterWrapper>
          <GroupHeader css={{ alignItems: 'center' }}>
            <Title size="s">{shoppingAssistantRecommended}</Title>
            <Paragraph>
              {/* eslint-disable-next-line max-len */}
              {shoppingAssistantNoteAddAll}
            </Paragraph>
          </GroupHeader>
            {
              isAnyProductMissing
              ? (
                <ButtonAdd
                  onClick={() => {
                    ensureInCart(products.map(
                      ({ cartItem }) => cartItem
                    ));
                  }}
                  title={shoppingAssistantAddEverything}
                  css={{
                    background: '$notificationBackground',
                  }}
                >
                  {shoppingAssistantAddAllAndCheckout}
                  <PlusOutlined />
                </ButtonAdd>
              )
              : (
                <ButtonRemove
                  onClick={() => (
                    ensureNotInCart(
                      products.map(({ cartItem }) => cartItem.sku)
                    )
                  )}
                  title={shoppingAssistantRemoveAllGroupProducts}
                  notification={shoppingAssistantAll}
                  notificationForceVisible
                >
                  {shoppingAssistantAllProductsAdded}
                  <CheckOutlined />
                </ButtonRemove>
              )
            }
          </FooterWrapper>
        );
      })()}
    </Wrapper>
  );
}

export default SubmitResults;
